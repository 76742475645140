import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'pt-BR',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      'pt-BR': {
        translation: {
          general: {
            applicationName: 'SWGE',
            applicationNameFull: 'Sistema Web Gerenciador de Eventos',
          },
          yup: {
            pastFinalDateError: 'A data final não deve ser menor que a data inicial',
            integerError: 'O campo deve ser um número inteiro',
            positiveError: 'O campo deve ser um número positivo',
            minError: 'O campo deve ter pelo menos {{count}} caracteres',
            maxCharactersAllowed: 'O campo deve ter no máximo 100 caracteres',
            requiredField: 'Este campo deve ser preenchido',
            emailFormat: 'O e-mail deve ser válido',
            urlFormat: 'A URL deve ser válida',
            passwordEqual: 'As duas senhas devem ser iguais',
            passwordMin: 'A senha deve ter no mínimo 8 caracteres',
            passwordStrength: 'A força da senha deve ser no mínimo aceitável',
            maskError: 'Todo o campo deve ser preenchido',
            uniqueField: 'Já utilizado',
            dateIntervalError: 'Fora do intervalo estabelecido',
            resourceTimeConflict: 'Conflito de Recurso-Tempo',
            wrongExpYear: 'Ano fora da Validade',
            validMonth: 'Mês errado',
            wrongCardNumber: 'Número do Cartão fora do padrão (13 a 19 dígitos)',
            wrongSecurityCode: 'Número CVV inválido',
            onlyAlphabeticalCharactersAllowed: 'Somente caracteres alfabéticos permitidos',
            onlyNumbersAllowed: 'Somente números são permitidos',
            noSpecialCharactersAllowed: 'Digite apenas caracteres alfabéticos',
            invalidDate: 'Data inválida',
            invalidDateTime: 'Data ou hora inválida',
            wrongCpf: 'CPF inválido',
          },
          table: {
            applyFilters: 'Aplicar Filtros',
          },
          datePicker: {
            prev: 'Mês anterior',
            next: 'Próximo Mês',
            placeHolderInputDate: 'dd/mm/aaaa',
            placeHolderInputDateTime: 'dd/mm/aaaa hh:mm',
          },
          calendar: {
            allDay: 'Todo o Dia',
            buttonText: {
              today: 'Hoje',
              month: 'Mês',
              week: 'Semana',
              day: 'Dia',
              period: '{{ days }} Dias',
              moreLinkText: 'mais',
            },
            buttonHints: {
              prev: '$0 anterior',
              next: 'Próximo $0',
              month: 'Visualização do Mês',
              today: 'Este $0',
              week: 'Visualização da Semana',
              day: 'Visualização do Dia',
              moreLinkHint: 'Mostrar mais $0 evento(s)',
            },
          },
          dropzoneArea: {
            dropzoneText: 'Arraste e solte uma imagem aqui ou clique',
            preview: 'Preview:',
          },
          inactivity: {
            minutes: '$0 Minuto',
            hours: '$0 Hora',
            days: '$0 Dia',
            weeks: '$0 Semana',
            month: '1 Mês',
            months: '$0 Meses',
          },
          form: {
            save: 'Salvar',
            delete: 'Excluir',
            cancel: 'Cancelar',
            INSTITUTION: 'Nova Instituição',
            EDIT_USER: 'Editar Usuário',
            EDIT_TRACK: 'Nova Trilha',
            EDIT_CERTIFICATE: 'Novo Certificado',
            USER_LIST: 'Editar Usuário',
            cancelDelete: 'Cancelar Exclusão',
            finalDelete: 'Excluir/Anonimizar',
            userDelete: 'Solicitar Exclusão',
          },
          pages: {
            home : {              
              welcome: 'Bem-vindo(a)',
              description: 'O SWGE (Sistema Web de Gerenciamento de Eventos) é o lugar para gerenciar usuários, inscrição, certificados e muito mais.',
              subscribe1: 'Inscreva-se agora no {{ eventName }} em {{ city }} - {{ state }} ({{ initialDate }} a {{ finalDate }}).',
              subscribe2: 'Você já está inscrito na {{ eventName }} em {{ city }} - {{ state }} ({{ initialDate }} a {{ finalDate }}).',
              subscribeSubtitle: 'Faça parte da maior comunidade de entusiastas de códigos livres e tecnologias abertas da américa latina.',
              button1: 'Inscreva-se agora',
              button2: 'Minha Inscrição',
              button3: 'Consultar caravanas',
              textCaravan: 'Se deseja participar de uma caravana clique em "Consultar Caravanas" e verifique se a sua instituição possui caravana.',
              programation: 'Programação',
            },
            registration: {
              steps: {
                step1: 'Cadastro',
                step2: 'Atividades',
                step3: 'Carrinho',
                step4: 'Pagamento',
                step5: 'Resumo',
                step6: 'Finalizado',
              },
              step1: {
                title:'Inscrição na {{ eventName }}',
                column1:{ 
                  title:"Evento",
                  text1:"Inscrições até ",
                  text2:"Em até ",
                },
                column2:{
                  title:"Dados para inscrição",
                  subTitle:"dados obrigatórios para a inscrição no evento",
                  msgRequeriment: "Para a geração do qrcode/pix copia e cola, é necessário informar o numero do CPF",
                  checkLabel: "Palestrante?"
                },
                column3:{title:"Programação"},  
                cardHint:"Clique para ver mais detalhes"                
              },
              step2: {
                title1:"Inscrição na ",
                subtitle1:"O que vem incluso na inscrição",
                title2:"Inscrição de atividades adicionais opcionais:",
                subtitle2:"Selecione as atividades para adicioná-los ao carrinho de compras",
                msgRow1:'Você pode voltar e comprar mais eventos na ',
                msgRow2:'na página "Minha Inscrição"',
                noActivities:"Nenhuma atividade adicional adicionada",
                card: {
                  showMore: "Ver atividades inclusas",                  
                  buttonOn: "Incluir na compra",
                  buttonOff: "adicionado",
                  details: "Detalhes",
                  free: "Gratuito",
                }
              },
              step3: {
                title:"Carrinho",
                subtitle:"Selecione o tipo de cobrança",
                card: {
                  button: "Remover da compra",                    
                },
                dialog: {
                  title: 'Remover atividade do carrinho',                    
                  text: 'Tem certeza que deseja remover'
                }
              },
              step4: {
                title:"Pagamento",  
                loading: "Processando...",             
                textButtonCancelPayment: "Cancelar pedido",             
                textButtonConfirmOrder: "Confirmar Pedido",  
                textButtonCancelRegistration: "Cancelar Inscrição",    
                dialog: {
                  title: 'Cancelar inscrição no evento',                    
                  text: 'Tem certeza que deseja cancelar o processo de inscrição?',
                  buttonCancel: 'Cancelar',
                  buttonConfirm: 'Confirmar',
                }       
              },
              step5: {
                msgCartEmpty:"Carrinho vazio",
              },
              step6: {
                title1:"Concluído!",
                title2:"Inscrição Confirmada!",
                textRow1:"Sua inscrição será efetivada após a confirmação do pagamento!",
                textRow2:"Sua inscrição foi efetivada. Em breve, você receberá mais detalhes no e-mail informado.",
              },
            },
            participationMenu: {
              camPermission: 'Solicitar permissões de câmera',
              registeredParticipation: 'Participação Confirmada!',
              registeredParticipationText: 'Foi realizado o registro e a confirmação de participação para a seguinte atividade:',
              close: 'Fechar',
              nonMobile: 'Funcionalidade somente disponível para dispositivos móveis',
              tabs: {
                registerParticipation: 'Cadastrar Participação',
                participationRegistered: 'Participações Cadastradas',
              },
              columns: {
                activityName: 'Nome da Atividade',
                onRegister: 'Data de Registro',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhuma Participação encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Participações por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                    downloadCsv: 'Baixar JSON',
                    print: 'Imprimir',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'Participação(ões) selecionada(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar participações Selecionadas',
                  },
                },
              },
            },
            coordinatorTerm: {
              accept: 'Aceitar',
            },
            tags: {
              docMargin: 'Margem do Documento',
              tagMargin: 'Margem da Etiqueta',
              tagSize: 'Tamanho da Etiqueta',
              tagWidthSize: 'Largura',
              tagHeightSize: 'Altura',
              marginTopBottom: 'Superior',
              marginLeftRight: 'Esquerda',
              colSize: 'Qnt. de Colunas',
              rowSize: 'Qnt. de Linhas',
              startIndex: 'Índice de Inicio',
              selectedUsers: 'Qnt. de Usuários Selecionados:',
              tagFilePreview: 'Selecionar linhas e colunas do Arquivo de Etiquetas',
              selectedIndexes: 'Indices selecionados',
              pageSize: 'Estilo e Tamanho da Página',
              openPreview: 'Visualizar etiquetas',
              previewTags: 'Arquivo com as Etiquetas',
              columns: {
                userName: 'Nome',
                tagName: 'Nome Crachá',
                caravanEnrollmentCount: 'Usuários inscritos na Caravana',
                enrollmentSituation: 'Situação da Inscrição',
                userCaravan: 'Usuário em Caravana?',
                userNotInCaravan: 'Não está em uma Caravana',
                situation: {
                  notFound: 'Não Encontrada',
                  completed: 'Completada',
                  missAuthorization: 'Sem Termo de Autorização',
                  notPayed: 'Não Paga',
                  notAccepted: 'Não Aceita',
                },
              },
              tooltip: {
                generateTags: 'Gerar Etiquetas',
              },
              tabs: {
                registeredUsers: 'Usuários',
                registeredTutoredUsers: 'Usuários Tutelados',
                caravans: 'Caravanas',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhuma etiqueta encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Etiquetas por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                    downloadCsv: 'Baixar JSON',
                    print: 'Imprimir',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'Etiqueta(s) selecionada(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar etiquetas Selecionadas',
                  },
                },
              },
            },
            noticePanel: {
              tooltip: {
                delete: 'Excluir',
                cancel: 'Cancelar',
                add: 'Adicionar',
                edit: 'Editar',
              },
              editor: 'Insira o texto aqui...',
              delete: 'Excluir',
            },
            editionNotFound: {
              home: 'Início',
              notFound: 'Edição Não Encontrada!',
            },
            editionNotEnabled: {
              home: 'Início',
              notEnabled: 'Edição Não Habilitada para Acesso!',
            },
            registrationInterval: {
              registrationNotInterval: 'Fora do período de inscrição',
            },
            messageWarningUser: {
              warningUser: 'Pagamento da Caravana não confirmado, por favor entrar em contato com o coordenador da caravana!',
            },
            login: {
              email: 'E-mail',
              password: 'Senha',
              login: 'Entrar',
              backToHomePage: 'Ir ao site',
              forgotPassword: 'Esqueci a senha',
              google: 'Google',
              facebook: 'Facebook',
              linkedin: 'LinkedIn',
              gitHub: 'GitHub',
              noRecord: 'Não possui cadastro? ',
              register: 'Registre-se aqui',
              socialsLogin: 'Ou entre com ',
              loginError: 'E-mail e/ou senha inválido(s)',
              emailConfirmationError: 'E-mail não confirmado!',
              resendEmailConfirmation: 'Clique aqui para reenviar o e-mail',
              msgOldRegisters: '* Se possui registro em edições anteriores, realize um novo registro para edição atual.',
              validateUserDisabled: 'Usuário Desabilitado',
              rememberMe: 'Lembrar de mim',
              terms: {
                privacyPolicy: 'Política de Privacidade',
                cancellationRefundPolicy: 'Política de Cancelamento e Reembolso',
                useTerm: 'Termos de Uso',
                imageTerm: 'Termo de Autorização de Uso de Imagem e Dados',
                coordinatorTerm: 'Termo de Responsabilidade do Coordenador de Caravana',
                authorizationTerm: 'Declaração de Autorização Participação Em Evento de Criança Ou Adolescente',
                apiUseTerm: 'Termos de Uso da API do Sistema',
                useTermAgreement: 'Li e aceito os',
                privacyPolicyAgreement: ', concordo com a',
                and: 'e o',
              },
              oldAcess: "O login utilizado na última edição não é válido. Por favor, realize novo registro.",
            },
            tutoredUserList: {
              columns: {
                name: 'Nome',
                idNumber: 'RG',
                country: 'País',
                exclusionDate: 'Data Máxima de Exclusão',
              },
              tableOptions: {
                title: 'Usuários Tutelados',
                textLabels: {
                  body: {
                    noMatch: 'Nenhum usuário tutelado encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Usuário Tutelado por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar JSON',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'usuário(s) tutelado(s) selecionado(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar usuários tutelados Selecionados',
                  },
                },
              },
            },
            terms: {
              privacyPolicy: 'Política de Privacidade',
              cancellationRefundPolicy: 'Política de Cancelamento e Reembolso',
              useTerm: 'Termos de Uso',
              imageTerm: 'Termo de Autorização de Uso de Imagem e Dados',
              coordinatorTerm: 'Termo de Responsabilidade do Coordenador de Caravana',
              authorizationTerm: 'Declaração de Autorização Participação Em Evento de Criança Ou Adolescente',
              apiUseTerm: 'Termos de Uso da API do Sistema',
            },
            pageNotFound: {
              notFound: 'Página não Encontrada',
              home: 'Voltar para o início',
            },
            pageNotAllowed: {
              notAllowed: 'Não Permitido',
              home: 'Voltar para o início',
            },
            editEmail: {
              toolbar: {
                title: 'Novo E-mail',
              },
              subject: 'Assunto',
              class: 'Categoria',
              save: 'Salvar E-mail',
              fromTemplate: 'Usar Modelo?',
              templates: 'Modelos',
              date: 'Data',
              time: 'Hora',
              enable: 'Habilitado?',
              userMode: 'Filtro',
              selectedUsers: 'Usuários Selecionados',
              selectedUserTypes: 'Tipos de Usuário Selecionados',
              subscriberMode: 'Filtro',
              selectedSubscribers: 'Inscritos Selecionados',
              selectedSubscriberTypes: 'Tipos de Inscritos Selecionados',
              selectedCaravans: 'Caravanas Selecionadas',
              selectedRecipients: 'Destinatários',
            },
            editEnrollmentType: {
              toolbar: {
                title: 'Novo Tipo de Inscrição',
              },
              tooltip: {
                add: 'Adicionar Preço Promocional',
              },
              name: 'Nome',
              description: 'Descrição',
              save: 'Salvar Tipo de Inscrição',
              initialDate: 'Data de Início',
              initialTime: 'Hora de Início',
              finalDate: 'Data de Fim',
              finalTime: 'Hora de Fim',
              enable: 'Habilitado?',
              paymentAccounts: 'Contas de Cobrança',
              exemption: 'Isentar Usuário',
              vacancies: 'Vagas',
              certificate: 'Certificado',
              price: 'Valor',
              coverage: 'Cobertura da Inscrição',
              columns: {
                promotionalPrice: 'Preço Promocional',
                initialDate: 'Data de Início',
                initialTime: 'Hora de Início',
                finalDate: 'Data de Fim',
                finalTime: 'Hora de Fim',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhum preço promocional encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Preços por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar JSON',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'preço(s) selecionado(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar preços Selecionados',
                  },
                },
              },
            },
            editTemplate: {
              toolbar: {
                title: 'Novo Modelo',
              },
              tooltip: {
                add: 'Adicionar Conteúdo',
              },
              positions: 'Conteúdo',
              name: 'Nome',
              type: 'Tipo',
              save: 'Cadastrar Modelo',
              preview: 'Preview do Certificado?',
              previewTag: 'Preview da Etiqueta?',
              previewEmail: 'Preview do E-mail?',
              dimensionX: 'Largura do Certificado',
              dimensionY: 'Altura do Certificado',
              dimensionXTag: 'Largura da Etiqueta',
              dimensionYTag: 'Altura da Etiqueta',
              columns: {
                xPosition: 'X',
                yPosition: 'Y',
                content: 'Conteúdo',
                fontSize: 'Tamanho da Fonte',
                bold: 'Negrito',
                italic: 'Itálico',
                underlined: 'Sublinhado',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhum conteúdo encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Posições por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar JSON',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'posição(es) selecionada(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar posições Selecionados',
                  },
                },
              },
            },
            editSatisfactionSurvey: {
              toolbar: {
                title: 'Nova Pesquisa de Satisfação',
              },
              templates: 'Modelos',
              fromTemplate: 'Usar Modelo?',
              subject: 'Assunto',
              name: 'Nome',
              url: 'URL',
              activation: 'Gatilho',
              date: 'Data',
              time: 'Hora',
              condition: 'Condição',
              event: 'Evento',
              target: 'Público Alvo',
              selectedTarget: 'Pessoas Selecionadas',
              save: 'Criar Pesquisa',
              back: 'Anterior',
              next: 'Próximo',
              enable: 'Habilitado?',
              step1: 'Informações Básicas',
              step2: 'Gatilho de Ativação',
              step3: 'Customização do E-mail',
            },
            editAccessProfile: {
              toolbar: {
                title: 'Novo Perfil de Acesso',
              },
              name: 'Nome',
              save: 'Criar Perfil',
              description: 'Descrição',
              permissions: 'Permissões',
            },
            editInternationalization: {
              toolbar: {
                title: 'Novo Idioma',
              },
              chooseJSON: {
                dropzoneText: 'Arraste e solte um arquivo aqui ou clique',
              },
              tooltip: {
                upload: 'Enviar JSON',
              },
              cod: 'Código',
              enable: 'Habilitado?',
              save: 'Cadastrar',
              columns: {
                cod: 'Código',
                description: 'Descrição',
                editor: 'Texto',
                reference: 'Referência',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhum campo encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Campos por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar JSON',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'campos(s) selecionado(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar campos Selecionados',
                  },
                },
              },
            },
            editTheme: {
              toolbar: {
                editTheme: 'Atualizar Tema',
                newTheme: 'Novo Tema',
              },
              chooseJSON: {
                dropzoneText: 'Arraste e solte um arquivo aqui ou clique',
              },
              tooltip: {
                upload: 'Enviar JSON',
                download: 'Baixar JSON',
              },
              id: 'ID',
              name: 'Nome',
              type: 'Tipo',
              save: 'Salvar',
              delete: 'Excluir',
              preview: 'Visualizar Mudanças?',
              isDefault: 'Tema Principal?',
              columns: {
                cod: 'Código',
                description: 'Descrição',
                editor: 'Cor',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhum campo encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Campos por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar JSON',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'campos(s) selecionado(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar campos Selecionados',
                  },
                },
              },
            },
            editManageCaravan: {
              toolbar: {
                title: 'Gerenciar Caravana',
              },
              tooltip: {
                add: 'Adicionar',
                preview: 'Visualizar Termo de Autorização',
                users: {
                  add: 'Adicionar Inscrito(s)',
                },
              },
              review: 'Revisar',
              reviewed: 'REVISADO',
              notReviewed: 'NÃO REVISADO',
              whoReview: 'Revisado por',
              tutoredUser: {
                name: 'Nome',
                idNumber: 'RG',
                tagName: 'Nome Crachá',
                gender: 'Gênero',
                country: 'País',
                cellPhone: 'Celular',
                birthDate: 'Data de Nascimento',
                authorizationTerm: 'Clique aqui para visualizar o Termo de Autorização de Participação',
                dropzoneText: 'Arraste e solte uma arquivo PDF aqui ou clique',
              },
              tutoredUserTable: {
                columns: {
                  name: 'Nome',
                  idNumber: 'RG',
                  country: 'País',
                },
                tableOptions: {
                  title: 'Usuários Tutelados',
                  textLabels: {
                    body: {
                      noMatch: 'Nenhum usuário tutelado encontrado',
                      toolTip: 'Ordenar',
                    },
                    pagination: {
                      next: 'Próxima página',
                      previous: 'Página anterior',
                      rowsPerPage: 'Usuário Tutelado por página:',
                      displayRows: 'de',
                      jumpToPage: 'Ver página:',
                    },
                    toolbar: {
                      search: 'Busca',
                      downloadCsv: 'Baixar JSON',
                      print: 'Imprimir',
                      viewColumns: 'Ver Colunas',
                      filterTable: 'Filtrar Tabela',
                    },
                    filter: {
                      all: 'Todos',
                      title: 'FILTROS',
                      reset: 'LIMPAR',
                    },
                    viewColumns: {
                      title: 'Ver Colunas',
                      titleAria: 'Ver/Esconder Colunas',
                    },
                    selectedRows: {
                      text: 'usuário(s) tutelado(s) selecionado(s)',
                      delete: 'Apagar',
                      deleteAria: 'Apagar usuários tutelados Selecionados',
                    },
                  },
                },
              },
              id: 'ID',
              type: 'Tipo',
              payed: 'Situação do Pagamento',
              name: 'Nome',
              country: 'País',
              state: 'Estado',
              city: 'Cidade',
              coordinator: 'Coordenador',
              vacancies: 'Vagas',
              remainingVacancies: 'Vagas Remanescentes',
              price: 'Preço',
              institution: 'Instituição',
              caravanInformation: 'Informações da Caravana',
              tutoredCertificates: 'Certificados dos Usuários',
              notices: 'Mensagens',
              authorizationPreview: 'Pré-Visualização do Termo de Autorização para Participação',
              notAccepted: 'Coordenador não esta de acordo com os termos',
              notAcceptedText:
                'O coordenador de caravana não constou aceite ao termo de responsabilidade de Coordenador de Caravana. Para utilizar as funcionalidades atribuidas a um Coordenador de Caravana, por favor leia e aceite os termos.',
              goToPage: 'Ir para a pagina de aceite do Termo de Responsabilidade',
              columns: {
                name: 'Nome',
                email: 'E-mail',
                phone: 'Telefone',
                cellPhone: 'Celular',
                payed: 'Pago?',
                confirmed: 'Situação da Confirmação',
                accepted: 'Aceito?',
                idNumber: 'RG',
                authorization: 'Termo de Autorização',
                editAuthorization: 'Editar Termo de Autorização',
              },
              insertDialogTitle: 'Escolher um participante ou inserir novo',
              cancelInsertDialog: 'Salvar',
              delete: 'Excluir',
              user: 'Usuário',
              tableOptions: {
                title: 'Inscritos na Caravana',
                textLabels: {
                  body: {
                    noMatch: 'Nenhum inscrito encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Inscritos por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar JSON',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'inscrito(s) selecionado(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar inscritos Selecionados',
                  },
                },
              },
            },
            editCaravan: {
              toolbar: {
                newCaravan: 'Nova Caravana',
                editCaravan: 'Atualizar Caravana',
              },
              id: 'ID',
              type: 'Tipo',
              payed: 'Situação do Pagamento',
              name: 'Nome',
              country: 'País',
              state: 'Estado',
              city: 'Cidade',
              coordinator: 'Coordenador',
              vacancies: 'Vagas',
              price: 'Preço',
              institution: 'Instituição',
              save: 'Salvar',
              delete: 'Excluir',
            },
            editTag: {
              toolbar: {
                title: 'Nova Etiqueta',
              },
              name: 'Nome',
              tagTemplate: 'Modelo da Etiqueta',
              confirmationTemplate: 'Modelo da Etiqueta de Confirmação',
              save: 'Salvar Etiqueta',
            },
            editHome: {
              content: 'Conteúdo da Tela de Inicio...',
              language: 'Idioma',
              toolbar: {
                title: 'Tela de Início',
              },
              saveChanges: 'Salvar Mudanças',
            },
            editCertificate: {
              toolbar: {
                editCertificate: 'Atualizar Certificado',
                newCertificate: 'Novo Certificado',
              },
              tooltip: {
                add: 'Adicionar Conteúdo',
                preview: 'Pré-visualizar Certificado',
                enableQrCode: 'Habilitar Código QR',
                help: 'Ajuda',
              },
              help: {
                title: 'Ajuda com PALAVRAS RESERVADAS',
                content:
                  'No texto inserido dentro do conteúdo dinâmico, é possível adicionar palavras reservadas, para serem substituídas com os valores dinâmicos para cada usuário, evento, atividade ou trilha. As palavras são apresentadas a seguir e o significado de cada uma.',
                track: {
                  title: 'Trilha',
                  name: 'Nome da Trilha',
                  initialDate: 'Data inicial da Trilha',
                  finalDate: 'Data final da Trilha',
                },
                user: {
                  title: 'Usuário',
                  name: 'Nome do Usuário',
                },
                edition: {
                  title: 'Edição',
                  name: 'Nome da Edição',
                  year: 'Ano da Edição',
                  institution: 'Instituição da Edição',
                },
                activity: {
                  title: 'Atividade',
                  name: 'Nome da Atividade',
                  workload: 'Carga Horária da Atividade',
                  grid: 'Atividade ocorrerá o dia todo',
                  data: 'Data',
                  start: 'Início',
                  end: 'Fim',
                  schedule: 'Programação da Atividade',
                },
              },
              id: 'ID',
              name: 'Nome',
              save: 'Salvar',
              delete: 'Excluir',
              availabilityDateTime: 'Data e Hora de Disponibilidade',
              insertDialogTitle: 'Inserir Conteúdo Dinâmico',
              cancelInsertDialog: 'Inserir',
              backgroundImage: {
                dropzoneText: 'Arraste e solte uma imagem de fundo aqui ou clique',
              },
              certificatePDF: {
                dropzoneText: 'Arraste e solte uma arquivo PDF aqui ou clique',
              },
              certificatePreview: 'Pré-Visualização do Certificado',
              userCertificate: 'Certificados do Usuário: ',
              columns: {
                x: 'X',
                y: 'Y',
                content: 'Conteúdo',
                fontSize: 'Tamanho da Fonte',
                font: 'Família da Fonte',
                color: 'Cor do Texto',
                bold: 'Negrito',
                italic: 'Itálico',
                underlined: 'Sublinhado',
              },
              tableOptions: {
                title: 'Conteúdo Dinâmico',
                textLabels: {
                  body: {
                    noMatch: 'Nenhum conteúdo encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Conteúdos por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'conteúdo(s) selecionado(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar conteúdos Selecionados',
                  },
                },
              },
            },
            editInstitution: {
              toolbar: {
                editInstitution: 'Atualizar Instituição',
                newInstitution: 'Nova Instituição',
              },
              id: 'ID',
              name: 'Nome',
              shortName: 'Sigla',
              country: 'País',
              state: 'Estado',
              city: 'Cidade',
              phone: 'Telefone',
              mobilePhone: 'Celular',
              website: 'Website',
              save: 'Salvar',
              delete: 'Excluir',
              insertDialogTitle: 'Inserir Espaço',
              updateDialogTitle: 'Atualizar Espaço',
              cancelInsertDialog: 'Inserir',
              isHost: 'A instituição é a sede do evento?',
              columns: {
                name: 'Nome',
                number: 'Número',
                languageFlag:'Bandeira',
              },
              tableOptions: {
                title: 'Espaços',
                textLabels: {
                  body: {
                    noMatch: 'Nenhum espaço encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Espaços por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'espaço(s) selecionado(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar espaços Selecionados',
                  },
                },
              },
            },
            editTrack: {
              toolbar: {
                editTrack: 'Atualizar Trilha',
                newTrack: 'Nova Trilha',
              },
              id: 'ID',
              name: 'Nome',
              initialDate: 'Data de Início',
              finalDate: 'Data de Fim',
              description: 'Descrição da Trilha...',
              attendeeCertificate: 'Certificado dos Participantes',
              speakerCertificate: 'Certificado dos Palestrantes',
              save: 'Salvar',
              delete: 'Excluir',
            },
            editEdition: {
              toolbar: {
                editEdition: 'Atualizar Edição',
                newEdition: 'Nova Edição',
              },
              enabled: 'Habilitada?',
              id: 'ID',
              name: 'Nome',
              year: 'Ano',
              shortName: 'Sigla',
              initialDate: 'Data de Início',
              finalDate: 'Data de Fim',
              country: 'País',
              state: 'Estado',
              city: 'Cidade',
              place: 'Local Sede',
              description: 'Descrição',
              editionType: 'Tipo',
              goToCustomization: 'Ir para Customização',
              save: 'Salvar',
              delete: 'Excluir',
            },
            myRegistration: {
              toolbar: {
                myRegistration: 'Minha Inscrição',
              },
              tooltip: {
                calendar: 'Calendário',
              },
              id: 'ID',
              payed: 'Paga',
              notPayed: 'Não Pago',
              status: 'Situação',
              totalPrice: 'Valor Total',
              registrationTypePrice: 'Valor da Inscrição',
              activitiesPrice: 'Valor das Atividades',
              scheduleTitle: 'Visualizar Programação',
              finishPurchase: 'Finalizar',
              payment: {
                title: 'Pagamento',
                cardDetails: 'Detalhes do Cartão',
                pagSeguroText: 'Pagamento processado pelo ',
                summary: 'Resumo',
                total: 'Total',
                quantityInstallments: 'Quantidade de Parcela(s)',
                number: 'Número do Cartão',
                holder: 'Nome do Titular',
                expMonth: 'Mês',
                expYear: 'Ano',
                cvv: 'CVV',
                expires: 'Data de Vencimento',
                securityCode: 'Código de Segurança',
                confirmPayment: 'Confirmar Pagamento',
                cancelPayment: 'Cancelar Pagamento',
                installments: 'Parcela(s) de',
                possibleInstallment: 'Parcela',
                subTotal: 'Sub-total',
                interest: 'Juros',
                promotion: 'Desconto',
                paypal: 'PayPal',
                pagseguro: 'PagSeguro',
                sendPix: 'Visualizar QrCode/Chave PIX',
                verifyPayment:'Verificar Pagamento',
                pixCopyText: 'Código copia e Cola',
                copyToClipboard: 'Copiar código do PIX',
                pixQrCode: 'QrCode do PIX',
                pix: 'PIX',
                pixDetails: 'Cobrança por PIX',
                pixKey: 'Chave Pix',
                receiver: {
                  name: 'Nome do Recebedor',
                  key: 'CNPJ',
                },
                paymentStatus: {
                  title: 'Estado da Cobrança',
                  chargeSuccess:
                    'Cobrança realizada com sucesso! Foi enviado um e-mail com mais informações, e para acompanhar o estado do pagamento, verifique essa página a cada 5 minutos.',
                  cancelSuccess:
                    'Cancelamento realizado com sucesso! Foi enviado um e-mail com mais informações sobre o cancelamento, e está sendo feito o reembolso total do valor da inscrição e atividades pagas.',
                  voucherFound:
                    'Inscrição Isenta! Foi encontrado um voucher de isenção atrelado a este e-mail, o custo da inscrição foi isentado.',
                },
              },
              activityCard: {
                track: 'Trilha',
                speakers: 'Palestrantes',
                responsible: 'Responsável',
                noResponsible: 'Sem Responsável',
                noSchedule: 'Horário indisponível',
                vacancies: 'Vagas',
                workload: 'Carga Horária',
                type: 'Tipo de Atividade',
                presentationType: 'Tipo de Apresentação',
                local: "Local",
                presentationRemote: "Atividade Remota",
                language: 'Idioma',
                price: 'Preço',
                conflict: 'Conflito',
                details: 'Detalhes',
                place: 'Local',
                start: 'Início',
                end: 'Fim',
                date: 'Data',
                calendarTooltip: 'Conferir calendário',
                speakerInfo: {
                  biography: 'Biografia',
                  contact: 'Contato',
                  additionalInformation: 'Informações Adicionais',
                },
                trackInfo: {
                  description: 'Descrição',
                  initialDate: 'Data Inicial',
                  finalDate: 'Data Final',
                },
                noDescription: 'Atividade sem descrição',
                includeInPurchase: 'Incluir na compra',
                removeFromCart: 'Remover da compra',
                tooltipDescription: 'Clique para ver mais',
                schedule: 'Programação da Atividade',
              },
              lecturePackCard: {
                activityList: 'Atividades Inclusas',
                title: 'Atividades Básicas',
              },
              titleTable: 'Atividades opcionais adquiridas',
              viewConflicts: 'Visualizar Conflito'
            },
            myFeedbacks: {
              toolbar: {
                title: 'Novo Feedback',
              },
              tooltip: {
                add: 'Adicionar Feedback',
              },
              title: 'Título',
              description: 'Descrição do feedback...',
              save: 'Salvar',
              date: 'Data de Criação',
              status: 'Status',
            },
            myAccount: {
              toolbar: {
                myAccount: 'Minha Conta',
                editMyAccount: 'Editar Conta',
              },
              admin: 'Administrador',
              enabled: 'Habilitado',
              notEnabled: 'Desabilitado',
              confirmed: 'E-mail confirmado',
              notConfirmed: 'E-mail não confirmado',
              communication: 'Receber informações deste evento por email',
              socialCommunication: 'Permitir a plataforma apresentar seus contatos sociais',
              editProfile: 'Editar Perfil',
              gitHub: 'Desvincular do GitHub',
              google: 'Desvincular do Google',
              untieDialogTitle: 'Desvincular Login Social',
              untieDialogContent: 'Deseja realmente desvincular esse método de login?',
              untie: 'Desvincular',
              sliderScale: 'Escala',
              sliderRotation: 'Rotação',
              changeUserImageContent: 'Deseja realmente alterar a imagem de Usuário?',
              changeUserImage: 'Salvar',
              removeUserImage: 'Remover Imagem Atual',
              goBack: 'Voltar',
              changeUserImageTitle: 'Alterar Imagem do Usuário',
              id: 'ID',
              accessProfile: 'Perfil de Acesso',
              allowEmail: 'Desejo receber informações deste evento por e-mail.',
              allowContact: 'Permito a plataforma disponibilizar meus contatos sociais para que outros possam me contatar.',
              addressUnique: 'Endereço',
              name: 'Nome',
              cardName: 'Nome Crachá',
              idNumber: 'Número de Identidade',
              gender: 'Gênero',
              birthDate: 'Data de nascimento',
              email: 'E-mail',
              github: 'Github',
              linkedin: 'Linkedin',
              lattes: 'Lattes',
              website: 'Website',
              orcid: 'Orcid',
              biography: 'Biografia',
              zipCode: 'CEP',
              address: 'Endereço Linha 1',
              address2: 'Endereço Linha 2',
              nin: 'CPF',
              mobilePhone: 'Celular',
              phone: 'Telefone',
              country: 'País',
              state: 'Estado',
              city: 'Cidade',
              company: 'Instituição',
              function: 'Cargo',
              save: 'Salvar',
              delete: 'Excluir Conta',
              password: {
                password: 'Senha',
                passwordConfirmation: 'Confirmação da Senha',
                tooShort: 'muito curta',
              },
              specialNeedsText: 'PcD - Pessoa com Deficiência',
              otherNeeds: 'Outras Atenções',
            },
            changePassword: {
              toolbar: {
                changePassword: 'Criar nova senha'
              },
              new_password: 'Nova senha',
              save: 'Registrar nova senha',
              messageTitleReset: "Olá! Uma solicitação de redefinição de senha foi registrada para esta conta. Para garantir sua segurança, defina uma nova senha.",
              messageTitleRedefine: "Digite a nova senha que deseja utilizar para acessar esta conta."
            },
            resetPassword: {
              toolbar: {
                resetPassword: 'Redefinir Senha',
              },
              email: 'E-mail',
              reset: 'Redefinir Senha',
              cancel: 'Cancelar',
            },
            emailConfirmation: {
              toolbar: {
                emailConfirmation: 'Confirmação de E-mail',
              },
              confirmed: 'E-mail Confirmado. Obrigado!',
              notConfirmed: 'Não foi possível confirmar seu endereço de e-mail!',
              cancel: 'Início',
            },
            certificateValidation: {
              toolbar: {
                validation: 'Validação do Certificado',
              },
            },
            autoRegistration: {
              toolbar: {
                newUser: 'Registrar usuário',
              },
              subtitle1: 'Dados do usuário',
              subtitle2: 'Informações adicionais (opcionais)',
              subtitle3: 'Endereço',
              tooltipExpand: 'Expandir',
              tooltipCollapse: 'Ocultar',
              gitHub: 'Registrar com GitHub',
              google: 'Registrar com Google',
              id: 'ID',
              accessProfile: 'Perfil de Acesso',
              allowEmail: 'Desejo receber informações deste evento por e-mail.',
              addressUnique: 'Endereço',
              name: 'Nome completo (Será utilizado na emissão do certificado)',
              cardName: 'Nome Crachá',
              idNumber: 'Número de Identidade',
              gender: 'Gênero',
              birthDate: 'Data de nascimento',
              email: 'E-mail',
              github: 'Github',
              linkedin: 'Linkedin',
              lattes: 'Lattes',
              website: 'Website',
              orcid: 'Orcid',
              biography: 'Adicionar biografia',
              zipCode: 'CEP',
              address: 'Endereço Linha 1',
              address2: 'Endereço Linha 2',
              nin: 'CPF',
              mobilePhone: 'Celular',
              phone: 'Telefone',
              country: 'País',
              state: 'Estado',
              city: 'Cidade',
              company: 'Instituição',
              function: 'Cargo',
              save: 'Salvar',
              exempt: 'Isentar Usuário',
              password: {
                password: 'Senha',
                passwordConfirmation: 'Confirmação da Senha',
                tooShort: 'muito curta',
              },
              specialNeedsText: 'PcD - Pessoa com Deficiência',
              otherNeeds: 'Outras Atenções',
              terms: {
                privacyPolicy: 'Política de Privacidade',
                cancellationRefundPolicy: 'Política de Cancelamento e Reembolso',
                useTerm: 'Termos de Uso',
                imageTerm: 'Termos de Uso Geral de Imagem',
                coordinatorTerm: 'Termo de Responsabilidade do Coordenador de Caravana',
                authorizationTerm: 'Declaração de Autorização Participação Em Evento de Criança Ou Adolescente',
                apiUseTerm: 'Termos de Uso da API do Sistema',
                useTermAgreement: 'Li e estou de acordo com os ',
                privacyPolicyAgreement: ', concordo com a',
                and: 'e o',
              },
              alerts: {
                text1: 'Após concluir o registro no sistema, você receberá um e-mail para realizar a confirmação do e-mail.',
                text2: 'Este cadastro não realiza a inscrição no evento, ao entrar no plataforma faça a inscrição no evento de preferência.',
                text3: 'Para concluir o cadastro, é necessário ler e concordar com os termos de uso do sistema.',
              }
            },
            dpoManage: {
              user: 'Usuário',
              tutoredUser: 'Usuário Tutelado',
              requestDate: 'Data de Requisição',
              note: 'Observação',
              applicantContact: 'Telefone do Solicitante',
              requestType: 'Tipo da Solicitação',
              attachment: 'Anexo',
              id: 'Id',
              registryDate: 'Data da Solicitação',
              status: 'Estado da Solicitação',
              returnDate: 'Data da Devolutiva',
              deadlineExclusionDate: 'Data limite Exclusão',
              deleteRequestBegin: 'Dados da Solicitação de Exclusão',
              deleteRequestEnd: 'Dados da Exclusão',
            },
            userExclusion: {
              user: 'Usuário',
              requestDate: 'Data de Requisição',
              note: 'Observação',
              applicantContact: 'Telefone do Solicitante',
              requestType: 'Tipo da Solicitação',
              attachment: 'Anexo',
              id: 'Id',
              registryDate: 'Data da Solicitação',
              status: 'Estado da Solicitação',
              returnDate: 'Data da Devolutiva',
              deadlineExclusionDate: 'Data limite Exclusão',
              effectiveDeletionDate: 'Data efetiva Exclusão',
            },
            editActivity: {
              toolbar: {
                editActivity: 'Atualizar Atividade',
                newActivity: 'Nova Atividade',
              },
              id: 'ID',
              name: 'Titulo',
              type: 'Tipo',
              track: 'Trilha',
              description: 'Descrição da Atividade...',
              speakerCertificate: 'Certificado dos Palestrantes',
              attendeeCertificate: 'Certificado dos Participantes',
              responsibleUser: 'Coord. de Grade Responsável',
              speaker: 'Palestrante',
              vacancies: 'Vagas',
              price: 'Preço',
              language: 'Idioma',
              languageFlag: 'Bandeira',
              presentationType: 'Tipo de Apresentação',
              workload: 'Carga Horária',
              certificate: 'Certificado',
              place: 'Local',
              placeUrl: 'Local Online',
              save: 'Salvar',
              delete: 'Excluir',
              scheduleDialogTitle: 'Gerenciar Programação',
              managePlacesDialogTitle: 'Gerenciar Locais',
              calendarColor: 'Cor na Grade',
            },
            speakerActivityList: {
              activityCard: {
                name: 'Nome',
                type: 'Tipo de Atividade',
                responsibleUser: 'Coord. de Grade Responsável',
                speakers: 'Palestrantes',
                language: 'Idioma',
                presentationType: 'Tipo de Apresentação',
                place: 'Local',
                webPlace: 'Local Online',
                track: 'Trilha',
                description: 'Descrição',
                activityDialogTitle: 'Atividades do Palestrante',
              },
            },
            userActivities: {
              helper: {
                editActivities: {
                  title: 'Alterar para a seleção de atividades',
                  content:
                    'Este botão permite trocar para a edição da grade customizada. Através da edição, permite apresentar somente as programações selecionadas por você. Para demonstração, clique no botão para ver o próximo guia.',
                },
                favorite: {
                  title: 'Selecionar Programação',
                  content:
                    'Através do clique na estrela, é possível marcar ou desmarcar a programação da atividade para ela pertencer a grade personalizada.\n Demonstração: ',
                },
                favoriteAll: {
                  title: 'Selecionar toda a Atividade',
                  content:
                    'Através do clique no evento, é apresentado outro botão com ícone de estrela. Com o clique nesse botão, é possível marcar ou desmarcar todas as programações da atividade na grade personalizada.\n Demonstração: ',
                },
                fab: {
                  title: 'Salvar grade personalizada',
                  content: 'Através deste botão e possível salvar toda a programação selecionada.',
                },
              },
              activityCard: {
                name: 'Nome',
                type: 'Tipo de Atividade',
                speakers: 'Palestrantes',
                language: 'Idioma',
                presentationType: 'Tipo de Apresentação',
                place: 'Local',
                presentationRemote: "Atividade Remota",
                webPlace: 'Local Online',
                availabilityLink: 'Link de acesso será disponibilizado após o pagamento da inscrição',
                track: 'Trilha',
                description: 'Descrição',
                activityDialogTitle: 'Atividade Inscrita',
              },
              saveUserSchedule: 'Salvar a grade customizada',
              editUserSchedule: 'Alterar para edição da programação',
              userSchedule: 'Alterar para as atividades escolhidas',
              addSchedule: 'Adicionar programação escolhida',
              removeSchedule: 'Remover programação escolhida',
              addActivity: 'Adicionar toda a programação da atividade escolhida',
              removeActivity: 'Remover toda a programação da atividade escolhida',
              removedSchedules: 'Programações Removidas',
              schedule: {
                title: 'Programação',
                name: 'Título',
                allDay: 'Todo o dia',
                initialDate: 'Data Inicial',
                finalDate: 'Data Final',
              },
            },
            editUser: {
              toolbar: {
                editUser: 'Atualizar Usuário',
                newUser: 'Novo Usuário',
              },
              admin: 'Administrador?',
              changePassword: 'Criar nova senha',
              resetPassword: 'Redefinir Senha',
              addRegistration: 'Realizar Inscrição',
              id: 'ID',
              accessProfile: 'Perfil de Acesso',
              name: 'Nome',
              cardName: 'Nome Crachá',
              idNumber: 'Número de Identidade',
              gender: 'Gênero',
              birthDate: 'Data de nascimento',
              email: 'E-mail',
              github: 'Github',
              linkedin: 'Linkedin',
              lattes: 'Lattes',
              website: 'Website',
              orcid: 'Orcid',
              biography: 'Adicionar biografia',
              zipCode: 'CEP',
              address: 'Endereço Linha 1',
              addressUnique: 'Endereço',
              address2: 'Endereço Linha 2',
              nin: 'CPF',
              mobilePhone: 'Celular',
              phone: 'Telefone',
              country: 'País',
              state: 'Estado',
              city: 'Cidade',
              company: 'Instituição',
              function: 'Cargo',
              userPermissions: 'Permissões do Usuário',
              save: 'Salvar',
              allowEmail: 'Enviar informações deste evento por e-mail?',
              delete: 'Excluir',
              reset: 'Redefinir Senha',
              cancelResetDialog: 'Cancelar',
              resetDialogTitle: 'Redefinir Senha',
              resetDialogContent: 'Deseja realmente redefinir a senha do usuário?',
              password: {
                password: 'Senha',
                passwordConfirmation: 'Confirmação da Senha',
                tooShort: 'muito curta',
              },
              confirmedEmail: 'E-mail confirmado?',
              resendEmail: 'Reenviar e-mail',
              enable: 'Habilitado?',
              specialNeedsText: 'PcD - Pessoa com Deficiência',
              otherNeeds: 'Outras Atenções',
            },
            accessProfileList: {
              toolbar: {
                title: 'Todos os Perfis de Acesso',
              },
              tooltip: {
                add: 'Adicionar Perfil de Acesso',
              },
              columns: {
                name: 'Nome',
                description: 'Descrição',
                permissions: 'Permissões',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhum perfil de acesso encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Perfis por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'perfil(s) selecionado(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar perfis Selecionados',
                  },
                },
              },
            },
            registrationList: {
              registrationDialog: {
                title: 'Informações da Inscrição',
              },
              registrationActivity: {
                activityUser: 'Atividades do Usuário',
              },
              toolbar: {
                title: 'Todas as Inscrições',
              },
              tooltip: {
                add: 'Adicionar',
                preview: 'Visualizar Termo de Autorização',
              },
              authorizationPreview: 'Pré-Visualização do Termo de Autorização para Participação',
              columns: {
                user: 'Usuário',
                registrationDateTime: 'Data e Hora da Inscrição',
                payed: 'Situação do Pagamento',
                price: 'Preço',
                percentage: 'Porcentagem (%)',
                authorization: 'Termo de Autorização',
              },
              tabs: {
                registrations: 'Inscrições de Usuários',
                tutoredRegistrations: 'Inscrições de Usuários Tutelados',
                config: 'Configurar Inscrição',
              },
              configForm: {
                initialDateTime: 'Data e Hora de Início',
                finalDateTime: 'Data e Hora de Fim',
                price: 'Preço',
                id: 'ID',
                columns: {
                  initialDateTime: 'Data e Hora de Início',
                  finalDateTime: 'Data e Hora de Fim',
                  price: 'Preço',
                  vacancies: 'Limite de Vagas',
                  percentage: 'Porcentagem (%)',
                },
                tooltip: {
                  add: 'Adicionar Valor Promocional',
                },
                insertDialogTitle: 'Inserir Valor Promocional',
                cancelInsertDialog: 'Salvar',
                delete: 'Excluir',
                cancelDeleteDialog: 'Cancelar',
                deleteDialogTitle: 'Excluir valores promocionais',
                deleteDialogContent: 'Deseja realmente excluir os valores promocionais?',
                tableOptions: {
                  title: 'Valores Promocionais',
                  textLabels: {
                    body: {
                      noMatch: 'Nenhum valor promocional encontrado',
                      toolTip: 'Ordenar',
                    },
                    pagination: {
                      next: 'Próxima página',
                      previous: 'Página anterior',
                      rowsPerPage: 'Valores Promocionais por página:',
                      displayRows: 'de',
                      jumpToPage: 'Ver página:',
                    },
                    toolbar: {
                      search: 'Busca',
                      downloadCsv: 'Baixar CSV',
                      print: 'Imprimir',
                      viewColumns: 'Ver Colunas',
                      filterTable: 'Filtrar Tabela',
                    },
                    filter: {
                      all: 'Todos',
                      title: 'FILTROS',
                      reset: 'LIMPAR',
                    },
                    viewColumns: {
                      title: 'Ver Colunas',
                      titleAria: 'Ver/Esconder Colunas',
                    },
                    selectedRows: {
                      text: 'Valor promocional(is) selecionado(s)',
                      delete: 'Apagar',
                      deleteAria: 'Apagar Valores Promocionais Selecionados',
                    },
                  },
                },
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhuma inscrição encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Inscrições por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'inscrição(ões) selecionada(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar inscrições Selecionadas',
                  },
                },
              },
            },
            activityList: {
              allDay: 'Dia Inteiro',
              toolbar: {
                title: 'Todas as Atividades',
              },
              helper: {
                scroll: {
                  title: 'Rolagem das Programações',
                  content:
                    'É possível ver todos os horários disponíveis para esta edição nesta tabela. Ele pode rolar horizontalmente para mostrar todas as salas não exibidas e suas atividades agendadas',
                },
                tracks: {
                  title: 'Legenda das Trilhas',
                  content:
                    'Aqui contêm a legenda das cores utilizadas nas atividades apresentada na grade. Cada cor na atividade representa a trilha que aquela atividade pertence.',
                },
              },
              tracks: 'Trilhas: ',
              tooltip: {
                add: 'Registrar',
              },
              columns: {
                name: 'Nome',
                track: 'Trilha',
                speaker: 'Palestrante',
                vacancies: 'Vagas',
                remainingVacancies: 'Vagas Remanescentes',
                price: 'Preço',
                type: 'Tipo de Atividade',
                presentationType: 'Tipo de Apresentação',
              },
              tabs: {
                schedule: 'Agenda',
                activities: 'Todas as Atividades',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhuma atividade encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Atividades por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'atividade(s) selecionada(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar atividades Selecionadas',
                  },
                },
              },
            },
            enrollmentTypeList: {
              toolbar: {
                title: 'Todos os Tipos de Inscrição',
              },
              tracks: 'Trilha(s): ',
              tooltip: {
                add: 'Adicionar Tipo de Inscrição',
              },
              columns: {
                name: 'Nome',
                initialTimestamp: 'Data/Hora de Início',
                finalTimestamp: 'Data/Hora de Fim',
                enable: 'Habilitado?',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhum tipo de inscrição encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Tipos por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'tipo(s) selecionado(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar tipos Selecionados',
                  },
                },
              },
            },
            satisfactionSurveyList: {
              toolbar: {
                title: 'Todas as Pesquisas de Satisfação',
              },
              tooltip: {
                add: 'Adicionar Pesquisa de Satisfação',
              },
              columns: {
                name: 'Nome',
                url: 'URL',
                status: 'Status',
                enable: 'Habilitado?',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhuma pesquisa de satisfação encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Pesquisas por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'pesquisa(s) selecionada(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar pesquisas Selecionadas',
                  },
                },
              },
            },
            caravanRegistrationList: {
              toolbar: {
                title: 'Todas as Caravanas',
              },
              tooltip: {
                add: 'Adicionar Caravana',
              },
              registrationDialogTitle: 'Inscrição na Caravana',
              confirmRegistrationDialog: 'Confirmar',
              registrationDialogContent: 'Confirma que deseja se inscrever nessa caravana?',
              registrationCancelDialogTitle: 'Cancelar Inscrição na Caravana',
              confirmRegistrationCancelDialog: 'Cancelar',
              registrationCancelDialogContent: 'Confirma que deseja remover sua inscrição nessa caravana?',
              confirmRegistrationCancelDialogConfirm: 'Remover',
              columns: {
                name: 'Nome',
                institution: 'Instituição',
                country: 'País',
                state: 'Estado',
                city: 'Cidade',
                price: 'Preço',
                vacancies: 'Vagas',
                remainingVacancies: 'Vagas Remanescentes',
                confirmed: 'Situação da Confirmação',
                accepted: 'Situação da Aceitação',
                textButtonAction: 'Participar',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhuma caravana encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Caravanas por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'caravana(s) selecionada(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar caravanas Selecionados',
                  },
                },
              },
            },
            trackList: {
              toolbar: {
                title: 'Todas as Trilhas',
              },
              tooltip: {
                add: 'Registrar',
              },
              columns: {
                name: 'Nome',
                initialDate: 'Data de Início',
                finalDate: 'Data de Fim',
                calendarColor: 'Cor',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhuma trilha encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Trilhas por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'trilha(s) selecionada(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar trilhas Selecionadas',
                  },
                },
              },
            },
            caravanList: {
              toolbar: {
                title: 'Todas as Caravanas',
              },
              tooltip: {
                add: 'Registrar',
              },
              columns: {
                name: 'Nome',
                coordinator: 'Coordenador',
                institution: 'Instituição',
                country: 'País',
                state: 'Estado',
                city: 'Cidade',
                price: 'Preço',
                vacancies: 'Vagas',
                remainingVacancies: 'Vagas Remanescentes',
                payed: 'Situação do Pagamento',
                type: 'Tipo',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhuma caravana encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Caravanas por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'caravana(s) selecionada(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar caravanas Selecionados',
                  },
                },
              },
            },
            themeList: {
              toolbar: {
                title: 'Customização',
              },
              tooltip: {
                add: 'Adicionar Tema',
              },
              choseTheme: {
                light: 'Tema Claro',
                dark: 'Tema Escuro',
                saveChanges: 'Salvar',
                themeChoice: 'Tema Padrão',
                allThemes: 'Todos os Temas',
              },
              choseLogo: {
                logo: 'Logo',
                saveLogo: 'Salvar',
                deleteLogo: 'Remover Logo',
              },
              app: {
                application: 'Aplicação',
                appName: 'Nome da Aplicação',
                fontSize: 'Tamanho da Fonte',
                fontFamily: 'Tipo da Fonte',
                saveChanges: 'Salvar Alterações',
              },
              columns: {
                name: 'Nome',
                type: 'Tipo',
              },
              delete: 'Excluir',
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhum tema encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Temas por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'temas(s) selecionado(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar temas Selecionados',
                  },
                },
              },
            },
            internationalizationList: {
              toolbar: {
                title: 'Todos os Idiomas',
              },
              tooltip: {
                add: 'Adicionar Idioma',
                upload: 'Enviar JSON',
              },
              chooseJSON: {
                dropzoneText: 'Arraste e solte um arquivo aqui ou clique',
              },
              columns: {
                cod: 'Código',
                flag: 'Bandeira',
                download: 'Baixar JSON',
                upload: 'Enviar JSON',
                enable: 'Habilitado?',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhum idioma encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Idiomas por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'idioma(s) selecionado(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar idiomas Selecionados',
                  },
                },
              },
            },
            institutionList: {
              toolbar: {
                title: 'Todas as Instituições',
              },
              tooltip: {
                add: 'Registrar',
              },
              columns: {
                name: 'Nome',
                country: 'País',
                state: 'Estado',
                city: 'Cidade',
                phone: 'Telefone',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhuma instituição encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'instituições por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'instituição(ões) selecionada(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar Instituições Selecionadas',
                  },
                },
              },
            },
            delete: 'Excluir',
            editionList: {
              toolbar: {
                title: 'Todos as Edições',
              },
              tooltip: {
                add: 'Registrar',
              },
              columns: {
                name: 'Nome',
                year: 'Ano',
                enabled: 'Habilitado?',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhuma edição encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Edições por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'edição(ões) selecionada(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar Edições Selecionados',
                  },
                },
              },
              delete: 'Excluir',
            },
            manageCaravanList: {
              toolbar: {
                title: 'Todas as Caravanas',
              },
              tooltip: {
                add: 'Adicionar Caravana',
              },
              columns: {
                name: 'Nome',
                type: 'Tipo',
                institution: 'Instituição',
                coordinator: 'Coordenador',
                vacancies: 'Vagas',
                remainingVacancies: 'Vagas Remanescentes',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhuma caravana encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Caravanas por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'caravana(s) selecionada(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar Caravanas Selecionadas',
                  },
                },
              },
              delete: 'Excluir',
            },
            feedbackList: {
              toolbar: {
                title: 'Todos os Feedbacks',
              },
              tooltip: {
                download: 'Baixar Imagens',
              },
              columns: {
                title: 'Título',
                user: 'Usuário',
                download: 'Imagens',
                status: 'Status',
                apiVersion: 'Versão API',
                webVersion: 'Versão WEB',
              },
              id: 'ID',
              insertDialogTitle: 'Atualizar Feedback',
              cancelInsertDialog: 'Salvar',
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhum feedback encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Feedbacks por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'feedback(s) selecionado(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar feedbacks Selecionados',
                  },
                },
              },
            },
            myCertificatesList: {
              toolbar: {
                title: 'Todos os meus certificados',
              },
              tooltip: {
                download: 'Baixar Certificado',
                downloads: 'Baixar Certificado(s)',
                preview: 'Pré-Visualizar Certificado',
              },
              columns: {
                activityName: 'Atividade',
                trackName: 'Trilha',
                download: 'Certificado',
                preview: 'Visualizar',
                certificateType: 'Tipo do Certificado',
                availability: 'Data de Disponibilidade',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhum certificado encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Certificados por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'certificado(s) selecionado(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar certificados Selecionados',
                  },
                },
              },
            },
            voucherList: {
              updateDialogTitle: 'Atualizar Voucher',
              saveDialog: 'Salvar Voucher',
              insertDialogTitle: 'Adicionar Voucher',
              toolbar: {
                title: 'Todos os Vouchers',
              },
              tooltip: {
                add: 'Registrar',
              },
              columns: {
                id: 'Id',
                userEmail: 'E-mail do Usuário',
                editionName: 'Nome da Edição',
                editionYear: 'Ano da Edição',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhum voucher encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'voucher por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'voucher(s) selecionado(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar Vouchers Selecionados',
                  },
                },
              },
            },
            tutoredUsersCertificateList: {
              toolbar: {
                title: 'Todos os Certificados dos Usuários Tutelados',
              },
              tooltip: {
                download: 'Baixar Certificado',
              },
              columns: {
                activityName: 'Atividade',
                trackName: 'Trilha',
                download: 'Certificado',
                preview: 'Pré-Visualização do Certificado',
                userName: 'Nome do Usuário',
                availability: 'Data de Disponibilidade',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhum certificado encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Certificados por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'certificado(s) selecionado(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar certificados Selecionados',
                  },
                },
              },
            },
            userList: {
              toolbar: {
                title: 'Todos os Usuários',
              },
              tooltip: {
                add: 'Registrar',
              },
              columns: {
                name: 'Nome',
                email: 'E-mail',
                tagName: 'Nome crachá',
                cellPhone: 'Contato',
                institution: 'Instituição',
                country: 'País',
                state: 'Estado',
                city: 'Cidade',
                enable: 'Habilitado?',
                exclusionDate: 'Data Máxima de Exclusão',
                inactivity: 'Inatividade',
                confirmed: 'Email Confirmado',
                editions: 'Participante dos Eventos',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhum usuário encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Usuários por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'usuário(s) selecionado(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar usuários Selecionados',
                  },
                },
              },
              delete: 'Excluir',
            },
            exclusionList: {
              toolbar: {
                title: 'Todos as Solicitações de Exclusão',
              },
              columns: {
                exclusionStatus: 'Estado da Solicitação',
                registryDate: 'Data da Solicitação',
                name: 'Nome do Usuário',
                email: 'E-mail',
                note: 'Observação',
                applicantContact: 'Contato',
                requestType: 'Tipo da Solicitação',
                actions: 'Ações',
              },
              tooltip: {
                edit: 'Editar solicitação',
                complete: 'Anonimizar dados do usuário',
                notApproved: 'Não é possível anonimizar os dados pois o status da solicitação não é ”Aprovado”.',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhuma solicitação encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Solicitações por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'solicitação(ões) selecionada(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar solicitações Selecionados',
                  },
                },
              },
              delete: 'Excluir',
            },
            emailList: {
              toolbar: {
                title: 'E-mails',
              },
              columns: {
                subject: 'Assunto',
                class: 'Categoria',
                status: 'Status',
                timestamp: 'Data/Hora',
                enable: 'Habilitado?',
              },
              tooltip: {
                add: 'Criar E-mail',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhum e-mail encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'E-mails por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'e-mail(s) selecionado(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar E-mails Selecionados',
                  },
                },
              },
            },
            exclusion: {
              columns: {
                requestDate: 'Data de Requisição',
                note: 'Observação',
                applicantContact: 'Telefone do Solicitante',
              },
            },
            certificateList: {
              toolbar: {
                title: 'Certificados',
              },
              columns: {
                name: 'Nome',
                availability: 'Disponibilidade',
                enable: 'Habilitado?',
              },
              tooltip: {
                add: 'Registrar',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhum certificado encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Certificados por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'certificado(s) selecionado(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar certificados Selecionados',
                  },
                },
              },
            },
            templateList: {
              toolbar: {
                title: 'Modelos',
              },
              columns: {
                name: 'Nome',
                type: 'Tipo',
              },
              tooltip: {
                add: 'Adicionar Modelo',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhum modelo encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Modelos por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'modelo(s) selecionado(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar modelos Selecionados',
                  },
                },
              },
            },
            tagList: {
              toolbar: {
                title: 'Etiquetas',
              },
              tooltip: {
                add: 'Adicionar Etiqueta',
                print: 'Imprimir Etiqueta',
              },
              filter: 'Filtro',
              intervalStart: 'Início do Intervalo de Inscrição',
              intervalEnd: 'Fim do Intervalo de Inscrição',
              letterIntervalStart: 'Início do Intervalo de Letras',
              letterIntervalEnd: 'Fim do Intervalo de Letras',
              enrollmentType: 'Tipo de Inscrição',
              generated: 'Incluir já Gerados?',
              caravans: 'Incluir Usuários de Caravanas?',
              pay: 'Apenas Pagos/Isentos?',
              confirmation: 'Imprimir Etiqueta de Confirmação?',
              selectedCaravans: 'Caravanas Selecionadas',
              coordinatorConfirmed: 'Apenas Inscrições Confirmadas pelo Coordenador?',
              selectedSpeakers: 'Palestrantes Selecionados',
              dialog: {
                title: 'Imprimir Etiqueta',
                save: 'Imprimir',
              },
              columns: {
                name: 'Nome',
                print: 'Imprimir',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhuma etiqueta encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Etiquetas por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'etiqueta(s) selecionada(s)',
                    delete: 'Apagar',
                    deleteAria: 'Apagar etiquetas Selecionadas',
                  },
                },
              },
            },
            audit: {
              toolbar: {
                title: 'Auditoria',
              },
              columns: {
                timestamp: 'Data/Hora',
                action: 'Evento',
                description: 'Descrição',
                agent: 'Agente',
                result: 'Resultado',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Nenhum evento encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Eventos por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Baixar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas',
                  },
                  selectedRows: {
                    text: 'evento(s) selecionados',
                    delete: 'Apagar',
                    deleteAria: 'Apagar eventos Selecionados',
                  },
                },
              },
            },
          },
          autoComplete: {
            noOptions: 'Sem opções',
            addNew: 'Adicionar Novo',
          },
          layouts: {
            actionMenu: {
              logOut: 'Sair',
              myAccount: 'Minha Conta',
              reviewDate: 'Exclusão em Análise',
              exclusionDeadlineDate: 'Data Limite de Exclusão',
            },
            sidebar: {
              dpo: 'DPO',
              allActivities: 'Grade Geral',
              tutoredUsers: 'Usuários Tutelados',
              exclusions: 'Solicitações de Exclusão',
              feedbacks: 'Gerenciar Feedbacks',
              feedback: 'Feedback',
              myFeedbacks: 'Meus Feedbacks',
              tracks: 'Trilhas',
              event: 'Evento',
              activities: 'Atividades',
              manageCaravans: 'Coordenar Caravanas',
              myCaravans: 'Participar de Caravanas',
              editions: 'Edições',
              dashboard: 'Início',
              myAccount: 'Minha Conta',
              myCertificates: 'Meus Certificados',
              myRegistration: 'Minha Inscrição',
              postPurchase: 'Comprar Atividades',
              records: 'Cadastros',
              users: 'Usuários',
              manageUsers: 'Gerenciar Usuários',
              addUser: 'Cadastrar Usuário',
              institutions: 'Instituições',
              accessProfiles: 'Perfis de Acesso',
              satisfactionSurveys: 'Pesquisas de Satisfação',
              audit: 'Auditoria',
              caravans: 'Caravanas',
              customization: 'Customização',
              themes: 'Temas',
              home: 'Tela de Início',
              internationalization: 'Internacionalização',
              communication: 'Comunicação',
              templates: 'Modelos',
              tags: 'Etiquetas',
              userRegistration: 'Cadastrar Usuário',
              emails: 'E-mails',
              certificates: 'Certificados',
              enrollments: 'Inscrições',
              registrations: 'Gerenciar Inscrições',
              speakerActivities: 'Grade do Palestrante',
              userActivities: 'Minha Grade',
              secretary: 'Menu Secretaria',
              voucher: 'Voucher',
              terms: 'Termos do SWGE',
              privacyPolicy: 'Política de Privacidade',
              cancellationRefundPolicy: 'Política de Cancelamento e Reembolso',
              useTerm: 'Termos de Uso',
              imageTerm: 'Termo de Autorização de Uso de Imagem e Dados',
              coordinatorTerm: 'Termo de Responsabilidade do Coordenador de Caravana',
              authorizationTerm: 'Declaração de Autorização Participação Em Evento de Criança Ou Adolescente',
              apiUseTerm: 'Termos de Uso da API do Sistema',
              coordinatorAcceptance: 'Aceite do Termo de Responsabilidade',
              listParticipation: 'Minhas Participações',
              userCertificates: 'Certificados Por Usuários',
            },
          },
          changeLanguage: 'Alterar idioma',
          dialog: {
            deleteDialogTitle: 'Excluir',
            userDeleteDialogTitle: 'Solicitar Exclusão',
            userDeleteDialogContent: 'Deseja solicitar a exclusão deste cadastro?',
            deleteDialogContent: 'Deseja realmente excluir?',
            warning: 'Esta ação não pode ser desfeita.',
            userDeleteWarning:
              '*Obs: Esta ação é irreversível, para cancelar a solicitação entre em contato com o DPO do sistema. O link para o DPO é:',
            delete: 'Excluir',
            userDelete: 'Solicitar Exclusão',
            cancelDeleteDialog: 'Cancelar',
          },
          cancelDialog: {
            cancelDialogTitle: 'Cancelar Exclusão',
            cancelDialogContent: 'Deseja realmente Cancelar a Exclusão?',
            accept: 'Sim',
            refuse: 'Não',
          },
          tutoredUserCard: {
            info: 'Informação',
            specialNeedsText: 'Necessidades Especiais',
          },
          manageExclusion: {
            dialogTitle: 'Gerenciar Solicitação de Exclusão',
            send: 'Atualizar',
            cancel: 'Cancelar',
            previewPDF: 'Visualizar Arquivo Anexado',
          },
          userExclusion: {
            dialogTitle: 'Completar Solicitação de Exclusão',
            send: 'Completar',
            cancel: 'Cancelar',
          },
          toastes: {
            reviewed: 'Termo de Participação Revisado com sucesso',
            reviewedError: 'Ocorreu um erro ao revisar o Termo de Participação',
            coordinatorAccepted: 'Termo de Responsabilidade de Coordenador de Caravana aceito',
            coordinatorError: 'Houve algo de Errado com o aceite',
            voucherError: 'Já existe um voucher nesta edição atribuído a este usuário',
            registrationSave: 'Inscrição feita com sucesso',
            registrationExempted: 'Inscrição isenta com sucesso',
            cardError: 'Há alguma informação errada no cartão',
            publicKeyError: 'Há um erro interno! Volte depois',
            paymentCancel: 'Pagamento cancelado, informações removidas',
            chargeSuccess: 'Cobrança realizada com sucesso',
            chargeError: 'Ocorreu um erro ao realizar a Cobrança, tente novamente',
            chargeCancel: 'Pagamento cancelado, valor reembolsado!',
            registrationSaveError: 'Erro ao realizar a inscrição',
            userProfileSave: 'Clique em salvar para registrar a nova imagem',
            userRemovedImage: 'Imagem removida com sucesso',
            noBackgroundImage: 'Imagem de Fundo não selecionada',
            noRegistrationType: 'Tipo de Inscrição não configurada para esta edição',
            noRegistrationTypeInterval: 'Fora do período de inscrição',
            changePassword: 'Senha alterada com sucesso',
            changePasswordError: 'Erro ao registrar nova senha, tente novamente mais tarde',
            resetPassword: 'Senha alterada. Verifique seu e-mail',
            resetPasswordError: 'Se o e-mail fornecido estiver associado a uma conta em nosso sistema, enviaremos um link para redefinição de senha',
            caravanEnrollmentAlreadyError: 'Usuário já inscrito e aceito em uma Caravana',
            editionEnabledError: 'Não foi possível habilitar/desabilitar a edição',
            userEnabledError: 'Não foi possível habilitar/desabilitar o usuário',
            noCurrentEditionError: 'Selecione uma edição primeiro',
            caravanEnrollment: 'Inscrição na caravana realizada',
            caravanEnrollmentError: 'Não foi possível se inscrever na caravana',
            caravanCancelEnrollment: 'Cancelamento da inscrição na caravana realizado',
            caravanCancelEnrollmentError: 'Não foi possível cancelar a inscrição na caravana',
            accountUntie: 'Login Social desvinculado',
            accountUntieError: 'Não foi possível desvincular o login social',
            activityPlaceTimeConflict: 'Conflito de Local-Horário com a Atividade',
            save: 'Cadastro Realizado',
            saveError: 'Erro ao salvar',
            maxUserRole: 'Máximo Atingido de Usuários com a Permissão: ',
            saveNoScheduleError: 'Erro ao salvar - Não há programação para esta atividade',
            fetch: 'Busca realizada com sucesso',
            fetchError: 'Não foi possível realizar a busca',
            update: 'Atualizado com sucesso',
            updateError: 'Erro ao atualizar',
            delete: 'Removido com sucesso',
            discarded: 'Compra descartada com sucesso',
            deleteError: 'Erro ao remover',
            discardedError: 'Erro ao descartar compra',
            deletes: 'Removido com sucesso',
            deleteErrors: 'Erro ao remover',
            exclusionRequest: 'Solicitado a exclusão com sucesso',
            exclusionUpdateSave: 'Alterado a solicitação de exclusão com sucesso',
            exclusionRequestError: 'Erro ao solicitar a exclusão',
            exclusionUpdateSaveError: 'Algo deu errado ao atualizar solicitação de exclusão',
            exclusionUpdateFileError: 'É necessário inserir um anexo para concluir a solicitação',
            exclusionNoFile: 'Erro, é necessário um anexo se for Negar uma solicitação',
            cancelDelete: 'Cancelado a exclusão com sucesso',
            cancelDeleteError: 'Erro ao cancelar exclusão',
            finalDelete: 'Anonimização realizado com sucesso',
            finalDeleteError: 'Erro ao excluir ou anonimizar',
            deleteThemeErrorDefault: 'Não é possível excluir um tema padrão! Altere o tema padrão primeiro',
            selectWarning: 'A atividade escolhida tem conflito de programação com outra atividade',
            uploadFile: {
              add: 'Arquivo selecionado',
              upload: 'Arquivo adicionado',
              delete: 'Arquivo removido',
              error: 'Ocorreu um erro. Tente novamente por favor',
              noFileError: 'Selecione um arquivo JSON primeiro',
              keysError: 'Todas as entradas do arquivo JSON devem seguir a Paleta de Cores',
              stringError: 'Existem valores no arquivo JSON que não são Strings (texto)',
              syntaxError: 'O arquivo não é um JSON válido',
              fileSizeError: 'O tamanho do arquivo excede 5MB',
            },
            errorGetData: 'Não foi possível buscar os dados',
            cepFetchError: 'Não foi possível buscar dados do CEP automaticamente',
            emailSent: 'O e-mail de confirmação foi enviado com sucesso',
            emailSentError: 'Não foi possível enviar o e-mail de confirmação',
            colorPaletteValueError: 'Preencha todos os campos de cores antes de salvar',
            colorPaletteValueFormat: 'Todas os campos de cores devem ser válidos',
            colorFieldFormat: 'Campo de cor deve ser válido',
            certificateValidated: 'Certificado Validado com sucesso!',
            invalidCertificate: 'Certificado Inválido!',
            participationRegistered: 'Participação Confirmada',
            errorOnParticipationRegister: 'Erro ao confirmar Participação',
          },
          steps: {
            appBar: {
              user: {
                title: 'Botão do Menu do Usuário',
                content:
                  'Esse botão permite o usuário realizar a ação de acessar os dados de sua conta e realizar o logout da conta.\n Demonstração: ',
              },
              theme: {
                title: 'Botão de Alterar o Tema',
                content:
                  'Esse botão permite alterar o tema atual do site. O padrão é o modo Branco (representado pelo Sol) e pode ser mudado para o modo Escuro (representado pela Lua).\n Demonstração: ',
              },
              edition: {
                title: 'Botão de Alterar a Edição',
                content:
                  'Esse botão apresenta todas as edições no momento, e através dele é possível selecionar a edição que deseja acessar.\n Demonstração: ',
              },
              language: {
                title: 'Botão de Alterar a Linguagem',
                content: 'Esse botão permite trocar entre as diversas linguagens disponíveis da plataforma.\n Demonstração: ',
              },
              sideBar: {
                title: 'Botão de Abrir/Esconder a barra lateral',
                content:
                  'Esse botão permite abrir ou esconder a barra lateral que possui todos as funcionalidades do sistema.\n Demonstração: ',
              },
              tooltip: {
                edition: 'Alterar Edição',
                help: 'Ajuda',
              },
            },
          },
          enums: {
            languages: {
              'pt-BR': 'Português',
              'en-US': 'Inglês',
              'es-ES': 'Espanhol',
            },
            feedbackStatus: {
              OPEN: 'Aberto',
              ON_GOING: 'Em Andamento',
              CLOSED: 'Fechado',
            },
            enabled: {
              true: 'Habilitado',
              false: 'Não Habilitado',
            },
            confirmedBool: {
              true: 'Confirmado',
              false: 'Não Confirmado',
            },
            empty: {
              EMPTY: 'Vazio',
            },
            activityTypes: {
              LECTURE: 'Palestra',
              MINI_COURSE: 'Mini-Curso',
              WORKSHOP: 'Workshop',
              KEYNOTE: 'Keynote',
              PAPER_PRESENTATION: 'Apresentação de Trabalho',
              POSTER_PRESENTATION: 'Apresentação de Pôster',
              OTHER: 'Outro',
            },
            exclusionStatus: {
              APPROVED: 'Aprovado',
              DENIED: 'Recusado',
              CANCELED: 'Cancelado',
              NOT_ANALYZED: 'Não Analisado',

              Aprovado: 'APPROVED',
              Recusado: 'DENIED',
              Cancelado: 'CANCELED',
              'Não Analisado': 'NOT_ANALYZED',
            },
            requestType: {
              INACTIVITY: 'INATIVIDADE',
              USER: 'USUÁRIO',
              ANONYMOUS: 'ANÔNIMO',
              TUTORED: 'TUTELADO',
            },
            fonts: {
              Courier: 'Courier',
              Helvetica: 'Helvetica',
              'Times-Roman': 'Times-Roman',
              Roboto: 'Roboto',
              'Fantasque Sans': 'Fantasque Sans',
              Ubuntu: 'Ubuntu',
              'Apple Storm': 'Apple Storm',
              Lato: 'Lato',
              Poppins: 'Poppins',
              'Playfair Display SC': 'Playfair Display SC',
            },
            payed: {
              PAYED: 'Pago',
              NOT_PAYED: 'Não Pago',
              EXEMPTION: 'Isento',
            },
            confirmed: {
              CONFIRMED: 'Confirmado',
              NOT_CONFIRMED: 'Não Confirmado',
            },
            accepted: {
              ACCEPTED: 'Aceito',
              NOT_ACCEPTED: 'Não Aceito',
            },
            userTypes: {
              ATTENDEE: 'Participante',
              SPEAKER: 'Palestrante',
              COORDINATOR: 'Coordenador',
              ADMINISTRATOR: 'Administrador',
            },
            editionTypes: {
              LIVE: 'Presencial',
              ONLINE: 'Virtual',
              HYBRID: 'Híbrido',
            },
            caravanTypes: {
              GENERAL: 'Comum',
              TUTORED: 'Tutelada',
            },
            activityType: {
              LIVE: 'Presencial',
              ONLINE: 'Virtual',
              HYBRID: 'Híbrido',
            },
            genders: {
              MALE: 'Masculino',
              FEMALE: 'Feminino',
              UNDEFINED: 'Outro',
            },
            specialNeeds: {
              VISUAL: 'Visual',
              HEARING: 'Auditiva',
              LOCOMOTOR: 'Locomotora',
              SPEECH: 'Fala e Linguagem',
              MENTAL: 'Psicossocial',
              INTELLECTUAL: 'Intelectual',
              OTHERS: 'Outros',
            },
            userRoleType: {
              ATTENDEE: 'Participante',
              SPEAKER: 'Palestrante',
              CARAVAN_COORDINATOR: 'Coordenador de Caravana',
              SECRETARY: 'Secretária',
              DPO: 'Oficial de Proteção de Dados',
              GRID_COORDINATOR: 'Coordenador de Grade',
            },
            accessPermissions: {
              USER: 'USUÁRIO',
              ADM: 'ADMINISTRADOR',
            },
            passwordStrength: {
              tooWeak: 'muito fraca',
              weak: 'fraca',
              okay: 'aceitável',
              good: 'boa',
              strong: 'forte',
            },
            themeTypes: {
              LIGHT: 'Claro',
              DARK: 'Escuro',
              tooltip: {
                light: 'Alterar para o modo claro',
                dark: 'Alterar para o modo escuro',
              },
            },
            colorPalette: {
              primary: 'Cor primária do sistema. Utilizada na maioria dos componentes primários',
              secondary: 'Cor secundária do sistema. Utilizada na maioria dos componentes secundários',
              error: 'Cor indicativa de erro',
              warning: 'Cor indicativa de aviso',
              info: 'Cor indicativa de informação',
              success: 'Cor indicativa de sucesso',
              toolbarBackgroundColor: 'Cor de fundo da barra de localização',
              titleCardBackgroundColor: 'Cor de fundo da barra de título dos cards',
              appBarBackgroundColor: 'Cor de fundo da barra superior',
              sidebarBackgroundColor: 'Cor de fundo do menu lateral',
            },
            tour: {
              next: 'Próximo',
              close: 'Fechar',
              open: 'Começar Tour',
              skip: 'Pular',
              back: 'Voltar',
              last: 'Último',
            },
          },
        },
      },
      'en-US': {
        translation: {
          general: {
            applicationName: 'SWGE',
            applicationNameFull: 'Event Manager Web System',
          },
          yup: {
            pastFinalDateError: 'The final date must be greater than the initial date',
            integerError: 'The field must be an integer',
            positiveError: 'The field must be positive',
            minError: 'The field must be at least {{count}} characters long',
            maxCharactersAllowed: 'The field must be at most 100 characters',            
            requiredField: 'This is a required field',
            emailFormat: 'Email must be a valid email',
            urlFormat: 'The URL must be valid',
            passwordEqual: 'Passwords must match',
            passwordMin: 'Password must be ate least 8 characters long',
            passwordStrength: 'Password strength must be at least okay',
            maskError: 'The field most be completed',
            uniqueField: 'Already in use',
            dateIntervalError: 'Out of Interval',
            resourceTimeConflict: 'Resource-Time Conflict',
            onlyAlphabeticalCharactersAllowed: 'Only alphabetical characters allowed',
            onlyNumbersAllowed: 'Only numbers allowed',
            noSpecialCharactersAllowed: 'Type only alphabetical characters',
            invalidDate: 'Invalid date',
            invalidDateTime: 'Invalid date or time',
          },
          table: {
            applyFilters: 'Apply Filters',
          },
          datePicker: {
            prev: 'Previous Month',
            next: 'Next Month',
            placeHolderInputDate: 'mm/dd/yyyy',
            placeHolderInputDateTime: 'mm/dd/yyyy hh:mm (a|p)m',
          },
          calendar: {
            allDay: 'All Day',
            buttonText: {
              today: 'Today',
              month: 'Month',
              week: 'Week',
              day: 'Day',
              period: '{{ days }} Days',
              moreLinkText: 'more',
            },
            buttonHints: {
              prev: 'Previous $0',
              next: 'Next $0',
              today: 'This $0',
              month: 'Month View',
              week: 'Week View',
              day: 'Day View',
              moreLinkHint: 'Show $0 more events',
            },
          },
          dropzoneArea: {
            dropzoneText: 'Drag and drop an image here or click',
            preview: 'Preview:',
          },
          inactivity: {
            minutes: '$0 Minutes',
            hours: '$0 Hours',
            days: '$0 Days',
            weeks: '$0 Weeks',
            month: '1 Month',
            months: '$0 Months',
          },
          form: {
            save: 'Save',
            delete: 'Delete',
            cancel: 'Cancel',
            INSTITUTION: 'New Institution',
            EDIT_USER: 'Edit User',
            EDIT_TRACK: 'New Track',
            EDIT_CERTIFICATE: 'New Certificate',
            USER_LIST: 'Edit User',
            cancelDelete: 'Cancel Exclusion',
            finalDelete: 'Delete/Anonymize',
            userDelete: 'Request Delete',
          },
          pages: {
            home : {              
              welcome: 'Welcome ',
              description: 'SWGE (Event Management Web System) is the place to manage users, registration, certificates and more.',
              subscribe1: 'Subscribe now on {{eventName}} in {{city}} - {{ state }} ({{ initialDate }} to {{ finalDate }}).',
              subscribe2: 'You are already registered in {{ eventName }} in {{ city }} - {{ state }} ({{ initialDate }} a {{ finalDate }}).',              
              subscribeSubtitle: 'Be part of the largest community of free code enthusiasts and open technologies in Latin America.',
              button1: 'subscribe now',
              button2: 'subscribed',
              button3: 'Caravans',
              textCaravan: 'If you want to participate in a caravan click "Check Caravans" and make sure your institution has a caravan.',
              programation: 'Schedule',
            },
            registration: {
              steps: {
                step1: 'Register',
                step2: 'Activities',
                step3: 'Cart',
                step4: 'Payment',
                step5: 'Summary',
                step6: 'Finished',
              },
              step1: {
                title:'Inscription {{ eventName }}',
                column1:{ 
                  title:"Event",
                  text1:"Registrations until ",
                  text2:"Upon ",
                },
                column2:{
                  title:"DATA FOR REGISTRATION",
                  subTitle:"Mandatory data for registration at the event",
                  msgRequeriment: "Mandatory, to complete the payment",
                  checkLabel: "Speaker?"
                },
                column3:{title:"Calendar"},
                cardHint:"Click to see more details"                 
              },
              step2: {
                title1:"Registration in ",
                subtitle1:"What is included in the inscription",
                title2:"Registration of optional additional activities:",
                subtitle2:"Select the activities to add them to the shopping cart",
                msgRow1:'You can go back and buy more events at ',
                msgRow2:'on the "My Registration" page',
                noActivities:"No additional activity added",
                card: {
                  showMore: "See activities included",                  
                  buttonOn: "Include",
                  buttonOff: "Included",
                  details: "Details",
                  free: "Free",
                }
              },
              step3: {
                title:"Cart",
                subtitle:"Select the type of collection",
                card: {
                  button: "Remove from cart",                    
                },
                dialog: {
                  title: 'Remove cart activity',
                  text: "You're sure you want to remove"
                }
              },
              step4: {
                title:"Payment", 
                loading: "Process...",   
                textButtonCancelPayment: "Cancel order",
                textButtonConfirmOrder: "Confirm order",     
                textButtonCancelRegistration: "Cancel registration",    
                dialog: {
                  title: 'Cancel registration at the event',                    
                  text: 'Are you sure you want to cancel process of registration?',
                  buttonCancel: 'Cancel',
                  buttonConfirm: 'Confirm',
                }          
              },
              step5: {
                msgCartEmpty:"Cart empty",
              },
              step6: {
                title1:"Concluded!",
                title2:"Registration confirmed!",
                textRow1:"Your registration will be made after payment confirmation!",
                textRow2:"Your registration was made.Soon, you will receive more details in the email informed.",
              },
            },
            coordinatorTerm: {
              accept: 'Accept',
            },
            participationMenu: {
              camPermission: 'Request camera permissions',
              registeredParticipation: 'Participation Confirmed!',
              registeredParticipationText: 'Registration and confirmation of participation were carried out for the following activity: ',
              close: 'Close',
              nonMobile: 'Mobile-only functionality',
              tabs: {
                registerParticipation: 'Register Participation',
                participationRegistered: 'Registered Participations',
              },
              columns: {
                activityName: 'Activity Name',
                onRegister: 'Register Date',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No participation found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Participation per page:',
                    displayRows: 'from',
                    jumpToPage: 'View page:',
                  },
                  toolbar: {
                    search: 'Search',
                    viewColumns: 'View Columns',
                    filterTable: 'Filter Table',
                    downloadCsv: 'Download JSON',
                    print: 'Print',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'View Columns',
                    titleAria: 'View/Hide Columns',
                  },
                  selectedRows: {
                    text: "Selected Participation's",
                    delete: 'Delete',
                    deleteAria: 'Delete Selected Participation',
                  },
                },
              },
            },
            tags: {
              docMargin: 'Document Margin',
              tagMargin: 'Tag Margin',
              tagSize: 'Tag Size',
              tagWidthSize: 'Width',
              tagHeightSize: 'Height',
              marginTopBottom: 'Top',
              marginLeftRight: 'Left',
              colSize: 'Number of Columns',
              rowSize: 'Number of Lines',
              startIndex: 'Start Index',
              selectedUsers: 'Number of Selected Users:',
              tagFilePreview: 'Select Rows and Columns of the Tag File',
              selectedIndexes: 'Selected indices',
              pageSize: 'Page Style and Size',
              openPreview: 'Preview Tags',
              previewTags: 'File with Tags',
              columns: {
                userName: 'Name',
                tagName: 'TagName',
                caravanEnrollmentCount: 'Caravan Enrolled Users',
                enrollmentSituation: 'Enrollment Status',
                userCaravan: 'User In Caravan?',
                userNotInCaravan: 'Not In a Caravan',
                situation: {
                  notFound: 'Not Found',
                  completed: 'Completed',
                  missAuthorization: 'No Authorization Term',
                  notPayed: 'Not Payed',
                  notAccepted: 'Not Accepted',
                },
              },
              tooltip: {
                generateTags: 'Generate Tags',
              },
              tabs: {
                registeredUsers: 'Users',
                registeredTutoredUsers: 'Tutored Users',
                caravans: 'Caravans',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No tags found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Labels per page:',
                    displayRows: 'from',
                    jumpToPage: 'View page:',
                  },
                  toolbar: {
                    search: 'Search',
                    viewColumns: 'View Columns',
                    filterTable: 'Filter Table',
                    downloadCsv: 'Download JSON',
                    print: 'Print',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'View Columns',
                    titleAria: 'View/Hide Columns',
                  },
                  selectedRows: {
                    text: "Selected label's",
                    delete: 'Delete',
                    deleteAria: 'Delete Selected Tags',
                  },
                },
              },
            },
            noticePanel: {
              tooltip: {
                delete: 'Delete',
                cancel: 'Cancel',
                add: 'Add',
                edit: 'Edit',
              },
              editor: 'Insert the text here...',
              delete: 'Delete',
            },
            editionNotFound: {
              home: 'Home',
              notFound: 'Edition Not Found!',
            },
            editionNotEnabled: {
              home: 'Home',
              notEnabled: 'Edition Not Enabled to Accessing!',
            },
            registrationInterval: {
              registrationNotInterval: 'Not within the registration interval',
            },
            messageWarningUser: {
              warningUser: 'Caravan payment not confirmed, please contact the caravan coordinator!',
            },
            login: {
              email: 'Email',
              password: 'Password',
              login: 'Login',
              backToHomePage: 'Go to website',
              forgotPassword: 'Forgot my Password',
              google: 'Google',
              facebook: 'Facebook',
              linkedin: 'LinkedIn',
              gitHub: 'GitHub',
              noRecord: 'Not registered yet? ',
              register: 'Sign up now',
              socialsLogin: 'Or log in with',
              loginError: 'Wrong email and/or password',
              emailConfirmationError: 'Email not confirmed!',
              resendEmailConfirmation: 'Click here to resend the email',
              msgOldRegisters: '* If you have a record in previous editions, make a new record for current edition.',
              validateUserDisabled: 'User Disabled',
              rememberMe: 'Remember me',
              terms: {
                privacyPolicy: 'Privacy Policy',
                cancellationRefundPolicy: 'Cancellation and Refund Policy',
                useTerm: 'Application Terms of Use',
                apiUseTerm: 'System API Terms of Use',
                imageTerm: 'Term of Authorization for General Image Use',
                useTermAgreement: 'By registering I accept the',
                privacyPolicyAgreement: ', I agree with',
                and: 'and the',
              },
              oldAcess: "The login used in the last edition is not valid. Please register again.",
            },
            terms: {
              privacyPolicy: 'Privacy Policy',
              cancellationRefundPolicy: 'Cancellation and Refund Policy',
              useTerm: 'Terms of Use',
              imageTerm: 'Authorization for Use of Image and Data',
              coordinatorTerm: "Caravan Coordinator's Termo of Responsibility",
              authorizationTerm: 'Declaration of Authorization Participation in a Child or Teenager Event',
              apiUseTerm: 'System API Terms of Use',
            },
            pageNotFound: {
              notFound: 'Page not Found',
              home: 'back to home',
            },
            pageNotAllowed: {
              notAllowed: 'Not Allowed',
              home: 'back to home',
            },
            editEmail: {
              toolbar: {
                title: 'New Email',
              },
              subject: 'Subject',
              class: 'Class',
              save: 'Save Email',
              fromTemplate: 'Apply Template?',
              templates: 'Templates',
              date: 'Date',
              time: 'Time',
              enable: 'Enabled?',
              userMode: 'Filter',
              selectedUsers: 'Selected Users',
              selectedUserTypes: 'Selected User Types',
              subscriberMode: 'Filter',
              selectedSubscribers: 'Selected Subscribers',
              selectedSubscriberTypes: 'Selected Subscriber Types',
              selectedCaravans: 'Selected Caravans',
              selectedRecipients: 'Selected Recipients',
            },
            tutoredUserTable: {
              columns: {
                name: 'Name',
                idNumber: 'ID Number',
                country: 'Country',
                exclusionDate: 'Maximum Exclusion Date',
              },
              tableOptions: {
                title: 'Tutored Users',
                textLabels: {
                  body: {
                    noMatch: 'No tutored users found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Tutored Users per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download JSON',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'tutored user(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected tutored users',
                  },
                },
              },
            },
            editEnrollmentType: {
              toolbar: {
                title: 'New Enrollment Type',
              },
              tooltip: {
                add: 'Add Promotional Price',
              },
              name: 'Name',
              description: 'Description',
              save: 'Save Enrollment Type',
              initialDate: 'Initial Date',
              initialTime: 'Initial Time',
              finalDate: 'End Date',
              finalTime: 'End Time',
              enable: 'Enabled?',
              paymentAccounts: 'Payment Accounts',
              exemption: 'Exempt User',
              vacancies: 'Vacancies',
              certificate: 'Certificate',
              price: 'Price',
              coverage: 'Enrollment Coverage',
              columns: {
                promotionalPrice: 'Promotional Price',
                initialDate: 'Initial Date',
                initialTime: 'Initial Time',
                finalDate: 'End Date',
                finalTime: 'End Time',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No promotional prices found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Prices per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download JSON',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'Price(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected prices',
                  },
                },
              },
            },
            editTemplate: {
              toolbar: {
                title: 'New Template',
              },
              tooltip: {
                add: 'Add Content',
              },
              positions: 'Content',
              name: 'Name',
              type: 'Type',
              save: 'Save Template',
              preview: 'Preview Certificate?',
              previewTag: 'Preview Tag?',
              previewEmail: 'Preview Email?',
              dimensionX: 'Width of the Certificate',
              dimensionY: 'Height of the Certificate',
              dimensionXTag: 'Width of the Tag',
              dimensionYTag: 'Height of the Tag',
              columns: {
                xPosition: 'X',
                yPosition: 'Y',
                content: 'Content',
                fontSize: 'Font Size',
                bold: 'Bold',
                italic: 'Italic',
                underlined: 'Underlined',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No content found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Positions per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download JSON',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'position(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected positions',
                  },
                },
              },
            },
            editSatisfactionSurvey: {
              toolbar: {
                title: 'New Satisfaction Survey',
              },
              templates: 'Templates',
              fromTemplate: 'Apply Template?',
              subject: 'Subject',
              name: 'Name',
              save: 'Create Survey',
              date: 'Date',
              time: 'Time',
              url: 'URL',
              condition: 'Condition',
              event: 'Event',
              selectedTarget: 'Selected people',
              target: 'Target Audience',
              activation: 'Trigger',
              enable: 'Enabled?',
              back: 'Back',
              next: 'Next',
              step1: 'Basic Information',
              step2: 'Activation Trigger',
              step3: 'Email Customization',
            },
            editAccessProfile: {
              toolbar: {
                title: 'New Access Profile',
              },
              name: 'Name',
              save: 'Create Profile',
              description: 'Description',
              permissions: 'Permissions',
            },
            editInternationalization: {
              toolbar: {
                title: 'New Language',
              },
              tooltip: {
                upload: 'Upload JSON',
              },
              chooseJSON: {
                dropzoneText: 'Drag and drop a file here or click',
              },
              name: 'Name',
              save: 'Save',
              enable: 'Enabled?',
              columns: {
                cod: 'Cod',
                description: 'Description',
                editor: 'Text',
                reference: 'Reference',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No fields found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Fields per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download JSON',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'fields(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected fields',
                  },
                },
              },
            },
            editTheme: {
              toolbar: {
                editTheme: 'Update Theme',
                newTheme: 'New Theme',
              },
              tooltip: {
                upload: 'Upload JSON',
                download: 'Download JSON',
              },
              chooseJSON: {
                dropzoneText: 'Drag and drop a file here or click',
              },
              id: 'ID',
              name: 'Name',
              type: 'Type',
              save: 'Save',
              preview: 'Preview Changes?',
              isDefault: 'Default Theme?',
              delete: 'Delete',
              columns: {
                cod: 'Cod',
                description: 'Description',
                editor: 'Color',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No fields found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Fields per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download JSON',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'fields(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected fields',
                  },
                },
              },
            },
            editManageCaravan: {
              toolbar: {
                title: 'Manage Caravan',
              },
              tooltip: {
                add: 'Add',
                preview: 'View Term of Authorization',
                users: {
                  add: 'Add Registration',
                },
              },
              review: 'Review',
              reviewed: 'REVIEWED',
              notReviewed: 'NOT REVIEWED',
              whoReview: 'Reviewed by',
              tutoredUser: {
                name: 'Name',
                idNumber: 'ID Number',
                tagName: 'Tag Name',
                gender: 'Gender',
                country: 'Country',
                cellPhone: 'Cell Phone',
                birthDate: 'Birth Date',
                authorizationTerm: 'Click here to view the Participation Authorization Term',
                dropzoneText: 'Drag and drop a PDF file here or click',
              },
              tutoredUserTable: {
                columns: {
                  name: 'Name',
                  idNumber: 'ID Number',
                  country: 'Country',
                },
                tableOptions: {
                  title: 'Tutored Users',
                  textLabels: {
                    body: {
                      noMatch: 'No tutored users found',
                      toolTip: 'Sort',
                    },
                    pagination: {
                      next: 'Next page',
                      previous: 'Previous page',
                      rowsPerPage: 'Tutored Users per page:',
                      displayRows: 'of',
                      jumpToPage: 'Go to page:',
                    },
                    toolbar: {
                      search: 'Search',
                      downloadCsv: 'Download JSON',
                      print: 'Print',
                      viewColumns: 'Show Columns',
                      filterTable: 'Filter Table',
                    },
                    filter: {
                      all: 'All',
                      title: 'FILTERS',
                      reset: 'RESET',
                    },
                    viewColumns: {
                      title: 'Show Columns',
                      titleAria: 'Show/Hide Columns',
                    },
                    selectedRows: {
                      text: 'tutored user(s) selected',
                      delete: 'Delete',
                      deleteAria: 'Delete selected tutored users',
                    },
                  },
                },
              },
              id: 'ID',
              type: 'Type',
              payed: 'Payment Status',
              name: 'Name',
              country: 'Country',
              state: 'State/Province',
              city: 'City',
              coordinator: 'Coordinator',
              vacancies: 'Number of Vacancies',
              remainingVacancies: 'Remaining Vacancies',
              price: 'Price',
              institution: 'Institution',
              insertDialogTitle: 'Choose a attendee or insert a new user',
              cancelInsertDialog: 'Save',
              user: 'User',
              caravanInformation: 'Caravan Information',
              tutoredCertificates: 'Users Certificates',
              notices: 'Notices',
              authorizationPreview: 'Preview of the Participation Authorization Term',
              notAccepted: 'Coordinator does not agree with the terms',
              notAcceptedText:
                "The caravan coordinator didn't accepted the Caravan Coordinator Responsability term. To use the features assigned to a Caravan Coordinator, please read and accept the terms.",
              goToPage: 'Go to the Responsability Agreement acceptance page',
              columns: {
                name: 'Name',
                email: 'Email',
                phone: 'Phone',
                cellPhone: 'Cell Phone',
                payed: 'Paid?',
                confirmed: 'Confirmation Status',
                accepted: 'Accepted?',
                idNumber: 'RG',
                authorization: 'Authorization Term',
                editAuthorization: 'Edit Authorization Term',
              },
              delete: 'Delete',
              tableOptions: {
                title: 'Registrations',
                textLabels: {
                  body: {
                    noMatch: 'No registrations found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Registrations per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download JSON',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'registration(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected registrations',
                  },
                },
              },
            },
            editCaravan: {
              toolbar: {
                editCaravan: 'Update Caravan',
                newCaravan: 'New Caravan',
              },
              id: 'ID',
              type: 'Type',
              payed: 'Payment Status',
              name: 'Name',
              country: 'Country',
              state: 'State/Province',
              city: 'City',
              coordinator: 'Coordinator',
              vacancies: 'Number of Vacancies',
              price: 'Price',
              institution: 'Institution',
              save: 'Save',
              delete: 'Delete',
            },
            editTag: {
              toolbar: {
                title: 'New Tag',
              },
              name: 'Name',
              tagTemplate: 'Tag Template',
              confirmationTemplate: 'Confirmation Tag Template',
              save: 'Save Tag',
            },
            editHome: {
              language: 'Language',
              content: 'Home Screen Content...',
              toolbar: {
                title: 'Home',
              },
              saveChanges: 'Save Changes',
            },
            editCertificate: {
              toolbar: {
                editCertificate: 'Update Certificate',
                newCertificate: 'New Certificate',
              },
              tooltip: {
                add: 'Add Content',
                preview: 'Preview Certificate',
                enableQrCode: 'Enable QR Code',
                help: 'Help',
              },
              help: {
                title: 'Help with RESERVED WORDS',
                content:
                  'In the text inserted inside the dynamic content, it is possible to add reserved words, to be replaced with the dynamic values for each user, edition, activity or track. The words are presented below and the meaning of each one.',
                track: {
                  title: 'Track',
                  name: 'Track Name',
                  initialDate: 'Track start date',
                  finalDate: 'Track end date',
                },
                user: {
                  title: 'User',
                  name: 'User Name',
                },
                edition: {
                  title: 'Edition',
                  name: 'Edition name',
                  year: 'Edition year',
                  institution: 'Editing Institution',
                },
                activity: {
                  title: 'Activity',
                  name: 'Activity Name',
                  workload: 'Activity Hour Load',
                  grid: 'Activity will take place all day',
                  data: 'Date',
                  start: 'Start',
                  end: 'End',
                  schedule: 'Activity Schedule',
                },
              },
              id: 'ID',
              name: 'Name',
              save: 'Save',
              delete: 'Delete',
              availabilityDateTime: 'Availability Date and Time',
              insertDialogTitle: 'Insert Dynamic Content',
              cancelInsertDialog: 'Insert',
              backgroundImage: {
                dropzoneText: 'Drag and drop a background image here or click',
              },
              certificatePDF: {
                dropzoneText: 'Drag and drop a PDF file here or click',
              },
              certificatePreview: 'Certificate Preview',
              userCertificate: 'Certificates of User: ',
              columns: {
                x: 'X',
                y: 'Y',
                content: 'Content',
                fontSize: 'Font Size',
                font: 'Family Font',
                color: 'Text Color',
                bold: 'Bold',
                italic: 'Italic',
                underlined: 'Underlined',
              },
              tableOptions: {
                title: 'Dynamic Content',
                textLabels: {
                  body: {
                    noMatch: 'No dynamic contents found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Contents per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'content(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected contents',
                  },
                },
              },
            },
            editInstitution: {
              toolbar: {
                editInstitution: 'Update Institution',
                newInstitution: 'New Institution',
                languageFlag: 'Flag',
              },
              id: 'ID',
              name: 'Name',
              shortName: 'Abbreviation',
              country: 'Country',
              state: 'State',
              city: 'City',
              phone: 'Phone',
              mobilePhone: 'Mobile Phone',
              website: 'Website',
              save: 'Save',
              delete: 'Delete',
              insertDialogTitle: 'Insert Space',
              updateDialogTitle: 'Update Space',
              cancelInsertDialog: 'Insert',
              isHost: 'Is the institution hosting the event?',
              columns: {
                name: 'Name',
                number: 'Number',
              },
              tableOptions: {
                title: 'Spaces',
                textLabels: {
                  body: {
                    noMatch: 'No spaces found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Spaces per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'space(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected spaces',
                  },
                },
              },
            },
            editTrack: {
              toolbar: {
                editTrack: 'Update Track',
                newTrack: 'New Track',
              },
              id: 'ID',
              name: 'Name',
              initialDate: 'Initial Date',
              finalDate: 'Final Date',
              description: 'Track Description...',
              speakerCertificate: "Speakers' Certificate",
              attendeeCertificate: "Attendees' Certificate",
              save: 'Save',
              delete: 'Delete',
            },
            editEdition: {
              toolbar: {
                editEdition: 'Update Edition',
                newUser: 'New Edition',
              },
              enabled: 'Enabled?',
              id: 'ID',
              name: 'Name',
              shortName: 'Abbreviation',
              initialDate: 'Initial Date',
              finalDate: 'Final Date',
              country: 'Country',
              state: 'State',
              city: 'City',
              place: 'Host Place',
              description: 'Description',
              editionType: 'Edition Type',
              year: 'Year',
              save: 'Save',
              goToCustomization: 'Go to Customization',
              delete: 'Delete',
            },
            myRegistration: {
              toolbar: {
                myRegistration: 'My Registration',
              },
              tooltip: {
                calendar: 'Calendar',
              },
              id: 'ID',
              payed: 'Payed',
              notPayed: 'Not Payed',
              status: 'Status',
              totalPrice: 'Total Value',
              registrationTypePrice: 'Registration Value',
              activitiesPrice: 'Activities Value',
              scheduleTitle: 'View Schedule',
              payment: {
                title: 'Payment',
                cardDetails: 'Card Details',
                pagSeguroText: 'Payment processed by ',
                summary: 'Summary',
                total: 'Total',
                number: 'Card Number',
                holder: 'Holder Name',
                expMonth: 'Month',
                expYear: 'Year',
                cvv: 'CVV',
                expires: 'Expiration Date',
                securityCode: 'Security Code',
                confirmPayment: 'Confirm Payment',
                cancelPayment: 'Cancel Payment',
                installments: 'Installment(s) of',
                possibleInstallment: 'Portion',
                subTotal: 'Subtotal',
                promotion: 'Discount',
                paypal: 'PayPal',
                pagseguro: 'PagSeguro',
                sendPix: 'View QrCode/PIX Key',
                verifyPayment: 'Verify Payment',
                pixCopyText: 'PIX Copy and Paste',
                pixQrCode: 'PIX QrCode',
                pix: 'PIX',
                pixDetails: 'Billing per PIX',
                pixKey: 'Pix Key',
                receiver: {
                  name: 'Recipient Name',
                  key: 'PIX key',
                },
                paymentStatus: {
                  title: 'Billing Status',
                  chargeSuccess:
                    'Payment made successfully! An email has been sent with more information, and to track the status of your payment, check this page every 5 minutes.',
                  cancelSuccess:
                    'Cancellation successful! An email has been sent with more information about the cancellation, and a full refund of the registration fee and paid activities is being made.',
                  voucherFound:
                    'Registration Exempt! An exemption voucher was found linked to this email, the registration cost was waived.',
                },
              },
              activityCard: {
                track: 'Track',
                speakers: 'Speakers',
                responsible: 'Responsible',
                noResponsible: 'No Responsible',
                noSchedule: 'No schedule',
                vacancies: 'Vacancies',
                workload: 'Workload',
                type: 'Activity Type',
                presentationType: 'Presentation Type',
                presentationRemote: "Atividade Remota",
                language: 'Language',
                price: 'Price',
                conflict: 'Conflict',
                start: 'Start',
                end: 'End',
                date: 'Date',
                calendarTooltip: 'Calendar details',
                speakerInfo: {
                  biography: 'Biography',
                  contact: 'Contact Information',
                  additionalInformation: 'Additional Information',
                },
                trackInfo: {
                  description: 'Description',
                  initialDate: 'Initial Date',
                  finalDate: 'Final Date',
                },
                noDescription: 'Activity has no description',
                includeInPurchase: 'include in purchase',
                removeFromCart: 'Remove from purchase',
                tooltipDescription: 'Click to see more',
                schedule: 'Schedule activity',
              },
              lecturePackCard: {
                activityList: 'Activity List',
                title: 'Basic Activities',                
              },
              titleTable: 'Optional activities acquired',
              viewConflicts: 'View Conflict'
            },
            myFeedbacks: {
              toolbar: {
                title: 'New Feedback',
              },
              tooltip: {
                add: 'Add Feedback',
              },
              title: 'Title',
              description: 'Feedback description...',
              save: 'Save',
              date: 'Creation Date',
              status: 'Status',
            },
            myCertificates: {
              date: 'Availability date',
            },
            myAccount: {
              toolbar: {
                myAccount: 'My Account',
                editMyAccount: 'Edit My Account',
              },
              admin: 'Administrator',
              enabled: 'Enabled',
              notEnabled: 'Not Enabled',
              confirmed: 'Confirmed email',
              notConfirmed: 'Not confirmed email',
              communication: 'Receive information about this event by email',
              editProfile: 'Edit Profile',
              gitHub: 'Untie GitHub Account',
              google: 'Untie Google Account',
              untieDialogTitle: 'Untie Social Account',
              untieDialogContent: 'Do you really want to untie this method?',
              untie: 'Untie',
              sliderScale: 'Scale',
              sliderRotation: 'Rotation',
              changeUserImageContent: 'Do you really want to change the User image?',
              changeUserImage: 'Save',
              removeUserImage: 'Remove Current Image',
              goBack: 'Return',
              changeUserImageTitle: 'Change User Image',
              allowEmail: 'I wish to receive information about this event by email.',
              allowContact: 'I allow the platform to make my social contacts available so that others can contact me.',
              addressUnique: 'Address',
              id: 'ID',
              name: 'Name',
              cardName: 'Name for Badge',
              idNumber: 'Identification Number',
              gender: 'Gender',
              birthDate: 'Birthdate',
              email: 'Email',
              github: 'Github',
              linkedin: 'Linkedin',
              lattes: 'Lattes',
              website: 'Website',
              orcid: 'Orcid',
              biography: 'Biography',
              zipCode: 'Zip Code',
              address: 'Address Line 1',
              address2: 'Address Line 2',
              nin: 'NIN',
              mobilePhone: 'Mobile Phone',
              phone: 'Phone',
              country: 'Country',
              state: 'State/Province',
              city: 'City',
              company: 'Institution',
              function: 'Job Title',
              save: 'Save',
              delete: 'Delete Account',
              password: {
                password: 'Password',
                passwordConfirmation: 'Password Confirmation',
                tooShort: 'too short',
              },
              specialNeedsText: 'PwD - Person with Disability',
              otherNeeds: 'Other Disabilities',
            },
            changePassword: {
              tollbar: {
                changePassword: 'Create new password'
              },
              new_password: 'New password',
              save: 'Register new password',
              messageTitleReset: "Hello! A password reset request has been logged for this account. To ensure your security, set a new password.",
              messageTitleRedefine: "Enter the new password you want to use to access this account.",
            },
            resetPassword: {
              toolbar: {
                resetPassword: 'Reset Password',
              },
              email: 'Email',
              reset: 'Reset Password',
              cancel: 'Cancel',
            },
            emailConfirmation: {
              toolbar: {
                emailConfirmation: 'Email Confirmation',
              },
              confirmed: 'Email Confirmed. Thanks!',
              notConfirmed: 'It was not possible to confirme your email address!',
              cancel: 'Home',
            },
            certificateValidation: {
              toolbar: {
                validation: 'Certificate Validation',
              },
            },
            autoRegistration: {
              toolbar: {
                newUser: 'New User',
              },
              subtitle1: 'User Data',
              subtitle2: 'Additional (optional) information',
              subtitle3: 'Address',
              allowEmail: 'I allow to contact me about this event by email.',
              addressUnique: 'Address',
              gitHub: 'Registration with GitHub',
              google: 'Registration with Google',
              id: 'ID',
              name: 'Full name (will be used in the issuance of the certificate)',
              cardName: 'Name for Badge',
              idNumber: 'Identification Number',
              gender: 'Gender',
              birthDate: 'Birthdate',
              email: 'Email',
              github: 'Github',
              linkedin: 'Linkedin',
              lattes: 'Lattes',
              website: 'Website',
              orcid: 'Orcid',
              biography: 'Add biography',
              zipCode: 'Zip Code',
              address: 'Address Line 1',
              address2: 'Address Line 2',
              nin: 'NIN',
              mobilePhone: 'Mobile Phone',
              phone: 'Phone',
              country: 'Country',
              state: 'State/Province',
              city: 'City',
              company: 'Institution',
              function: 'Job Title',
              save: 'Save',
              exempt: 'Exempt User',
              accessProfile: 'Access Profile',
              password: {
                password: 'Password',
                passwordConfirmation: 'Password Confirmation',
                tooShort: 'too short',
              },
              specialNeedsText: 'PwD - Person with Disability',
              otherNeeds: 'Other Disabilities',
              terms: {
                privacyPolicy: 'Privacy Policy',
                cancellationRefundPolicy: 'Cancellation and Refund Policy',
                useTerm: 'Terms of Use',
                imageTerm: 'Authorization for Use of Image and Data',
                coordinatorTerm: "Caravan Coordinator's Termo of Responsibility",
                authorizationTerm: 'Declaration of Authorization Participation in a Child or Teenager Event',
                apiUseTerm: 'System API Terms of Use',
                useTermAgreement: 'By registering I accept the',
                privacyPolicyAgreement: ', I agree with',
                and: 'and the',
              },
              alerts: {
                text1: 'After completing the system registration, you will receive an email to confirm the email.',
                text2: 'This registration does not register at the event, upon entering the platform register at the preference event.',
                text3: 'To complete the registration, you need to read and agree with the terms of use of the system.',
              }
            },
            dpoManage: {
              user: 'User',
              tutoredUser: 'Tutored User',
              requestDate: 'Request Date',
              note: 'Note',
              applicantContact: 'Applicant Phone',
              requestType: 'Request Type',
              attachment: 'Attachment',
              id: 'Id',
              registryDate: 'Request Date',
              status: 'Request Status',
              returnDate: 'Return Date',
              deadlineExclusionDate: 'Exclusion deadline',
              deleteRequestBegin: 'Delete Request Data',
              deleteRequestEnd: 'Exclusion Data',
            },
            userExclusion: {
              user: 'User',
              requestDate: 'Request Date',
              note: 'Note',
              applicantContact: 'Applicant Phone',
              requestType: 'Request Type',
              attachment: 'Attachment',
              id: 'Id',
              registryDate: 'Request Date',
              status: 'Request Status',
              returnDate: 'Return Date',
              deadlineExclusionDate: 'Exclusion deadline',
              effectiveDeletionDate: 'Effective Delete Date',
            },
            editActivity: {
              toolbar: {
                editActivity: 'Update Activity',
                newActivity: 'New Activity',
              },
              id: 'ID',
              name: 'Title',
              type: 'Type',
              track: 'Track',
              description: 'Activity Description...',
              speakerCertificate: "Speakers' Certificate",
              attendeeCertificate: "Attendees' Certificate",
              responsibleUser: 'Lead Schedule Coordinator',
              speaker: 'Speaker',
              vacancies: 'Vacancies',
              price: 'Price',
              language: 'Language',
              languageFlag: 'Flag',
              presentationType: 'Presentation Type',
              workload: 'Workload',
              certificate: 'Certificate',
              save: 'Save',
              delete: 'Delete',
              place: 'Place',
              placeUrl: 'Website',
              scheduleDialogTitle: 'Manage Schedule',
              calendarColor: 'Color in Schedule',
            },
            speakerActivityList: {
              activityCard: {
                name: 'Name',
                type: 'Activity Type',
                speakers: 'Speakers',
                language: 'Language',
                responsibleUser: 'Lead Schedule Coordinator',
                presentationType: 'Presentation Type',
                place: 'Place',
                webPlace: 'WebPlace',
                track: 'Track',
                description: 'Description',
                activityDialogTitle: 'Speaker Activities',
              },
            },
            userActivities: {
              helper: {
                editActivities: {
                  title: 'Change to activity selection',
                  content:
                    'This button allows you to switch to custom grid editing. Through editing, it allows you to present only the programs selected by you. For demonstration, click on the button to see the next tutorial.',
                },
                favorite: {
                  title: 'Select a Schedule',
                  content:
                    'By clicking on the star, it is possible to check or uncheck the scheduled activity for it to belong to the custom grid.\n Demonstration:',
                },
                favoriteAll: {
                  title: 'Select All Activity',
                  content:
                    'By clicking on the event, another button with a star icon appears. By clicking this button, it is possible to check or uncheck all schedules of the activity in the custom grid.\n Demonstration: ',
                },
                fab: {
                  title: 'Save custom grid',
                  content: 'Using this button it is possible to save all the selected programming.',
                },
              },
              activityCard: {
                name: 'Name',
                type: 'Activity Type',
                speakers: 'Speakers',
                language: 'Language',
                presentationType: 'Presentation Type',
                local: "Local",
                place: 'Place',
                webPlace: 'WebPlace',
                availabilityLink: 'Access link will be made available after registration payment',
                track: 'Track',
                description: 'Description',
                activityDialogTitle: 'Speaker Activities',
              },
              addSchedule: 'Add Selected Schedule',
              removeSchedule: 'Remove Selected Schedule',
              addActivity: 'Add all schedules from selected activity',
              removeActivity: 'Remove all schedules from selected activity',
              removedSchedules: 'Removed Schedules',
              editUserSchedule: 'Change to edit schedules',
              saveUserSchedule: 'Save custom schedule',
              userSchedule: 'Change to selected schedules',
              schedule: {
                title: 'Schedule',
                name: 'Title',
                allDay: 'All day',
                initialDate: 'Initial Date',
                finalDate: 'Final Date',
              },
            },
            editUser: {
              toolbar: {
                editUser: 'Update User',
                newUser: 'New User',
              },
              admin: 'Administrator?',
              changePassword: 'Create new Password',
              resetPassword: 'Reset Password',
              addRegistration: 'Add Registration',
              id: 'ID',
              name: 'Name',
              cardName: 'Name for Badge',
              idNumber: 'Identification Number',
              gender: 'Gender',
              birthDate: 'Birthdate',
              email: 'Email',
              github: 'Github',
              linkedin: 'Linkedin',
              lattes: 'Lattes',
              website: 'Website',
              orcid: 'Orcid',
              biography: 'Add biography',
              zipCode: 'Zip Code',
              address: 'Address Line 1',
              addressUnique: 'Address',
              address2: 'Address Line 2',
              nin: 'NIN',
              mobilePhone: 'Mobile Phone',
              phone: 'Phone',
              country: 'Country',
              state: 'State/Province',
              city: 'City',
              company: 'Institution',
              function: 'Job Title',
              userPermissions: 'User Permissions',
              save: 'Save',
              delete: 'Delete',
              reset: 'Reset Password',
              cancelResetDialog: 'Cancel',
              resetDialogTitle: 'Reset Password',
              resetDialogContent: 'Do you really want to delete reset the password of this user?',
              accessProfile: 'Access Profile',
              allowEmail: 'Send Information about this event by email?',
              password: {
                password: 'Password',
                passwordConfirmation: 'Password Confirmation',
                tooShort: 'too short',
              },
              confirmedEmail: 'E-mail confirmed?',
              resendEmail: 'Resend E-mail',
              enable: 'Enabled?',
              specialNeedsText: 'PwD - Person with Disability',
              otherNeeds: 'Other Disabilities',
            },
            accessProfileList: {
              toolbar: {
                title: 'All Profiles',
              },
              tooltip: {
                add: 'Add profile',
              },
              columns: {
                name: 'Name',
                description: 'Description',
                permissions: 'Permissions',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No access profiles found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Profiles per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'profiles(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected profiles',
                  },
                },
              },
            },
            registrationList: {
              registrationDialog: {
                title: 'Registration Information',
              },
              registrationActivity: {
                activityUser: 'User Activities',
              },
              toolbar: {
                title: 'All Registrations',
              },
              tooltip: {
                add: 'Add',
                preview: 'View Term of Authorization',
              },
              authorizationPreview: 'Preview of the Participation Authorization Term',
              columns: {
                user: 'User',
                registrationDateTime: 'Registration Date and Time',
                payed: 'Payment Status',
                price: 'Price',
                percentage: 'Percentage (%)',
                authorization: 'Authorization Term',
              },
              tabs: {
                registrations: 'User Registrations',
                tutoredRegistrations: 'Tutored User Registrations',
                config: 'Registration Configuration',
              },
              configForm: {
                initialDateTime: 'Initial Date and Time',
                finalDateTime: 'Final Date and Time',
                price: 'Price',
                id: 'ID',
                columns: {
                  initialDateTime: 'Initial Date and Time',
                  finalDateTime: 'Final Date and Time',
                  price: 'Price',
                  vacancies: 'Vacancy Availability',
                  percentage: 'Percentage (%)',
                },
                tooltip: {
                  add: 'Add Promotional Price',
                },
                insertDialogTitle: 'Add Promotional Price',
                cancelInsertDialog: 'Save',
                delete: 'Delete',
                cancelDeleteDialog: 'Cancel',
                deleteDialogTitle: 'Delete Promotional Prices',
                deleteDialogContent: 'Do you really want to delete the Promotional Prices?',
                tableOptions: {
                  title: 'Promotional Prices',
                  textLabels: {
                    body: {
                      noMatch: 'No promotional prices found',
                      toolTip: 'Sort',
                    },
                    pagination: {
                      next: 'Next page',
                      previous: 'Previous page',
                      rowsPerPage: 'Promotional Prices per page:',
                      displayRows: 'of',
                      jumpToPage: 'Go to page:',
                    },
                    toolbar: {
                      search: 'Search',
                      downloadCsv: 'Download CSV',
                      print: 'Print',
                      viewColumns: 'Show Columns',
                      filterTable: 'Filter Table',
                    },
                    filter: {
                      all: 'All',
                      title: 'FILTERS',
                      reset: 'RESET',
                    },
                    viewColumns: {
                      title: 'Show Columns',
                      titleAria: 'Show/Hide Columns',
                    },
                    selectedRows: {
                      text: 'promotional price(s) selected',
                      delete: 'Delete',
                      deleteAria: 'Delete selected promotional prices',
                    },
                  },
                },
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No registrations found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Registrations per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'registration(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected registrations',
                  },
                },
              },
            },
            activityList: {
              allDay: 'All Day',
              toolbar: {
                title: 'All Activities',
              },
              helper: {
                scroll: {
                  title: 'Schedules Scroll',
                  content:
                    'Is possible to see all schedules available for this edition on this table. It can scroll horizontally to show all not showing rooms and their scheduled activities.',
                },
                tracks: {
                  title: "Track Label's",
                  content:
                    'Here they contain the legend of the colors used in the activities presented in the grid. Each color in the activity represents the track that activity belongs to.',
                },
              },
              tooltip: {
                add: 'Register',
              },
              tracks: 'Tracks:',
              columns: {
                name: 'Name',
                track: 'Track',
                speaker: 'Speaker',
                price: 'Price',
                vacancies: 'Vacancies',
                remainingVacancies: 'Remaining Vacancies',
                type: 'Activity Type',
                presentationType: 'Presentation Type',
              },
              tabs: {
                schedule: 'Schedule',
                activities: 'All Activities',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No activities found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Activities per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'activity(ies) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected activities',
                  },
                },
              },
            },
            enrollmentTypeList: {
              toolbar: {
                title: 'All Enrollment Types',
              },
              tooltip: {
                add: 'Add Enrollment Type',
              },
              tracks: "Track's:",
              columns: {
                name: 'Name',
                initialTimestamp: 'Initial Date/Time',
                finalTimestamp: 'End Date/Time',
                enable: 'Enabled?',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No enrollment types found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Types per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'type(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected types',
                  },
                },
              },
            },
            satisfactionSurveyList: {
              toolbar: {
                title: 'All Satisfaction Surveys',
              },
              tooltip: {
                add: 'Add survey',
              },
              columns: {
                name: 'Name',
                url: 'URL',
                status: 'Status',
                enable: 'Enabled?',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No satisfaction surveys found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Surveys per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'survey(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected surveys',
                  },
                },
              },
            },
            internationalizationList: {
              toolbar: {
                title: 'All Languages',
              },
              tooltip: {
                add: 'Add language',
                upload: 'Upload JSON',
              },
              chooseJSON: {
                dropzoneText: 'Drag and drop a file here or click',
              },
              columns: {
                cod: 'Cod',
                flag: 'Flag',
                download: 'Download JSON',
                upload: 'Upload JSON',
                enable: 'Enabled?',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No languages found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Languages per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'language(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected languages',
                  },
                },
              },
            },
            caravanRegistrationList: {
              toolbar: {
                title: 'All Caravans',
              },
              tooltip: {
                add: 'Add caravan',
              },
              columns: {
                name: 'Name',
                coordinator: 'Coordinator',
                institution: 'Institution',
                country: 'Country',
                state: 'State',
                city: 'City',
                price: 'Price',
                vacancies: 'Vacancies',
                remainingVacancies: 'Remaining Vacancies',
                confirmed: 'Confirmation Status',
                accepted: 'Acceptation Status',
                textButtonAction: 'To participate',
              },
              registrationDialogTitle: 'Caravan Enrollment',
              confirmRegistrationDialog: 'Confirm',
              registrationDialogContent: 'Do you confirm you want to enroll to this caravan?',
              registrationCancelDialogTitle: 'Remove Caravan Enrollment',
              confirmRegistrationCancelDialog: 'Cancel',
              registrationCancelDialogContent: 'Do you confirm you want to cancel your enrollment in this caravan?',
              confirmRegistrationCancelDialogConfirm: 'Remove',
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No caravans found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Caravans per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'caravan(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected caravans',
                  },
                },
              },
            },
            trackList: {
              toolbar: {
                title: 'All Tracks',
              },
              tooltip: {
                add: 'Register',
              },
              columns: {
                name: 'Name',
                initialDate: 'Initial Date',
                finalDate: 'Final Date',
                calendarColor: 'Color',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No tracks found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Tracks per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'track(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected tracks',
                  },
                },
              },
            },
            caravanList: {
              toolbar: {
                title: 'All Caravans',
              },
              tooltip: {
                add: 'Register',
              },
              columns: {
                name: 'Name',
                coordinator: 'Coordinator',
                institution: 'Institution',
                country: 'Country',
                state: 'State',
                city: 'City',
                price: 'Price',
                vacancies: 'Vacancies',
                remainingVacancies: 'Remaining Vacancies',
                payed: 'Payment Status',
                type: 'Type',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No caravans found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Caravans per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'caravan(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected caravans',
                  },
                },
              },
            },
            themeList: {
              toolbar: {
                title: 'Customization',
              },
              tooltip: {
                add: 'Add theme',
              },
              choseTheme: {
                light: 'Light Theme',
                dark: 'Dark Theme',
                saveChanges: 'Save',
                themeChoice: 'Default Theme',
                allThemes: 'All Themes',
              },
              choseLogo: {
                logo: 'Logo',
                saveLogo: 'Save',
                deleteLogo: 'Remove Logo',
              },
              app: {
                application: 'Application',
                appName: 'Application Name',
                fontSize: 'Font Size',
                fontFamily: 'Font Type',
                saveChanges: 'Save Changes',
              },
              columns: {
                name: 'Name',
                type: 'Type',
              },
              delete: 'Delete',
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No themes found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Themes per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'theme(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected themes',
                  },
                },
              },
            },
            institutionList: {
              toolbar: {
                title: 'All Institutions',
              },
              tooltip: {
                add: 'Register',
              },
              columns: {
                name: 'Name',
                country: 'Country',
                state: 'State',
                city: 'City',
                phone: 'Phone',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No institutions found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Institutions per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'institution(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected institutions',
                  },
                },
              },
              delete: 'Delete',
            },
            editionList: {
              toolbar: {
                title: 'All Editions',
              },
              tooltip: {
                add: 'Register',
              },
              columns: {
                name: 'Name',
                year: 'Year',
                enabled: 'Enabled?',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No editions found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Editions per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'edition(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected editions',
                  },
                },
              },
              delete: 'Delete',
            },
            manageCaravanList: {
              toolbar: {
                title: 'All Caravans',
              },
              tooltip: {
                add: 'Add Caravan',
              },
              columns: {
                name: 'Name',
                type: 'Type',
                institution: 'Institution',
                coordinator: 'Coordinator',
                vacancies: 'Vacancies',
                remainingVacancies: 'Remaining Vacancies',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No Caravans found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Caravans per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'user(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected caravans',
                  },
                },
              },
              delete: 'Delete',
            },
            feedbackList: {
              toolbar: {
                title: 'All Feedbacks',
              },
              tooltip: {
                download: 'Download Images',
              },
              columns: {
                title: 'Title',
                user: 'User',
                download: 'Images',
                status: 'Status',
                apiVersion: 'API Version',
                webVersion: 'WEB Version',
              },
              id: 'ID',
              insertDialogTitle: 'Update Feedback',
              cancelInsertDialog: 'Save',
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No feedbacks found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Feedbacks per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'feedback(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected feedbacks',
                  },
                },
              },
            },
            myCertificatesList: {
              toolbar: {
                title: 'All my certificates',
              },
              tooltip: {
                download: 'Download Certificate',
                downloads: 'Download Certificate(s)',
                preview: 'Preview Certificate',
              },
              columns: {
                activityName: 'Activity',
                trackName: 'Track',
                download: 'Certificate',
                preview: 'Certificate Preview',
                certificateType: 'Certificate type',
                availability: 'Availability Date',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No certificate found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Certificates per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'certificate(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected certificates',
                  },
                },
              },
            },
            voucherList: {
              updateDialogTitle: 'Update Voucher',
              saveDialog: 'Save Voucher',
              insertDialogTitle: 'Add Voucher',
              toolbar: {
                title: 'All Vouchers',
              },
              tooltip: {
                add: 'Register',
              },
              columns: {
                id: 'Id',
                userEmail: 'User Email',
                editionName: 'Edition Name',
                editionYear: 'Edition Year',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No voucher found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'voucher per page:',
                    displayRows: 'from',
                    jumpToPage: 'View page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'View Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'CLEAR',
                  },
                  viewColumns: {
                    title: 'View Columns',
                    titleAria: 'View/Hide Columns',
                  },
                  selectedRows: {
                    text: 'selected voucher(s)',
                    delete: 'Delete',
                    deleteAria: 'Delete Selected Vouchers',
                  },
                },
              },
            },
            tutoredUsersCertificateList: {
              toolbar: {
                title: 'All tutored users certificates',
              },
              tooltip: {
                download: 'Download Certificate',
              },
              columns: {
                activityName: 'Activity',
                trackName: 'Track',
                download: 'Certificate',
                preview: 'Certificate Preview',
                userName: 'User name',
                availability: 'Availability Date',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No certificate found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Certificates per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'certificate(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected certificates',
                  },
                },
              },
            },
            tutoredUserList: {
              columns: {
                name: 'Name',
                idNumber: 'RG',
                country: 'Country',
                exclusionDate: 'Maximum Exclusion Date',
              },
              tableOptions: {
                title: 'Guarded Users',
                textLabels: {
                  body: {
                    noMatch: 'No tutored user found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'User Guardian per page:',
                    displayRows: 'from',
                    jumpToPage: 'View page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download JSON',
                    print: 'Print',
                    viewColumns: 'View Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'CLEAR',
                  },
                  viewColumns: {
                    title: 'View Columns',
                    titleAria: 'View/Hide Columns',
                  },
                  selectedRows: {
                    text: 'selected guardian(s) user(s)',
                    delete: 'Delete',
                    deleteAria: 'Delete Selected Guardian Users',
                  },
                },
              },
            },
            userList: {
              toolbar: {
                title: 'All Users',
              },
              tooltip: {
                add: 'Register',
              },
              columns: {
                name: 'Name',
                email: 'Email',
                tagName: 'Tag name',
                cellPhone: 'Contact',
                institution: 'Institution',
                country: 'Country',
                state: 'State',
                city: 'City',
                enable: 'Enabled?',
                exclusionDate: 'Exclusion Date',
                inactivity: 'Inactivity',
                confirmed: 'Mail Confirmed',
                editions: 'Participant in Events',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No users found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Users per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'user(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected users',
                  },
                },
              },
              delete: 'Delete',
            },
            exclusionList: {
              toolbar: {
                title: 'All Delete Requests',
              },
              columns: {
                registryDate: 'Request Date',
                name: 'User Name',
                note: 'Note',
                email: 'E-mail',
                applicantContact: 'Contact',
                requestType: 'Request Type',
                exclusionStatus: 'Request State',
                actions: 'Actions',
              },
              tooltip: {
                edit: 'Edit Request',
                complete: 'Anonymize user data',
                notApproved: 'Unable to anonymize the data as the status of the request is not "Approved".',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No request found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Requests per page:',
                    displayRows: 'from',
                    jumpToPage: 'View page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'View Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'CLEAR',
                  },
                  viewColumns: {
                    title: 'View Columns',
                    titleAria: 'View/Hide Columns',
                  },
                  selectedRows: {
                    text: 'selected request(s)',
                    delete: 'Delete',
                    deleteAria: 'Delete Selected Requests',
                  },
                },
              },
              delete: 'Delete',
            },
            emailList: {
              toolbar: {
                title: 'Emails',
              },
              columns: {
                subject: 'Subject',
                class: 'Class',
                status: 'Status',
                timestamp: 'Date/Time',
                enable: 'Enabled?',
              },
              tooltip: {
                add: 'Add Email',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No emails found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Emails per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'email(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected emails',
                  },
                },
              },
            },
            exclusion: {
              columns: {
                requestDate: 'Request Date',
                note: 'Note',
                applicantContact: 'Applicant Phone',
              },
            },
            certificateList: {
              toolbar: {
                title: 'Certificates',
              },
              columns: {
                name: 'Name',
                availability: 'Availability',
                enable: 'Enabled?',
              },
              tooltip: {
                add: 'Register',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No certificates found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Certificates per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'certificate(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected certificates',
                  },
                },
              },
            },
            templateList: {
              toolbar: {
                title: 'Templates',
              },
              columns: {
                name: 'Name',
                type: 'Type',
              },
              tooltip: {
                add: 'Add Template',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No templates found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Templates per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'template(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected templates',
                  },
                },
              },
            },
            tagList: {
              toolbar: {
                title: 'Tags',
              },
              tooltip: {
                add: 'Add Tag',
                print: 'Print Tag',
              },
              filter: 'Filter',
              intervalStart: 'Registration Interval Start',
              intervalEnd: 'Registration Interval End',
              letterIntervalStart: 'Letter Interval Start',
              letterIntervalEnd: 'Letter Interval End',
              enrollmentType: 'Enrollment Type',
              generated: 'Include Already Generated Users?',
              caravans: 'Include Users from Caravans?',
              pay: 'Only Paid/Exemption Users?',
              confirmation: 'Print Confirmation Tag?',
              selectedCaravans: 'Selected Caravans',
              coordinatorConfirmed: 'Only Users Confirmed by the Coordinator?',
              selectedSpeakers: 'Selected Speakers',
              dialog: {
                title: 'Print Tag',
                save: 'Print',
              },
              columns: {
                name: 'Name',
                print: 'Print',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No tags found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Tags per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'tag(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected tags',
                  },
                },
              },
            },
            audit: {
              toolbar: {
                title: 'Audit',
              },
              columns: {
                timestamp: 'Date/Time',
                action: 'Event',
                description: 'Description',
                agent: 'Agent',
                result: 'Result',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No events found',
                    toolTip: 'Sort',
                  },
                  pagination: {
                    next: 'Next page',
                    previous: 'Previous page',
                    rowsPerPage: 'Events per page:',
                    displayRows: 'of',
                    jumpToPage: 'Go to page:',
                  },
                  toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'Show Columns',
                    filterTable: 'Filter Table',
                  },
                  filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                  },
                  viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Columns',
                  },
                  selectedRows: {
                    text: 'event(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete selected events',
                  },
                },
              },
            },
          },
          autoComplete: {
            noOptions: 'No options',
            addNew: 'Add New',
          },
          layouts: {
            actionMenu: {
              logOut: 'logout',
              myAccount: 'My Account',
              reviewDate: 'Deletion under Review',
              exclusionDeadlineDate: 'Exclusion Deadline',
            },
            sidebar: {
              dpo: 'DPO',
              allActivities: 'Global Schedule',
              feedbacks: 'Manage Feedbacks',
              feedback: 'Feedback',
              myFeedbacks: 'My Feedbacks',
              exclusions: 'Exclusion Requests',
              tracks: 'Tracks',
              tutoredUsers: 'Tutored Users',
              event: 'Event',
              activities: 'Activities',
              manageCaravans: 'Coordinate Caravans',
              myCaravans: 'Participate in Caravans',
              editions: 'Editions',
              dashboard: 'Home',
              myAccount: 'My Account',
              myCertificates: 'My Certificates',
              myRegistration: 'My Registration',
              postPurchase: 'Buy Activities',
              records: 'Records',
              users: 'Users',
              institutions: 'Institutions',
              accessProfiles: 'Access Profiles',
              satisfactionSurveys: 'Satisfaction Surveys',
              audit: 'Audit',
              caravans: 'Caravans',
              customization: 'Customization',
              themes: 'Themes',
              home: 'Home',
              internationalization: 'Internationalization',
              communication: 'Communication',
              templates: 'Templates',
              tags: 'Tags',
              userRegistration: 'Register User',
              emails: 'Emails',
              enrollments: 'Registrations',
              registrations: 'Manage Registrations',
              certificates: 'Certificates',
              speakerActivities: 'Speaker Schedule',
              userActivities: 'My Schedule',
              secretary: 'Secretary',
              terms: 'Terms of SWGE',
              privacyPolicy: 'Privacy Policy',
              cancellationRefundPolicy: 'Cancellation and Refund Policy',
              useTerm: 'Terms of Use',
              imageTerm: 'Authorization for Use of Image and Data',
              coordinatorTerm: "Caravan Coordinator's Termo of Responsibility",
              authorizationTerm: 'Declaration of Authorization Participation in a Child or Teenager Event',
              apiUseTerm: 'System API Terms of Use',
              coordinatorAcceptance: 'Acceptance of the Responsibility Agreement',
              listParticipation: 'My Participations',
              userCertificates: 'Certificates By Users',
            },
          },
          changeLanguage: 'Change Language',
          dialog: {
            deleteDialogTitle: 'Delete',
            userDeleteDialogTitle: 'Request Deletion',
            userDeleteDialogContent: 'Do you want to request the deletion of this record?',
            deleteDialogContent: 'Do you really want to delete?',
            warning: 'This action cannot be undone.',
            userDeleteWarning: '*Note: This action is irreversible, to cancel the request contact the system DPO. The link to the DPO is: ',
            delete: 'Delete',
            userDelete: 'Request Deletion',
            cancelDeleteDialog: 'Cancel',
          },
          cancelDialog: {
            cancelDialogTitle: 'Cancel Exclusion',
            cancelDialogContent: 'Do you really want to Cancel the Exclusion?',
            accept: 'Yes',
            refuse: 'No',
          },
          tutoredUserCard: {
            info: 'Information',
            specialNeedsText: 'Special Needs',
          },
          manageExclusion: {
            dialogTitle: 'Manage Deletion Request',
            send: 'Update',
            cancel: 'Cancel',
            previewPDF: 'Preview Attached File',
          },
          userExclusion: {
            dialogTitle: 'Complete Deletion Request',
            send: 'Complete',
            cancel: 'Cancel',
          },
          toastes: {
            reviewed: 'Term of Participation reviewed successfully',
            reviewedError: 'An error occurred while reviewing the Participation Agreement',
            coordinatorAccepted: 'Caravan Coordinator Responsibility Term accepted',
            coordinatorError: 'There was something wrong with the acceptance',
            voucherError: 'There is already a voucher in this edition assigned to this user',
            registrationSave: 'Registration successful',
            registrationSaveError: 'Error registering',
            cardError: 'There is some wrong information on the card',
            publicKeyError: 'There is an internal error! Come back later',
            paymentCancel: 'Payment cancelled, information removed',
            chargeSuccess: 'Charge successful',
            chargeError: 'There was an error charging, please try again',
            chargeCancel: 'Payment cancelled, amount refunded!',
            userProfileSave: 'Click on save to register the new picture',
            userRemovedImage: 'Image successfully removed',
            noBackgroundImage: 'Background image not selected',
            noRegistrationType: 'Registration Type not configured for this edition',
            noRegistrationTypeInterval: 'Not within the registration interval',
            chagePassword: 'Password changed successfully',
            chagePasswordError: 'Error registering new password, try again later',
            resetPassword: 'Password Changed. Verify your email',
            resetPasswordError: 'If the email provided is associated with an account in our system, we will send a link to reset your password',
            caravanEnrollmentAlreadyError: 'User already enrolled and accepted in a Caravan',
            editionEnabledError: 'It was not possible to enable/disable the edition',
            userEnabledError: 'It was not possible to enable/disable the user',
            noCurrentEditionError: 'Select an edition first',
            caravanEnrollment: 'Caravan enrollment done',
            caravanEnrollmentError: 'It was not possible to enroll to the caravan',
            caravanCancelEnrollment: 'Cancel caravan enrollment done',
            caravanCancelEnrollmentError: 'It was not possible to cancel the enrollment',
            exclusionRequest: 'Successfully requested exclusion',
            exclusionUpdateSave: 'Successfully changed the exclusion request',
            exclusionUpdateSaveError: 'Something went wrong on exclusion request update',
            exclusionUpdateFileError: 'An attachment is required to complete the request',
            exclusionRequestError: 'Error requesting the exclusion',
            excludeNoFile: 'Error, an attachment is required if deny a request',
            accountUntie: 'Social Account Untied',
            accountUntieError: 'It was not possible to untie the social account',
            save: 'Registration Performed',
            saveError: 'Error saving',
            maxUserRole: 'Maximum Users Reached with Permission: ',
            activityPlaceTimeConflict: 'Time-Place Conflict with Activity',
            saveNoScheduleError: 'Error saving - There is no schedule for this activity',
            fecth: 'Search successful',
            fetchError: 'Unable to perform search',
            update: 'successfully updated',
            updateError: 'Error while updating',
            delete: 'successfully removed',
            discarded: 'Purchase discarded successfully',
            deleteError: 'error removing',
            discardedError: 'Error discarding purchase',
            deletes: 'successfully removed',
            deleteErrors: 'error removing',
            selectWarning: 'The selected activity has schedule conflicts with another activity',
            deleteThemeErrorDefault: 'It is not possible to delete a default theme! Change it first.',
            uploadFile: {
              add: 'File selected',
              upload: 'File added',
              delete: 'File removed',
              error: 'Sorry, an error has occurred. Please try again',
              noFileError: 'Select a JSON file first',
              keysError: 'All entries of the JSON file must follow the Color Palette',
              stringError: 'There are some entries in the JSON file that are not Strings',
              syntaxError: 'The file is not a valid JSON file',
              fileSizeError: 'The file size is larger than 5MB',
            },
            colorPaletteValueError: 'Fill all the color fields before saving',
            errorGetData: 'It was not possible to fetch data',
            cepFetchError: 'It was not possible to fetch CEP data automatically',
            emailSent: 'The email confirmation has been sent successfully',
            emailSentError: 'It was not possible to send sent the email confirmation',
            colorPaletteValueFormat: 'All the color fields must be valid',
            colorFieldFormat: 'Color field must be valid',
            certificateValidated: 'Certificate Validation was a success',
            invalidCertificate: 'Certificate is Invalid!',
            participationRegistered: 'Participation Registered',
            errorOnParticipationRegister: 'Error in registering the Participation',
          },
          steps: {
            appBar: {
              user: {
                title: 'User Menu Button',
                content:
                  'This button allows the user to perform the action of accessing their account data and logging out of the account.\n Demonstration: ',
              },
              theme: {
                title: 'Theme Button',
                content:
                  'This button allow to change the webpage current theme on click. The default theme is Light Mode (Sun icon) and can be changed to Dark Mode (Moon icon).\n Demonstration: ',
              },
              edition: {
                title: 'Edition Change Button',
                content:
                  'This button displays all the editions at the moment, and through it you can select the edition you want to access.\n Demonstration: ',
              },
              language: {
                title: 'Language Change Button',
                content: 'This button allows you to switch between the different languages available on the platform.\n Demonstration: ',
              },
              sideBar: {
                title: 'Show or Hide Sidebar Button',
                content:
                  'This button allows you to open or hide the sidebar that has all the functionality of the system.\n Demonstration: ',
              },
              tooltip: {
                edition: 'Change Edition',
                help: 'Help',
              },
            },
          },
          enums: {
            languages: {
              'pt-BR': 'Portuguese',
              'en-US': 'English',
              'es-ES': 'Spanish',
            },
            feedbackStatus: {
              OPEN: 'Open',
              ON_GOING: 'Ongoing',
              CLOSED: 'Closed',
            },
            enabled: {
              true: 'Enabled',
              false: 'Not Enabled',
            },
            confirmedBool: {
              true: 'Confirmed',
              false: 'Not Confirmed',
            },
            empty: {
              EMPTY: 'Empty',
            },
            activityTypes: {
              LECTURE: 'Lecture',
              MINI_COURSE: 'Mini-Course',
              WORKSHOP: 'Workshop',
              KEYNOTE: 'Keynote',
              PAPER_PRESENTATION: 'Paper Presentation',
              POSTER_PRESENTATION: 'Poster Presentation',
              OTHER: 'Other',
            },
            fonts: {
              Courier: 'Courier',
              Helvetica: 'Helvetica',
              TimesRoman: 'Times-Roman',
              Roboto: 'Roboto',
              'Fantasque Sans': 'Fantasque Sans',
              Ubuntu: 'Ubuntu',
              'Apple Storm': 'Apple Storm',
              Lato: 'Lato',
              Poppins: 'Poppins',
              'Playfair Display SC': 'Playfair Display SC',
            },
            exclusionStatus: {
              APPROVED: 'Approved',
              DENIED: 'Denied',
              CANCELED: 'Canceled',
              NOT_ANALYZED: 'Not Analyzed',

              Approved: 'APPROVED',
              Denied: 'DENIED',
              Canceled: 'CANCELED',
              'Not Analyzed': 'NOT_ANALYZED',
            },
            requestType: {
              INACTIVITY: 'INACTIVITY',
              USER: 'USER',
              ANONYMOUS: 'ANONYMOUS',
              TUTORED: 'TUTORED',
            },
            payed: {
              PAYED: 'Payed',
              NOT_PAYED: 'Not Payed',
              EXEMPTION: 'Exemption',
            },
            hasAuthorization: {
              YES: 'Yes',
              NO: 'No',
            },
            confirmed: {
              CONFIRMED: 'Confirmed',
              NOT_CONFIRMED: 'Not Confirmed',
            },
            accepted: {
              ACCEPTED: 'Accepted',
              NOT_ACCEPTED: 'Not Accepted',
            },
            userTypes: {
              ATTENDEE: 'Attendee',
              SPEAKER: 'Speaker',
              COORDINATOR: 'Coordinator',
              ADMINISTRATOR: 'Administrator',
            },
            editionTypes: {
              LIVE: 'In Person',
              ONLINE: 'Virtual',
              HYBRID: 'Hybrid',
            },
            genders: {
              MALE: 'Male',
              FEMALE: 'Female',
              UNDEFINED: 'Other',
            },
            specialNeeds: {
              VISUAL: 'Visual',
              HEARING: 'Hearing',
              LOCOMOTOR: 'Locomotor',
              SPEECH: 'Speech and Language',
              MENTAL: 'Mental behavior',
              INTELLECTUAL: 'Intellectual',
              OTHERS: 'Others',
            },
            caravanTypes: {
              GENERAL: 'General',
              TUTORED: 'Tutored',
            },
            activityType: {
              LIVE: 'In Person',
              ONLINE: 'Virtual',
              HYBRID: 'Hybrid',
            },
            userRoleType: {
              ATTENDEE: 'Attendee',
              SPEAKER: 'Speaker',
              CARAVAN_COORDINATOR: 'Caravan Coordinator',
              SECRETARY: 'Secretary',
              DPO: 'Data Protection Officer',
              GRID_COORDINATOR: 'Grid Coordinator',
            },
            accessPermissions: {
              USER: 'USER',
              ADM: 'ADMINISTRATOR',
            },
            passwordStrength: {
              tooWeak: 'too weak',
              weak: 'weak',
              okay: 'okay',
              good: 'good',
              strong: 'strong',
            },
            themeTypes: {
              LIGHT: 'Light',
              DARK: 'Dark',
              tooltip: {
                light: 'Change it to Light Mode',
                dark: 'Change it to Dark Mode',
              },
            },
            colorPalette: {
              primary: 'Primary color of the system. Used in most components',
              secondary: 'Secondary color of the system. Used in most components',
              error: 'Error color',
              warning: 'Warning color',
              info: 'Attention color',
              success: 'Success color',
              toolbarBackgroundColor: 'Background color of the localization bar',
              titleCardBackgroundColor: 'Background color of the title bar of the cards',
              appBarBackgroundColor: 'Background color of the upper bar',
              sidebarBackgroundColor: 'Background color of the side menu',
            },
            tour: {
              next: 'Next',
              close: 'Close',
              open: 'Begin Tour',
              skip: 'Skip',
              back: 'Back',
              last: 'Last',
            },
          },
        },
      },
      'es-ES': {
        translation: {
          general: {
            applicationName: 'SWGE',
            applicationNameFull: 'Sistema web del administrador de eventos',

          },
          yup: {
            pastFinalDateError: 'La fecha final debe ser mayor que la fecha inicial',
            integerError: 'El campo debe ser un entero',
            positiveError: 'El campo debe ser positivo',
            minError: 'El campo debe ser al menos {{count}} caracteres Long',
            maxCharactersAllowed: 'El campo debe ser como máximo 100 caracteres',
            requiredField: 'Este es un campo obligatorio',
            emailFormat: 'El correo electrónico debe ser un correo electrónico válido',
            urlFormat: 'La URL debe ser válida',
            passwordEqual: 'Las contraseñas deben coincidir',
            passwordMin: 'La contraseña debe tener al menos 8 caracteres',
            passwordStrength: 'La fuerza de la contraseña debe estar al menos bien',
            maskError: 'El campo más se complete',
            uniqueField: 'Ya en uso',
            dateIntervalError: 'Fuera del intervalo',
            resourceTimeConflict: 'Conflicto de tiempo de recurso',
            wrongExpYear: 'Año fuera de validez',
            validMonth: 'Mes equivocado',
            wrongCardNumber: 'Número de tarjeta fuera del estándar (13 a 19 dígitos)',
            wrongSecurityCode: 'Número de CVV no válido',
            onlyAlphabeticalCharactersAllowed: 'Solo los caracteres alfabéticos permitidos',
            onlyNumbersAllowed: 'Solo números permitidos',
            noSpecialCharactersAllowed: 'Caracteres especiales no están permitidos',
            invalidDate: 'Fecha invalida',
            invalidDateTime: 'Fecha o hora no válida',
          },
          table: {
            applyFilters: 'Aplicar filtros',
          },
          datePicker: {
            prev: 'Mes anterior',
            next: 'Próximo mes',
            placeHolderInputDate: 'dd/mm/aaaa',
            placeHolderInputDateTime: 'dd/mm/aaaa hh:mm (a|p)m',
          },
          calendar: {
            allDay: 'Todo el dia',
            buttonText: {
              today: 'Hoy',
              month: 'Mes',
              week: 'Semana',
              day: 'Día',
              period: '{{ days }} Días',
              moreLinkText: 'más',
            },
            buttonHints: {
              prev: '$0 Anterior',
              next: 'Próximo $0',
              today: 'Esta $0',
              month: 'Visualización del Mes',
              week: 'Semana Vista',
              day: 'Visualización del Día',
              moreLinkHint: 'Espectáculo $0 más eventos',
            },
          },
          dropzoneArea: {
            dropzoneText: 'Arrastrar y gota un imagen aquí o hacer clic',
            preview: 'Preview:',
          },
          inactivity: {
            minutes: '$0 Minutos',
            hours: '$0 Horas',
            days: '$0 Días',
            weeks: '$0 Semanas',
            month: '1 Mes',
            months: '$0 Meses',
          },
          form: {
            save: 'Ahorrar',
            delete: 'Borrar',
            cancel: 'Cancelar',
            INSTITUTION: 'Nueva institución',
            EDIT_USER: 'EUsuario de DIT',
            EDIT_TRACK: 'New Track',
            EDIT_CERTIFICATE: 'Nuevo certificado',
            USER_LIST: 'editar usuario',
            cancelDelete: 'Cancelar exclusión',
            finalDelete: 'Eliminar/anonimizar',
            userDelete: 'Solicitar eliminar',
          },
          pages: {
            home : {              
              welcome: 'Bienvenido ',
              description: 'El SWGE (Sistema Web de Gestión de Eventos) es el lugar para administrar usuarios, registro, certificados y más.',
              subscribe1: 'Suscríbase ahora en {{ eventName }} en {{ city }} - {{ state }} ({{ initialDate }} a {{ finalDate }}).',
              subscribe2: 'Ya estás registrado en {{ eventName }} en {{ city }} - {{ state }} ({{ initialDate }} a {{ finalDate }}).',              
              subscribeSubtitle: 'Ser parte de la comunidad más grande de entusiastas de los códigos gratuitos y tecnologías abiertas en América Latina.',
              button1: 'Suscríbase ahora',
              button2: 'Suscríbased',
              button3: 'Caravanas',
              textCaravan: 'Si desea participar en una caravana, haga clic en "Revise las caravanas" y asegúrese de que su institución tenga una caravana.',
              programation: 'Cronograma',
            },
            registration: {
              steps: {
                step1: 'Registro',
                step2: 'Actividades',
                step3: 'Resumen',
                step4: 'Pago',
                step5: 'finalizado',
              },
              step1: {
                title:'Inscripción en {{ eventName }}',
                column1:{ 
                  title:"Evento",
                  text1:"Registros hasta ",
                  text2:"En hasta ",
                },
                column2:{
                  title:"Datos para el registro",
                  subTitle:"Datos obligatorios para el registro en el evento",
                  msgRequeriment: "Obligatorio, para completar el pago",
                  checkLabel: "Vocero?"
                },
                column3:{title:"Programación"},                                  
                cardHint:"Haga clic para ver más detalles", 
              },
              step2: {
                title1:"Registro en ",
                subtitle1:"Lo que se incluye en la inscripción",
                title2:"Registro de actividades adicionales opcionales:",
                subtitle2:"Seleccione las actividades para agregarlas al carrito de compras",
                msgRow1:'Puedes regresar y comprar más eventos en ',
                msgRow2:'En la página "Mi inscripcion"',
                noActivities:"No se agregó actividad adicional",
                card: {
                  showHint: 'Ver más',
                  showLess: 'Ver menos',
                  showMore: "Ver actividades incluidas",                  
                  buttonOn: "Incluir en la compra",
                  buttonOff: "adicionado",
                  details: "Detalles",
                  free: "Gratis",
                }
              },
              step3: {
                title:"Resumen",
                subtitle:"Seleccione el tipo de colección",
                card: {
                  button: "Remover de la Compra",  
                },
                dialog: {
                  title: 'Eliminar la actividad del carrito',                    
                  text: 'Estás seguro de que quieres eliminar'
                }                  
              },
              step4: {
                title:"Pago",  
                loading: "Processando...",      
                textButtonCancelPayment: "Cancelar pedido",
                textButtonConfirmOrder: "Confirmar pedido",  
                textButtonCancelRegistration: "Cancelar el registro",    
                dialog: {
                  title: 'Cancelar el registro en el evento',                    
                  text: '¿Estás seguro de que quieres cancelar el proceso de registro?',
                  buttonCancel: 'Cancelar',
                  buttonConfirm: 'Confirmar',
                }                  
              },
              step5: {
                textRow1:"¡Felicidades!",
                textRow2:"Estás registrado en {{ eventName }}!",
              },
              step6: {
                title1:"Concluyó!",
                title2:"Registro confirmado!",
                textRow1:"¡Su registro se realizará después de la confirmación del pago!",
                textRow2:"Se ha realizado su registro, se enviarán más detalles en el correo electrónico informado.",
              },
            },
            participationMenu: {
              camPermission: 'Solicitar permisos de cámara',
              registeredParticipation: 'Participación confirmada!',
              registeredParticipationText: 'El registro y la confirmación de la participación se llevaron a cabo para la siguiente actividad: ',
              close: 'Cerca',
              nonMobile: 'Funcionalidad solo para dispositivos móviles',
              tabs: {
                registerParticipation: 'Registrar participación',
                participationRegistered: 'Participaciones registradas',
              },
              columns: {
                activityName: 'Nombre de la actividad',
                onRegister: 'Fecha de registro',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No se encontró participación',
                    toolTip: 'Clasificar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Pagina anterior',
                    rowsPerPage: 'Participación por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver pagina:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Mesa de filtro',
                    downloadCsv: 'Descargar JSON',
                    print: 'Imprimir',
                  },
                  filter: {
                    all: 'Toda',
                    title: 'Filtros',
                    reset: 'REINICIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/ocultar columnas',
                  },
                  selectedRows: {
                    text: "Participación elegida",
                    delete: 'Borrar',
                    deleteAria: 'Eliminar la participación seleccionada',
                  },
                },
              },
            },
            coordinatorTerm: {
              accept: 'Aceptar',
            },
            tags: {
              docMargin: 'Margen de documento',
              tagMargin: 'Margen de etiqueta',
              tagSize: 'Tamaño de la etiqueta',
              tagWidthSize: 'Ancho',
              tagHeightSize: 'Altura',
              marginTopBottom: 'Arriba',
              marginLeftRight: 'Izquierda',
              colSize: 'Número de columnas',
              rowSize: 'Número de líneas',
              startIndex: 'Índice de comienzo',
              selectedUsers: 'Número de usuarios seleccionados:',
              tagFilePreview: 'Seleccione filas y columnas del archivo de etiqueta',
              selectedIndexes: 'Índices seleccionados',
              pageSize: 'Estilo y tamaño de página',
              openPreview: 'Visualizar Etiquetas',
              previewTags: 'Archivo con etiquetas',
              columns: {
                userName: 'Nombre',
                tagName: 'Nombre de etiqueta',
                caravanEnrollmentCount: 'Usuarios inscritos en caravana',
                enrollmentSituation: 'Estado de inscripción',
                userCaravan: 'Usuario en caravana?',
                userNotInCaravan: 'No en una caravana',
                situation: {
                  notFound: 'Extraviado',
                  completed: 'Terminada',
                  missAuthorization: 'Sin término de autorización',
                  notPayed: 'No pagado',
                  notAccepted: 'No aceptada',
                },
              },
              tooltip: {
                generateTags: 'Generar etiquetas',
              },
              tabs: {
                registeredUsers: 'Usuarios',
                registeredTutoredUsers: 'Usuarios tutorizados',
                caravans: 'Caravanas',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No se encuentran etiquetas',
                    toolTip: 'Clasificar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Pagina anterior',
                    rowsPerPage: 'Etiquetas por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver pagina:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    viewColumns: 'Ver columnas',
                    filterTable: 'Mesa de Filtro',
                    downloadCsv: 'Descargar JSON',
                    print: 'Imprimir',
                  },
                  filter: {
                    all: 'Toda',
                    title: 'Filtros',
                    reset: 'REINICIAR',
                  },
                  viewColumns: {
                    title: 'Ver columnas',
                    titleAria: 'Ver/ocultar columnas',
                  },
                  selectedRows: {
                    text: "Etiqueta seleccionada",
                    delete: 'Borrar',
                    deleteAria: 'Eliminar etiquetas seleccionadas',
                  },
                },
              },
            },
            noticePanel: {
              tooltip: {
                delete: 'Borrar',
                cancel: 'Cancelar',
                add: 'Agregar',
                edit: 'Editar',
              },
              editor: 'Inserte el texto aquí...',
              delete: 'Delete',
            },
            editionNotFound: {
              home: 'Hogar',
              notFound: 'Edición no encontrada!',
            },
            editionNotEnabled: {
              home: 'Hogar',
              notEnabled: 'Edición no habilitada para acceder!',
            },
            registrationInterval: {
              registrationNotInterval: 'No dentro del intervalo de registro',
            },
            messageWarningUser: {
              warningUser: 'Pago de caravana No confirmado, ¡comuníquese con el Coordinador de Caravana!',
            },
            login: {
              email: 'Email',
              password: 'Contraseña',
              login: 'Acceso',
              backToHomePage: 'Ir al sitio web',
              forgotPassword: 'Olvidé mi contraseña',
              google: 'Google',
              facebook: 'Facebook',
              linkedin: 'LinkedIn',
              gitHub: 'GitHub',
              noRecord: 'Todavía no estas registrado? ',
              register: 'Regístrate ahora',
              socialsLogin: 'O iniciar sesión con',
              loginError: 'Correo electrónico y/o contraseña incorrectos',
              emailConfirmationError: 'Correo electrónico no confirmado!',
              resendEmailConfirmation: 'Haga clic aquí para reenviar el correo electrónico',
              msgOldRegisters: '* Si tiene un registro en ediciones anteriores, haga un nuevo registro para la edición actual.',
              validateUserDisabled: 'Usuario deshabilitado',
              rememberMe: 'Acuérdate de mí',
              terms: {
                privacyPolicy: 'Política de privacidad',
                cancellationRefundPolicy: 'Política de Cancelación y Reembolso',
                useTerm: 'Términos de uso de la aplicación',
                imageTerm: 'Término de Autorización de Uso de Imagen y Datos',
                coordinatorTerm: 'Término de responsabilidad del coordinador de la caravana',
                authorizationTerm: 'Declaración de participación de autorización en evento infantil o adolescente',
                apiUseTerm: 'Términos de uso de la API del sistema',
                useTermAgreement: 'Al registrarse, acepto el',
                privacyPolicyAgreement: ', estoy de acuerdo con',
                and: 'y el',
              },
              oldAcess: "El inicio de sesión utilizado en la última edición no es válido. Por favor, realice un nuevo registro.",
            },
            tutoredUserList: {
              columns: {
                name: 'Nombre',
                idNumber: 'Rg',
                country: 'país',
                exclusionDate: 'Fecha de exclusión máxima',
              },
              tableOptions: {
                title: 'Guardianes',
                textLabels: {
                  body: {
                    noMatch: 'No se encontró ningún usuario protegido',
                    toolTip: 'ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'páginaAnterior',
                    rowsPerPage: 'Usuario protegido por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver Página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar json',
                    print: 'imprimir',
                    viewColumns: 'Ver columnas',
                    filterTable: 'Filtrar Tabela',
                  },
                  filter: {
                    all: 'Todo',
                    title: 'Filtros',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver columnas',
                    titleAria: 'Ver/ocultar columnas',
                  },
                  selectedRows: {
                    text: 'Usuario(s) tutela seleccionada',
                    delete: 'apagar',
                    deleteAria: 'Eliminar guardianes seleccionados',
                  },
                },
              },
            },
            terms: {
              privacyPolicy: 'Política de privacidad',
              cancellationRefundPolicy: 'Política de Cancelación y Reembolso',
              useTerm: 'Condiciones de uso',
              imageTerm: 'Término de Autorización de Uso de Imagen y Datos',
              coordinatorTerm: "Termo de responsabilidad del coordinador de caravana",
              authorizationTerm: 'Declaración de participación de autorización en un evento de niños o adolescentes',
              apiUseTerm: 'Términos de uso de la API del sistema',
            },
            pageNotFound: {
              notFound: 'Página no encontrada',
              home: 'de vuelta a início',
            },
            pageNotAllowed: {
              notAllowed: 'No permitido',
              home: 'de vuelta a casa',
            },
            editEmail: {
              toolbar: {
                title: 'Nuevo Email',
              },
              subject: 'Sujeto',
              class: 'Clase',
              save: 'Guardar correo electrónico',
              fromTemplate: 'Aplicar plantilla?',
              templates: 'Plantillas',
              date: 'Fecha',
              time: 'Tiempo',
              enable: 'Activada?',
              userMode: 'Filtrar',
              selectedUsers: 'Usuarios seleccionados',
              selectedUserTypes: 'Tipos de usuario seleccionados',
              subscriberMode: 'Filtrar',
              selectedSubscribers: 'Suscriptores seleccionados',
              selectedSubscriberTypes: 'Tipos de suscriptores seleccionados',
              selectedCaravans: 'Caravanas seleccionadas',
              selectedRecipients: 'Destinatarios seleccionados',
            },
            editEnrollmentType: {
              toolbar: {
                title: 'Nuevo tipo de inscripción',
              },
              tooltip: {
                add: 'Agregar precio promocional',
              },
              name: 'Nombre',
              description: 'Descripción',
              save: 'Guardar el tipo de inscripción',
              initialDate: 'Fecha inicial',
              initialTime: 'Tiempo inicial',
              finalDate: 'Fecha final',
              finalTime: 'Hora de finalización',
              enable: '¿Activada?',
              paymentAccounts: 'Cuentas de pago',
              exemption: 'Usuario exento',
              vacancies: 'Vacantes',
              certificate: 'Certificado',
              price: 'Precio',
              coverage: 'Cobertura de inscripción',
              columns: {
                promotionalPrice: 'Precio promocional',
                initialDate: 'Fecha inicial',
                initialTime: 'Tiempo inicial',
                finalDate: 'Fecha final',
                finalTime: 'Hora de finalización',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No se encontraron precios promocionales',
                    toolTip: 'Clasificar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Pagina anterior',
                    rowsPerPage: 'Precios por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ir a la página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar json',
                    print: 'Imprimir',
                    viewColumns: 'Mostrar columnas',
                    filterTable: 'Mesa de Filtro',
                  },
                  filter: {
                    all: 'Toda',
                    title: 'Filtros',
                    reset: 'REINICIAR',
                  },
                  viewColumns: {
                    title: 'Mostrar columnas',
                    titleAria: 'Mostrar/ocultar columnas',
                  },
                  selectedRows: {
                    text: 'Precio (s) seleccionado',
                    delete: 'Borrar',
                    deleteAria: 'Eliminar precios seleccionados',
                  },
                },
              },
            },
            editTemplate: {
              toolbar: {
                title: 'Nueva plantilla',
              },
              tooltip: {
                add: 'Agregar contenido',
              },
              positions: 'Contenido',
              name: 'Nombre',
              type: 'Tipo',
              save: 'Guardar plantilla',
              preview: 'Certificado de vista previa?',
              previewTag: 'Etiqueta de vista previa?',
              previewEmail: '¿Vista previa por correo electrónico?',
              dimensionX: 'Ancho del certificado',
              dimensionY: 'Altura del certificado',
              dimensionXTag: 'Ancho de la etiqueta',
              dimensionYTag: 'Altura de la etiqueta',
              columns: {
                xPosition: 'X',
                yPosition: 'Y',
                content: 'Contenido',
                fontSize: 'Tamaño de fuente',
                bold: 'Negrita',
                italic: 'Itálica',
                underlined: 'Subrayada',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No se encuentra contenido',
                    toolTip: 'Clasificar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Pagina anterior',
                    rowsPerPage: 'Puestos por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ir a la página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar json',
                    print: 'Imprimir',
                    viewColumns: 'Mostrar columnas',
                    filterTable: 'Mesa de Filtro',
                  },
                  filter: {
                    all: 'Toda',
                    title: 'Filtros',
                    reset: 'REINICIAR',
                  },
                  viewColumns: {
                    title: 'Mostrar columnas',
                    titleAria: 'Mostrar/ocultar columnas',
                  },
                  selectedRows: {
                    text: 'posición (s) seleccionada',
                    delete: 'Borrar',
                    deleteAria: 'Eliminar posiciones seleccionadas',
                  },
                },
              },
            },
            editSatisfactionSurvey: {
              toolbar: {
                title: 'Nueva encuesta de satisfacción',
              },
              templates: 'Plantillas',
              fromTemplate: 'Aplicar plantilla?',
              subject: 'Sujeto',
              name: 'Nombre',
              save: 'Crear encuesta',
              date: 'Fecha',
              time: 'Tiempo',
              url: 'Url',
              condition: 'Condición',
              event: 'Evento',
              selectedTarget: 'Personas seleccionadas',
              target: 'Público objetivo',
              activation: 'Desencadenar',
              enable: 'Activada?',
              back: 'Atrás',
              next: 'Próxima',
              step1: 'Información básica',
              step2: 'Activación',
              step3: 'Personalización por correo electrónico',
            },
            editAccessProfile: {
              toolbar: {
                title: 'Nuevo perfil de acceso',
              },
              name: 'Nombre',
              save: 'Crear perfil',
              description: 'Descripción',
              permissions: 'Permisos',
            },
            editInternationalization: {
              toolbar: {
                title: 'Nuevo idioma',
              },
              chooseJSON: {
                dropzoneText: 'Arrastre y suelte un archivo aquí o haga clic',
              },
              tooltip: {
                upload: 'Subir JSON',
              },
              cod: 'Codigo',
              enable: 'Activada?',
              save: 'Ahorrar',
              columns: {
                cod: 'Codigo',
                description: 'Descripción',
                editor: 'Texto',
                reference: 'Referencia',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No se encontraron campos',
                    toolTip: 'Clasificar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Pagina anterior',
                    rowsPerPage: 'Campos por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ir a la página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar json',
                    print: 'Imprimir',
                    viewColumns: 'Mostrar columnas',
                    filterTable: 'Mesa de Filtro',
                  },
                  filter: {
                    all: 'Toda',
                    title: 'Filtros',
                    reset: 'REINICIAR',
                  },
                  viewColumns: {
                    title: 'Mostrar columnas',
                    titleAria: 'Mostrar/ocultar columnas',
                  },
                  selectedRows: {
                    text: 'campos (s) seleccionados',
                    delete: 'Borrar',
                    deleteAria: 'Eliminar campos seleccionados',
                  },
                },
              },
            },
            editTheme: {
              toolbar: {
                editTheme: 'Tema de actualización',
                newTheme: 'Nuevo tema',
              },
              chooseJSON: {
                dropzoneText: 'Arrastre y suelte un archivo aquí o haga clic',
              },
              tooltip: {
                upload: 'Subir JSON',
                download: 'Descargar json',
              },
              id: 'ID',
              name: 'Nombre',
              type: 'Tipo',
              save: 'Ahorrar',
              delete: 'Borrar',
              preview: 'Vista previa de cambios?',
              isDefault: '¿Tema predeterminado?',
              columns: {
                cod: 'Cod',
                description: 'Descripción',
                editor: 'color',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'No se encontraron campos',
                    toolTip: 'Clasificar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Pagina anterior',
                    rowsPerPage: 'Campos por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ir a la horae:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar json',
                    print: 'Imprimir',
                    viewColumns: 'Mostrar columnas',
                    filterTable: 'Mesa de Filtro',
                  },
                  filter: {
                    all: 'Toda',
                    title: 'Filtros',
                    reset: 'REINICIAR',
                  },
                  viewColumns: {
                    title: 'Mostrar columnas',
                    titleAria: 'Mostrar/ocultar columnas',
                  },
                  selectedRows: {
                    text: 'campos (s) seleccionados',
                    delete: 'Borrar',
                    deleteAria: 'Eliminar campos seleccionados',
                  },
                },
              },
            },
            editManageCaravan: {
              toolbar: {
                title: 'Administrar caravana',
              },
              tooltip: {
                add: 'Agregar',
                preview: 'Ver término de autorización',
                users: {
                  add: 'Agregar registro',
                },
              },
              review: 'Revisar',
              reviewed: 'REVISADA',

              notReviewed: 'NO REVISADO',
              whoReview: 'Revisado por',
              tutoredUser: {
                name: 'Nombre',
                idNumber: 'DNI',
                tagName: 'Nombre en Insignia',
                gender: 'Género',
                country: 'País',
                cellPhone: 'Celular',
                birthDate: 'Fecha de Nacimiento',
                authorizationTerm: 'Haga clic aquí para ver el Término de Autorización de Participación',
                dropzoneText: 'Arrastre y suelte un archivo PDF aquí o haga clic',
              },
              tutoredUserTable: {
                columns: {
                  name: 'Nombre',
                  idNumber: 'DNI',
                  country: 'País',
                },
                tableOptions: {
                  title: 'Usuarios Tutelados',
                  textLabels: {
                    body: {
                      noMatch: 'Ningún usuario tutelado encontrado',
                      toolTip: 'Ordenar',
                    },
                    pagination: {
                      next: 'Siguiente página',
                      previous: 'Página anterior',
                      rowsPerPage: 'Usuario Tutelado por página:',
                      displayRows: 'de',
                      jumpToPage: 'Ver página:',
                    },
                    toolbar: {
                      search: 'Buscar',
                      downloadCsv: 'Descargar JSON',
                      print: 'Imprimir',
                      viewColumns: 'Ver Columnas',
                      filterTable: 'Filtrar Tabla',
                    },
                    filter: {
                      all: 'Todos',
                      title: 'FILTROS',
                      reset: 'LIMPIAR',
                    },
                    viewColumns: {
                      title: 'Ver Columnas',
                      titleAria: 'Ver/Ocultar Columnas',
                    },
                    selectedRows: {
                      text: 'usuario(s) tutelado(s) seleccionado(s)',
                      delete: 'Eliminar',
                      deleteAria: 'Eliminar usuarios tutelados seleccionados',
                    },
                  },
                },
              },
              id: 'ID',
              type: 'Tipo',
              payed: 'Situación del Pago',
              name: 'Nombre',
              country: 'País',
              state: 'Estado',
              city: 'Ciudad',
              coordinator: 'Coordinador',
              vacancies: 'Plazas',
              remainingVacancies: 'Plazas Restantes',
              price: 'Precio',
              institution: 'Institución',
              caravanInformation: 'Información de la Caravana',
              tutoredCertificates: 'Certificados de los Usuarios',
              notices: 'Mensajes',
              authorizationPreview: 'Previsualización del Término de Autorización para Participación',
              notAccepted: 'Coordinador no está de acuerdo con los términos',
              notAcceptedText:
                'El coordinador de caravana no constó aceptación al término de responsabilidad de Coordinador de Caravana. Para utilizar las funcionalidades asignadas a un Coordinador de Caravana, por favor lea y acepte los términos.',
              goToPage: 'Ir a la página de aceptación del Término de Responsabilidad',
              columns: {
                name: 'Nombre',
                email: 'Correo electrónico',
                phone: 'Teléfono',
                cellPhone: 'Celular',
                payed: '¿Pagado?',
                confirmed: 'Situación de la Confirmación',
                accepted: '¿Aceptado?',
                idNumber: 'DNI',
                authorization: 'Término de Autorización',
                editAuthorization: 'Editar Término de Autorización',
              },
              insertDialogTitle: 'Elegir un participante o insertar nuevo',
              cancelInsertDialog: 'Guardar',
              delete: 'Eliminar',
              user: 'Usuario',
              tableOptions: {
                title: 'Inscritos en la Caravana',
                textLabels: {
                  body: {
                    noMatch: 'Ningún inscrito encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Inscritos por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar JSON',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'inscrito(s) seleccionado(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar inscritos seleccionados',
                  },
                },
              },
            },
            editCaravan: {
              toolbar: {
                newCaravan: 'Nueva Caravana',
                editCaravan: 'Actualizar Caravana',
              },
              id: 'ID',
              type: 'Tipo',
              payed: 'Situación del Pago',
              name: 'Nombre',
              country: 'País',
              state: 'Estado',
              city: 'Ciudad',
              coordinator: 'Coordinador',
              vacancies: 'Plazas',
              price: 'Precio',
              institution: 'Institución',
              save: 'Guardar',
              delete: 'Eliminar',
            },
            editTag: {
              toolbar: {
                title: 'Nueva Etiqueta',
              },
              name: 'Nombre',
              tagTemplate: 'Modelo de la Etiqueta',
              confirmationTemplate: 'Modelo de la Etiqueta de Confirmación',
              save: 'Guardar Etiqueta',
            },
            editHome: {
              content: 'Contenido de la Pantalla de Inicio...',
              language: 'Idioma',
              toolbar: {
                title: 'Pantalla de Inicio',
              },
              saveChanges: 'Guardar Cambios',
            },
            editCertificate: {
              toolbar: {
                editCertificate: 'Actualizar Certificado',
                newCertificate: 'Nuevo Certificado',
              },
              tooltip: {
                add: 'Agregar Contenido',
                preview: 'Previsualizar Certificado',
                enableQrCode: 'Habilitar Código QR',
                help: 'Ayuda',
              },
              help: {
                title: 'Ayuda con PALABRAS RESERVADAS',
                content:
                  'En el texto insertado dentro del contenido dinámico, es posible agregar palabras reservadas, para ser sustituidas con los valores dinámicos para cada usuario, evento, actividad o trayectoria. Las palabras se presentan a continuación y el significado de cada una.',
                track: {
                  title: 'Trayectoria',
                  name: 'Nombre de la Trayectoria',
                  initialDate: 'Fecha inicial de la Trayectoria',
                  finalDate: 'Fecha final de la Trayectoria',
                },
                user: {
                  title: 'Usuario',
                  name: 'Nombre del Usuario',
                },
                edition: {
                  title: 'Edición',
                  name: 'Nombre de la Edición',
                  year: 'Año de la Edición',
                  institution: 'Institución de la Edición',
                },
                activity: {
                  title: 'Actividad',
                  name: 'Nombre de la Actividad',
                  workload: 'Carga Horaria de la Actividad',
                  grid: 'La actividad se desarrollará durante todo el día',
                  data: 'Fecha',
                  start: 'Comenzar',
                  end: 'Fin',
                  schedule: 'Calendario de actividades',
                },
              },
              id: 'ID',
              name: 'Nombre',
              save: 'Guardar',
              delete: 'Eliminar',
              availabilityDateTime: 'Fecha y Hora de Disponibilidad',
              insertDialogTitle: 'Insertar Contenido Dinámico',
              cancelInsertDialog: 'Insertar',
              backgroundImage: {
                dropzoneText: 'Arrastre y suelte una imagen de fondo aquí o haga clic',
              },
              certificatePDF: {
                dropzoneText: 'Arrastre y suelte un archivo PDF aquí o haga clic',
              },
              certificatePreview: 'Previsualización del Certificado',
              userCertificate: 'Certificados del Usuario: ',
              columns: {
                x: 'X',
                y: 'Y',
                content: 'Contenido',
                fontSize: 'Tamaño de la Fuente',
                font: 'Familia de la Fuente',
                color: 'Color del Texto',
                bold: 'Negrita',
                italic: 'Cursiva',
                underlined: 'Subrayado',
              },
              tableOptions: {
                title: 'Contenido Dinámico',
                textLabels: {
                  body: {
                    noMatch: 'Ningún contenido encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Contenidos por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'contenido(s) seleccionado(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar contenidos seleccionados',
                  },
                },
              },
            },
            editInstitution: {
              toolbar: {
                editInstitution: 'Actualizar Institución',
                newInstitution: 'Nueva Institución',
              },
              id: 'ID',
              name: 'Nombre',
              shortName: 'Sigla',
              country: 'País',
              state: 'Estado',
              city: 'Ciudad',
              phone: 'Teléfono',
              mobilePhone: 'Celular',
              website: 'Sitio web',
              save: 'Guardar',
              delete: 'Eliminar',
              insertDialogTitle: 'Insertar Espacio',
              updateDialogTitle: 'Actualizar Espacio',
              cancelInsertDialog: 'Insertar',
              isHost: '¿La institución es la sede del evento?',
              columns: {
                name: 'Nombre',
                number: 'Número',
                languageFlag: 'Bandera',
              },
              tableOptions: {
                title: 'Espacios',
                textLabels: {
                  body: {
                    noMatch: 'Ningún espacio encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Espacios por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'espacio(s) seleccionado(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar espacios seleccionados',
                  },
                },
              },
            },
            editTrack: {
              toolbar: {
                editTrack: 'Actualizar Trayectoria',
                newTrack: 'Nueva Trayectoria',
              },
              id: 'ID',
              name: 'Nombre',
              initialDate: 'Fecha de Inicio',
              finalDate: 'Fecha de Fin',
              description: 'Descripción de la Trayectoria...',
              attendeeCertificate: 'Certificado de los Participantes',
              speakerCertificate: 'Certificado de los Ponentes',
              save: 'Guardar',
              delete: 'Eliminar',
            },
            editEdition: {
              toolbar: {
                editEdition: 'Actualizar Edición',
                newEdition: 'Nueva Edición',
              },
              enabled: '¿Habilitada?',
              id: 'ID',
              name: 'Nombre',
              year: 'Año',
              shortName: 'Sigla',
              initialDate: 'Fecha de Inicio',
              finalDate: 'Fecha de Fin',
              country: 'País',
              state: 'Estado',
              city: 'Ciudad',
              place: 'Lugar Sede',
              description: 'Descripción',
              editionType: 'Tipo',
              goToCustomization: 'Ir a Personalización',
              save: 'Guardar',
              delete: 'Eliminar',
            },
            myRegistration: {
              toolbar: {
                myRegistration: 'Mi Inscripción',
              },
              tooltip: {
                calendar: 'Calendario',
              },
              id: 'ID',
              payed: 'Pagada',
              notPayed: 'No Pagada',
              status: 'Situación',
              totalPrice: 'Valor Total',
              registrationTypePrice: 'Valor de la Inscripción',
              activitiesPrice: 'Valor de las Actividades',
              scheduleTitle: 'Ver Programación',
              payment: {
                title: 'Pago',
                cardDetails: 'Detalles de la Tarjeta',
                pagSeguroText: 'Pago procesado por ',
                summary: 'Resumen',
                total: 'Total',
                number: 'Número de Tarjeta',
                holder: 'Nombre del Titular',
                expMonth: 'Mes',
                expYear: 'Año',
                cvv: 'CVV',
                expires: 'Fecha de Vencimiento',
                securityCode: 'Código de Seguridad',
                confirmPayment: 'Confirmar Pago',
                cancelPayment: 'Cancelar Pago',
                installments: 'Cuota(s) de',
                possibleInstallment: 'Cuota',
                subTotal: 'Subtotal',
                promotion: 'Descuento',
                paypal: 'PayPal',
                pagseguro: 'PagSeguro',
                sendPix: 'Ver QrCode/Clave PIX',
                verifyPayment:'Verificar Pago',
                pixCopyText: 'PIX Copia y Pega',
                pixQrCode: 'QrCode del PIX',
                pix: 'PIX',
                pixDetails: 'Cobro por PIX',
                pixKey: 'Clave Pix',
                receiver: {
                  name: 'Nombre del Receptor',
                  key: 'Clave PIX',
                },
                paymentStatus: {
                  title: 'Estado del Cobro',
                  chargeSuccess:
                    '¡Cobro realizado con éxito! Se envió un correo electrónico con más información, y para seguir el estado del pago, verifique esta página cada 5 minutos.',
                  cancelSuccess:
                    '¡Cancelación realizada con éxito! Se envió un correo electrónico con más información sobre la cancelación, y se está realizando el reembolso total del valor de la inscripción y actividades pagadas.',
                  voucherFound:
                    '¡Inscripción Exenta! Se encontró un voucher de exención vinculado a este correo electrónico, el costo de la inscripción fue exento.',
                },
              },
              activityCard: {
                track: 'Trayectoria',
                speakers: 'Ponentes',
                responsible: 'Responsable',
                noResponsible: 'Sin Responsable',
                noSchedule: 'Sin horario',
                vacancies: 'Plazas',
                workload: 'Carga Horaria',
                type: 'Tipo de Actividad',
                presentationType: 'Tipo de Presentación',
                presentationRemote: "Atividade Remota",
                local: "Local",
                language: 'Idioma',
                price: 'Precio',
                conflict: 'Conflicto',
                start: 'Inicio',
                end: 'Fin',
                date: 'Fecha',
                calendarTooltip: 'Consultar calendário',
                speakerInfo: {
                  biography: 'Biografía',
                  contact: 'Contacto',
                  additionalInformation: 'Información Adicional',
                },
                trackInfo: {
                  description: 'Descripción',
                  initialDate: 'Fecha Inicial',
                  finalDate: 'Fecha Final',
                },
                noDescription: 'Actividad sin descripcion',
                includeInPurchase: 'incluir en la compra',
                removeFromCart: 'Eliminar de la compra',
                tooltipDescription: 'Haga clic para ver más',
                schedule: 'Programación de la actividad',
              },
              lecturePackCard: {
                activityList: 'Actividades Incluidas',
                title: 'Actividades Básicas',
              },
              titleTable: 'Actividades opcionales adquiridas',
              viewConflicts: 'Visualizar Conflicto'
            },
            myFeedbacks: {
              toolbar: {
                title: 'Nuevo Feedback',
              },
              tooltip: {
                add: 'Agregar Feedback',
              },
              title: 'Título',
              description: 'Descripción del feedback...',
              save: 'Guardar',
              date: 'Fecha de Creación',
              status: 'Estado',
            },
            myAccount: {
              toolbar: {
                myAccount: 'Mi Cuenta',
                editMyAccount: 'Editar Cuenta',
              },
              admin: 'Administrador',
              enabled: 'Habilitado',
              notEnabled: 'Deshabilitado',
              confirmed: 'Correo electrónico confirmado',
              notConfirmed: 'Correo electrónico no confirmado',
              communication: 'Recibir información de este evento por correo electrónico',
              socialCommunication: 'Permitir que la plataforma presente sus contactos sociales',
              editProfile: 'Editar Perfil',
              gitHub: 'Desvincular de GitHub',
              google: 'Desvincular de Google',
              untieDialogTitle: 'Desvincular Inicio de Sesión Social',
              untieDialogContent: '¿Realmente desea desvincular este método de inicio de sesión?',
              untie: 'Desvincular',
              sliderScale: 'Escala',
              sliderRotation: 'Rotación',
              changeUserImageContent: '¿Realmente desea cambiar la imagen de usuario?',
              changeUserImage: 'Guardar',
              removeUserImage: 'Eliminar Imagen Actual',
              goBack: 'Volver',
              changeUserImageTitle: 'Cambiar Imagen del Usuario',
              id: 'ID',
              accessProfile: 'Perfil de Acceso',
              allowEmail: 'Deseo recibir información de este evento por correo electrónico.',
              allowContact: 'Permito que la plataforma disponga mis contactos sociales para que otros puedan contactarme.',
              addressUnique: 'Dirección',
              name: 'Nombre',
              cardName: 'Nombre en Insignia',
              idNumber: 'Número de Identidad',
              gender: 'Género',
              birthDate: 'Fecha de nacimiento',
              email: 'Correo electrónico',
              github: 'Github',
              linkedin: 'Linkedin',
              lattes: 'Lattes',
              website: 'Sitio web',
              orcid: 'Orcid',
              biography: 'Biografía',
              zipCode: 'Código Postal',
              address: 'Dirección Línea 1',
              address2: 'Dirección Línea 2',
              nin: 'CPF',
              mobilePhone: 'Celular',
              phone: 'Teléfono',
              country: 'País',
              state: 'Estado',
              city: 'Ciudad',
              company: 'Institución',
              function: 'Cargo',
              save: 'Guardar',
              delete: 'Eliminar Cuenta',
              password: {
                password: 'Contraseña',
                passwordConfirmation: 'Confirmación de Contraseña',
                tooShort: 'muy corta',
              },
              specialNeedsText: 'PcD - Persona con Discapacidad',
              otherNeeds: 'Otras Atenciones',
            },
            changePassword: {
              toolbar: {
                changePassword: 'Crear nueva contraseña'
              },
              new_password: 'Nueva contraseña',
              save: 'Registrar nueva contraseña',
              messageTitleReset: "¡Hola! Se ha registrado una solicitud de restablecimiento de contraseña para esta cuenta. Para garantizar su seguridad, establezca una nueva contraseña.",
              messageTitleRedefine: "Ingrese la nueva contraseña que desea usar para acceder a esta cuenta."
            },
            resetPassword: {
              toolbar: {
                resetPassword: 'Restablecer Contraseña',
              },
              email: 'Correo electrónico',
              reset: 'Restablecer Contraseña',
              cancel: 'Cancelar',
            },
            emailConfirmation: {
              toolbar: {
                emailConfirmation: 'Confirmación de Correo Electrónico',
              },
              confirmed: 'Correo electrónico confirmado. ¡Gracias!',
              notConfirmed: '¡No se pudo confirmar su dirección de correo electrónico!',
              cancel: 'Inicio',
            },
            certificateValidation: {
              toolbar: {
                validation: 'Validación del Certificado',
              },
            },
            autoRegistration: {
              toolbar: {
                newUser: 'Nuevo Usuario',
              },
              subtitle1: 'Datos de usuario',
              subtitle2: 'Información adicional (opcional)',
              subtitle3: 'Dirección',
              gitHub: 'Registrar con GitHub',
              google: 'Registrar con Google',
              id: 'ID',
              accessProfile: 'Perfil de Acceso',
              allowEmail: 'Deseo recibir información de este evento por correo electrónico.',
              addressUnique: 'Dirección',
              name: 'Nombre completo (se utilizará en la emisión del certificado)',
              cardName: 'Nombre en Insignia',
              idNumber: 'Número de Identidad',
              gender: 'Género',
              birthDate: 'Fecha de nacimiento',
              email: 'Correo electrónico',
              github: 'Github',
              linkedin: 'Linkedin',
              lattes: 'Lattes',
              website: 'Sitio web',
              orcid: 'Orcid',
              biography: 'Agregar biografía',
              zipCode: 'Código Postal',
              address: 'Dirección Línea 1',
              address2: 'Dirección Línea 2',
              nin: 'CPF',
              mobilePhone: 'Celular',
              phone: 'Teléfono',
              country: 'País',
              state: 'Estado',
              city: 'Ciudad',
              company: 'Institución',
              function: 'Cargo',
              save: 'Guardar',
              exempt: 'Eximir Usuario',
              password: {
                password: 'Contraseña',
                passwordConfirmation: 'Confirmación de Contraseña',
                tooShort: 'muy corta',
              },
              specialNeedsText: 'PcD - Persona con Discapacidad',
              otherNeeds: 'Otras Atenciones',
              terms: {
                privacyPolicy: 'Política de Privacidad',
                cancellationRefundPolicy: 'Política de Cancelación y Reembolso',                
                useTerm: 'Términos de Uso',
                imageTerm: 'Término de Autorización de Uso de Imagen y Datos',
                coordinatorTerm: 'Término de Responsabilidad del Coordinador de Caravana',
                authorizationTerm: 'Declaración de Autorización Participación En Evento de Niño O Adolescente',
                apiUseTerm: 'Términos de Uso de la API del Sistema',
                useTermAgreement: 'Al registrarme acepto los',
                privacyPolicyAgreement: ', estoy de acuerdo con la',
                and: 'y el',
              },
              alerts: {
                text1: 'Después de completar el registro del sistema, recibirá un correo electrónico para confirmar el correo electrónico.',
                text2: 'El registro solo crea tu cuenta. Para participar del evento, debes inscribirte formalmente dentro de la plataforma.',
                text3: 'Para completar el registro, debe leer y estar de acuerdo con los términos de uso del sistema.',
              }
            },
            dpoManage: {
              user: 'Usuario',
              tutoredUser: 'Usuario Tutelado',
              requestDate: 'Fecha de Solicitud',
              note: 'Observación',
              applicantContact: 'Teléfono del Solicitante',
              requestType: 'Tipo de Solicitud',
              attachment: 'Adjunto',
              id: 'Id',
              registryDate: 'Fecha de la Solicitud',
              status: 'Estado de la Solicitud',
              returnDate: 'Fecha de la Devolución',
              deadlineExclusionDate: 'Fecha límite Exclusión',
              deleteRequestBegin: 'Datos de la Solicitud de Exclusión',
              deleteRequestEnd: 'Datos de la Exclusión',
            },
            userExclusion: {
              user: 'Usuario',
              requestDate: 'Fecha de Solicitud',
              note: 'Observación',
              applicantContact: 'Teléfono del Solicitante',
              requestType: 'Tipo de Solicitud',
              attachment: 'Adjunto',
              id: 'Id',
              registryDate: 'Fecha de la Solicitud',
              status: 'Estado de la Solicitud',
              returnDate: 'Fecha de la Devolución',
              deadlineExclusionDate: 'Fecha límite Exclusión',
              effectiveDeletionDate: 'Fecha efectiva Exclusión',
            },
            editActivity: {
              toolbar: {
                editActivity: 'Actualizar Actividad',
                newActivity: 'Nueva Actividad',
              },
              id: 'ID',
              name: 'Título',
              type: 'Tipo',
              track: 'Trayectoria',
              description: 'Descripción de la Actividad...',
              speakerCertificate: 'Certificado de los Ponentes',
              attendeeCertificate: 'Certificado de los Participantes',
              responsibleUser: 'Coordinador de Malla Responsable',
              speaker: 'Ponente',
              vacancies: 'Plazas',
              price: 'Precio',
              language: 'Idioma',
              languageFlag: 'Bandera',
              presentationType: 'Tipo de Presentación',
              workload: 'Carga Horaria',
              certificate: 'Certificado',
              place: 'Lugar',
              placeUrl: 'Lugar en Línea',
              save: 'Guardar',
              delete: 'Eliminar',
              scheduleDialogTitle: 'Gestionar Programación',
              calendarColor: 'Color en la Agenda',
            },
            speakerActivityList: {
              activityCard: {
                name: 'Nombre',
                type: 'Tipo de Actividad',
                responsibleUser: 'Coordinador de Malla Responsable',
                speakers: 'Ponentes',
                language: 'Idioma',
                presentationType: 'Tipo de Presentación',
                place: 'Lugar',
                webPlace: 'Lugar en Línea',
                track: 'Trayectoria',
                description: 'Descripción',
                activityDialogTitle: 'Actividades del Ponente',
              },
            },
            userActivities: {
              helper: {
                editActivities: {
                  title: 'Cambiar a la selección de actividades',
                  content:
                    'Este botón permite cambiar a la edición de la agenda personalizada. A través de la edición, permite presentar solo las programaciones seleccionadas por usted. Para demostración, haga clic en el botón para ver la siguiente guía.',
                },
                favorite: {
                  title: 'Seleccionar Programación',
                  content:
                    'A través del clic en la estrella, es posible marcar o desmarcar la programación de la actividad para que pertenezca a la agenda personalizada.\n Demostración: ',
                },
                favoriteAll: {
                  title: 'Seleccionar toda la Actividad',
                  content:
                    'A través del clic en el evento, se presenta otro botón con icono de estrella. Con el clic en ese botón, es posible marcar o desmarcar todas las programaciones de la actividad en la agenda personalizada.\n Demostración: ',
                },
                fab: {
                  title: 'Guardar agenda personalizada',
                  content: 'A través de este botón es posible guardar toda la programación seleccionada.',
                },
              },
              activityCard: {
                name: 'Nombre',
                type: 'Tipo de Actividad',
                speakers: 'Ponentes',
                language: 'Idioma',
                presentationType: 'Tipo de Presentación',
                local: "Local",
                place: 'Lugar',
                webPlace: 'Lugar en Línea',
                availabilityLink: 'El enlace de acceso estará disponible después del pago de la inscripción.',
                track: 'Trayectoria',
                description: 'Descripción',
                activityDialogTitle: 'Actividad Inscrita',
              },
              saveUserSchedule: 'Guardar la agenda personalizada',
              editUserSchedule: 'Cambiar a edición de programación',
              userSchedule: 'Cambiar a las actividades elegidas',
              addSchedule: 'Agregar programación elegida',
              removeSchedule: 'Eliminar programación elegida',
              addActivity: 'Agregar toda la programación de la actividad elegida',
              removeActivity: 'Eliminar toda la programación de la actividad elegida',
              removedSchedules: 'Programaciones Eliminadas',
              schedule: {
                title: 'Programación',
                name: 'Título',
                allDay: 'Todo el día',
                initialDate: 'Fecha Inicial',
                finalDate: 'Fecha Final',
              },
            },
            editUser: {
              toolbar: {
                editUser: 'Actualizar Usuario',
                newUser: 'Nuevo Usuario',
              },
              admin: '¿Administrador?',
              changePassword: 'Crear nueva contraseña',
              resetPassword: 'Restablecer Contraseña',
              addRegistration: 'Realizar Inscripción',
              id: 'ID',
              accessProfile: 'Perfil de Acceso',
              name: 'Nombre',
              cardName: 'Nombre en Insignia',
              idNumber: 'Número de Identidad',
              gender: 'Género',
              birthDate: 'Fecha de nacimiento',
              email: 'Correo electrónico',
              github: 'Github',
              linkedin: 'Linkedin',
              lattes: 'Lattes',
              website: 'Sitio web',
              orcid: 'Orcid',
              biography: 'Agregar biografía',
              zipCode: 'Código Postal',
              address: 'Dirección Línea 1',
              addressUnique: 'Dirección',
              address2: 'Dirección Línea 2',
              nin: 'CPF',
              mobilePhone: 'Celular',
              phone: 'Teléfono',
              country: 'País',
              state: 'Estado',
              city: 'Ciudad',
              company: 'Institución',
              function: 'Cargo',
              userPermissions: 'Permisos del Usuario',
              save: 'Guardar',
              allowEmail: '¿Enviar información de este evento por correo electrónico?',
              delete: 'Eliminar',
              reset: 'Restablecer Contraseña',
              cancelResetDialog: 'Cancelar',
              resetDialogTitle: 'Restablecer Contraseña',
              resetDialogContent: '¿Realmente desea restablecer la contraseña del usuario?',
              password: {
                password: 'Contraseña',
                passwordConfirmation: 'Confirmación de Contraseña',
                tooShort: 'muy corta',
              },
              confirmedEmail: '¿Correo electrónico confirmado?',
              resendEmail: 'Reenviar correo electrónico',
              enable: '¿Habilitado?',
              specialNeedsText: 'PcD - Persona con Discapacidad',
              otherNeeds: 'Otras Atenciones',
            },
            accessProfileList: {
              toolbar: {
                title: 'Todos los Perfiles de Acceso',
              },
              tooltip: {
                add: 'Agregar Perfil de Acceso',
              },
              columns: {
                name: 'Nombre',
                description: 'Descripción',
                permissions: 'Permisos',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ningún perfil de acceso encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Perfiles por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'perfil(es) seleccionado(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar perfiles seleccionados',
                  },
                },
              },
            },
            registrationList: {
              registrationDialog: {
                title: 'Información de la Inscripción',
              },
              registrationActivity: {
                activityUser: 'Actividades del Usuario',
              },
              toolbar: {
                title: 'Todas las Inscripciones',
              },
              tooltip: {
                add: 'Agregar',
                preview: 'Visualizar Término de Autorización',
              },
              authorizationPreview: 'Previsualización del Término de Autorización para Participación',
              columns: {
                user: 'Usuario',
                registrationDateTime: 'Fecha y Hora de la Inscripción',
                payed: 'Situación del Pago',
                price: 'Precio',
                percentage: 'Porcentaje (%)',
                authorization: 'Término de Autorización',
              },
              tabs: {
                registrations: 'Inscripciones de Usuarios',
                tutoredRegistrations: 'Inscripciones de Usuarios Tutelados',
                config: 'Configurar Inscripción',
              },
              configForm: {
                initialDateTime: 'Fecha y Hora de Inicio',
                finalDateTime: 'Fecha y Hora de Fin',
                price: 'Precio',
                id: 'ID',
                columns: {
                  initialDateTime: 'Fecha y Hora de Inicio',
                  finalDateTime: 'Fecha y Hora de Fin',
                  price: 'Precio',
                  vacancies: 'Límite de Plazas',
                  percentage: 'Porcentaje (%)',
                },
                tooltip: {
                  add: 'Agregar Valor Promocional',
                },
                insertDialogTitle: 'Insertar Valor Promocional',
                cancelInsertDialog: 'Guardar',
                delete: 'Eliminar',
                cancelDeleteDialog: 'Cancelar',
                deleteDialogTitle: 'Eliminar valores promocionales',
                deleteDialogContent: '¿Realmente desea eliminar los valores promocionales?',
                tableOptions: {
                  title: 'Valores Promocionales',
                  textLabels: {
                    body: {
                      noMatch: 'Ningún valor promocional encontrado',
                      toolTip: 'Ordenar',
                    },
                    pagination: {
                      next: 'Siguiente página',
                      previous: 'Página anterior',
                      rowsPerPage: 'Valores Promocionales por página:',
                      displayRows: 'de',
                      jumpToPage: 'Ver página:',
                    },
                    toolbar: {
                      search: 'Buscar',
                      downloadCsv: 'Descargar CSV',
                      print: 'Imprimir',
                      viewColumns: 'Ver Columnas',
                      filterTable: 'Filtrar Tabla',
                    },
                    filter: {
                      all: 'Todos',
                      title: 'FILTROS',
                      reset: 'LIMPIAR',
                    },
                    viewColumns: {
                      title: 'Ver Columnas',
                      titleAria: 'Ver/Ocultar Columnas',
                    },
                    selectedRows: {
                      text: 'valor(es) promocional(es) seleccionado(s)',
                      delete: 'Eliminar',
                      deleteAria: 'Eliminar Valores Promocionales Seleccionados',
                    },
                  },
                },
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ninguna inscripción encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Inscripciones por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'inscripción(es) seleccionada(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar inscripciones seleccionadas',
                  },
                },
              },
            },
            activityList: {
              allDay: 'Todo el Día',
              toolbar: {
                title: 'Todas las Actividades',
              },
              helper: {
                scroll: {
                  title: 'Desplazamiento de las Programaciones',
                  content:
                    'Es posible ver todos los horarios disponibles para esta edición en esta tabla. Se puede desplazar horizontalmente para mostrar todas las salas no exhibidas y sus actividades programadas',
                },
                tracks: {
                  title: 'Leyenda de las Trayectorias',
                  content:
                    'Aquí contiene la leyenda de los colores utilizados en las actividades presentadas en la agenda. Cada color en la actividad representa la trayectoria a la que pertenece esa actividad.',
                },
              },
              tracks: 'Trayectorias: ',
              tooltip: {
                add: 'Registrar',
              },
              columns: {
                name: 'Nombre',
                track: 'Trayectoria',
                speaker: 'Ponente',
                vacancies: 'Plazas',
                remainingVacancies: 'Plazas Restantes',
                price: 'Precio',
                type: 'Tipo de Actividad',
                presentationType: 'Tipo de Presentación',
              },
              tabs: {
                schedule: 'Agenda',
                activities: 'Todas las Actividades',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ninguna actividad encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Actividades por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'actividad(es) seleccionada(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar actividades seleccionadas',
                  },
                },
              },
            },
            enrollmentTypeList: {
              toolbar: {
                title: 'Todos los Tipos de Inscripción',
              },
              tracks: 'Trayectoria(s): ',
              tooltip: {
                add: 'Agregar Tipo de Inscripción',
              },
              columns: {
                name: 'Nombre',
                initialTimestamp: 'Fecha/Hora de Inicio',
                finalTimestamp: 'Fecha/Hora de Fin',
                enable: '¿Habilitado?',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ningún tipo de inscripción encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Tipos por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'tipo(s) seleccionado(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar tipos seleccionados',
                  },
                },
              },
            },
            satisfactionSurveyList: {
              toolbar: {
                title: 'Todas las Encuestas de Satisfacción',
              },
              tooltip: {
                add: 'Agregar Encuesta de Satisfacción',
              },
              columns: {
                name: 'Nombre',
                url: 'URL',
                status: 'Estado',
                enable: '¿Habilitado?',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ninguna encuesta de satisfacción encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Encuestas por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'encuesta(s) seleccionada(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar encuestas seleccionadas',
                  },
                },
              },
            },
            caravanRegistrationList: {
              toolbar: {
                title: 'Todas las Caravanas',
              },
              tooltip: {
                add: 'Agregar Caravana',
              },
              registrationDialogTitle: 'Inscripción en la Caravana',
              confirmRegistrationDialog: 'Confirmar',
              registrationDialogContent: '¿Confirma que desea inscribirse en esta caravana?',
              registrationCancelDialogTitle: 'Cancelar Inscripción en la Caravana',
              confirmRegistrationCancelDialog: 'Cancelar',
              registrationCancelDialogContent: '¿Confirma que desea eliminar su inscripción en esta caravana?',
              confirmRegistrationCancelDialogConfirm: 'Eliminar',
              columns: {
                name: 'Nombre',
                institution: 'Institución',
                country: 'País',
                state: 'Estado',
                city: 'Ciudad',
                price: 'Precio',
                vacancies: 'Plazas',
                remainingVacancies: 'Plazas Restantes',
                confirmed: 'Situación de la Confirmación',
                accepted: 'Situación de la Aceptación',
                textButtonAction: 'Participar',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ninguna caravana encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Caravanas por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'caravana(s) seleccionada(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar caravanas seleccionadas',
                  },
                },
              },
            },
            trackList: {
              toolbar: {
                title: 'Todas las Trayectorias',
              },
              tooltip: {
                add: 'Registrar',
              },
              columns: {
                name: 'Nombre',
                initialDate: 'Fecha de Inicio',
                finalDate: 'Fecha de Fin',
                calendarColor: 'Color',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ninguna trayectoria encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Trayectorias por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'trayectoria(s) seleccionada(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar trayectorias seleccionadas',
                  },
                },
              },
            },
            caravanList: {
              toolbar: {
                title: 'Todas las Caravanas',
              },
              tooltip: {
                add: 'Registrar',
              },
              columns: {
                name: 'Nombre',
                coordinator: 'Coordinador',
                institution: 'Institución',
                country: 'País',
                state: 'Estado',
                city: 'Ciudad',
                price: 'Precio',
                vacancies: 'Plazas',
                remainingVacancies: 'Plazas Restantes',
                payed: 'Situación del Pago',
                type: 'Tipo',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ninguna caravana encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Caravanas por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'caravana(s) seleccionada(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar caravanas seleccionadas',
                  },
                },
              },
            },
            themeList: {
              toolbar: {
                title: 'Personalización',
              },
              tooltip: {
                add: 'Agregar Tema',
              },
              choseTheme: {
                light: 'Tema Claro',
                dark: 'Tema Oscuro',
                saveChanges: 'Guardar',
                themeChoice: 'Elección de Tema',
                allThemes: 'Todos los Temas',
              },
              choseLogo: {
                logo: 'Logo',
                saveLogo: 'Guardar',
                deleteLogo: 'Eliminar Logo',
              },
              app: {
                application: 'Aplicación',
                appName: 'Nombre de la Aplicación',
                fontSize: 'Tamaño de Fuente',
                fontFamily: 'Tipo de Fuente',
                saveChanges: 'Guardar Cambios',
              },
              columns: {
                name: 'Nombre',
                type: 'Tipo',
              },
              delete: 'Eliminar',
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ningún tema encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Temas por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'tema(s) seleccionado(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar temas seleccionados',
                  },
                },
              },
            },
            internationalizationList: {
              toolbar: {
                title: 'Todos los Idiomas',
              },
              tooltip: {
                add: 'Agregar Idioma',
                upload: 'Subir JSON',
              },
              chooseJSON: {
                dropzoneText: 'Arrastre y suelte un archivo aquí o haga clic',
              },
              columns: {
                cod: 'Código',
                flag: 'Bandera',
                download: 'Descargar JSON',
                upload: 'Subir JSON',
                enable: '¿Habilitado?',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ningún idioma encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Idiomas por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'idioma(s) seleccionado(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar idiomas seleccionados',
                  },
                },
              },
            },
            institutionList: {
              toolbar: {
                title: 'Todas las Instituciones',
              },
              tooltip: {
                add: 'Registrar',
              },
              columns: {
                name: 'Nombre',
                country: 'País',
                state: 'Estado',
                city: 'Ciudad',
                phone: 'Teléfono',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ninguna institución encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'instituciones por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'institución(es) seleccionada(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar Instituciones Seleccionadas',
                  },
                },
              },
            },
            delete: 'Eliminar',
            editionList: {
              toolbar: {
                title: 'Todos las Ediciones',
              },
              tooltip: {
                add: 'Registrar',
              },
              columns: {
                name: 'Nombre',
                year: 'Año',
                enabled: '¿Habilitado?',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ninguna edición encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Ediciones por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'edición(es) seleccionada(s)',
                    delete: 'Eliminar',
                    deleteAria

                      : 'Eliminar Ediciones Seleccionados',
                  },
                },
              },
              delete: 'Eliminar',
            },
            manageCaravanList: {
              toolbar: {
                title: 'Todas las Caravanas',
              },
              tooltip: {
                add: 'Agregar Caravana',
              },
              columns: {
                name: 'Nombre',
                type: 'Tipo',
                institution: 'Institución',
                coordinator: 'Coordinador',
                vacancies: 'Plazas',
                remainingVacancies: 'Plazas Restantes',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ninguna caravana encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Caravanas por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'caravana(s) seleccionada(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar Caravanas Seleccionadas',
                  },
                },
              },
              delete: 'Eliminar',
            },
            feedbackList: {
              toolbar: {
                title: 'Todos los Feedbacks',
              },
              tooltip: {
                download: 'Descargar Imágenes',
              },
              columns: {
                title: 'Título',
                user: 'Usuario',
                download: 'Imágenes',
                status: 'Estado',
                apiVersion: 'Versión API',
                webVersion: 'Versión WEB',
              },
              id: 'ID',
              insertDialogTitle: 'Actualizar Feedback',
              cancelInsertDialog: 'Guardar',
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ningún feedback encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Feedbacks por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'feedback(s) seleccionado(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar feedbacks Seleccionados',
                  },
                },
              },
            },
            myCertificatesList: {
              toolbar: {
                title: 'Todos mis certificados',
              },
              tooltip: {
                download: 'Descargar Certificado',
                downloads: 'Descargar Certificado(s)',
                preview: 'Previsualizar Certificado',
              },
              columns: {
                activityName: 'Actividad',
                trackName: 'Trayectoria',
                download: 'Certificado',
                preview: 'Visualizar',
                certificateType: 'Tipo del Certificado',
                availability: 'Fecha de Disponibilidad',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ningún certificado encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Certificados por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'certificado(s) seleccionado(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar certificados Seleccionados',
                  },
                },
              },
            },
            voucherList: {
              updateDialogTitle: 'Actualizar Voucher',
              saveDialog: 'Guardar Voucher',
              insertDialogTitle: 'Agregar Voucher',
              toolbar: {
                title: 'Todos los Vouchers',
              },
              tooltip: {
                add: 'Registrar',
              },
              columns: {
                id: 'Id',
                userEmail: 'Correo Electrónico del Usuario',
                editionName: 'Nombre de la Edición',
                editionYear: 'Año de la Edición',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ningún voucher encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'voucher por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'voucher(s) seleccionado(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar Vouchers Seleccionados',
                  },
                },
              },
            },
            tutoredUsersCertificateList: {
              toolbar: {
                title: 'Todos los Certificados de los Usuarios Tutelados',
              },
              tooltip: {
                download: 'Descargar Certificado',
              },
              columns: {
                activityName: 'Actividad',
                trackName: 'Trayectoria',
                download: 'Certificado',
                preview: 'Previsualización del Certificado',
                userName: 'Nombre del Usuario',
                availability: 'Fecha de Disponibilidad',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ningún certificado encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Certificados por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'certificado(s) seleccionado(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar certificados Seleccionados',
                  },
                },
              },
            },
            userList: {
              toolbar: {
                title: 'Todos los Usuarios',
              },
              tooltip: {
                add: 'Registrar',
              },
              columns: {
                name: 'Nombre',
                email: 'Correo Electrónico',
                tagName: 'Nombre Insignia',
                cellPhone: 'Contacto',
                institution: 'Institución',
                country: 'País',
                state: 'Estado',
                city: 'Ciudad',
                enable: '¿Habilitado?',
                exclusionDate: 'Fecha Máxima de Exclusión',
                inactivity: 'Inactividad',
                confirmed: 'Correo Confirmado',
                editions: 'Participante de los Eventos',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ningún usuario encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Usuarios por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {


                    text: 'usuario(s) seleccionado(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar usuarios Seleccionados',
                  },
                },
              },
              delete: 'Eliminar',
            },
            exclusionList: {
              toolbar: {
                title: 'Todas las Solicitudes de Exclusión',
              },
              columns: {
                exclusionStatus: 'Estado de la Solicitud',
                registryDate: 'Fecha de la Solicitud',
                name: 'Nombre del Usuario',
                email: 'Correo Electrónico',
                note: 'Observación',
                applicantContact: 'Contacto',
                requestType: 'Tipo de la Solicitud',
                actions: 'Acciones',
              },
              tooltip: {
                edit: 'Editar solicitud',
                complete: 'Anonimizar datos del usuario',
                notApproved: 'No es posible anonimizar los datos ya que el estado de la solicitud no es “Aprobado”.',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ninguna solicitud encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Solicitudes por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'solicitud(es) seleccionada(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar solicitudes Seleccionadas',
                  },
                },
              },
              delete: 'Eliminar',
            },
            emailList: {
              toolbar: {
                title: 'Correos Electrónicos',
              },
              columns: {
                subject: 'Asunto',
                class: 'Categoría',
                status: 'Estado',
                timestamp: 'Fecha/Hora',
                enable: '¿Habilitado?',
              },
              tooltip: {
                add: 'Crear Correo Electrónico',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ningún correo electrónico encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Correos Electrónicos por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'correo(s) electrónico(s) seleccionado(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar Correos Electrónicos Seleccionados',
                  },
                },
              },
            },
            exclusion: {
              columns: {
                requestDate: 'Fecha de Solicitud',
                note: 'Observación',
                applicantContact: 'Teléfono del Solicitante',
              },
            },
            certificateList: {
              toolbar: {
                title: 'Certificados',
              },
              columns: {
                name: 'Nombre',
                availability: 'Disponibilidad',
                enable: '¿Habilitado?',
              },
              tooltip: {
                add: 'Registrar',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ningún certificado encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Certificados por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',


                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'certificado(s) seleccionado(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar certificados Seleccionados',
                  },
                },
              },
            },
            templateList: {
              toolbar: {
                title: 'Modelos',
              },
              columns: {
                name: 'Nombre',
                type: 'Tipo',
              },
              tooltip: {
                add: 'Agregar Modelo',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ningún modelo encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Modelos por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'modelo(s) seleccionado(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar modelos Seleccionados',
                  },
                },
              },
            },
            tagList: {
              toolbar: {
                title: 'Etiquetas',
              },
              tooltip: {
                add: 'Agregar Etiqueta',
                print: 'Imprimir Etiqueta',
              },
              filter: 'Filtro',
              intervalStart: 'Inicio del Intervalo de Inscripción',
              intervalEnd: 'Fin del Intervalo de Inscripción',
              letterIntervalStart: 'Inicio del Intervalo de Letras',
              letterIntervalEnd: 'Fin del Intervalo de Letras',
              enrollmentType: 'Tipo de Inscripción',
              generated: 'Incluir ya Generados?',
              caravans: 'Incluir Usuarios de Caravanas?',
              pay: '¿Solo Pagados/Exentos?',
              confirmation: '¿Imprimir Etiqueta de Confirmación?',
              selectedCaravans: 'Caravanas Seleccionadas',
              coordinatorConfirmed: '¿Solo Inscripciones Confirmadas por el Coordinador?',
              selectedSpeakers: 'Ponentes Seleccionados',
              dialog: {
                title: 'Imprimir Etiqueta',
                save: 'Imprimir',
              },
              columns: {
                name: 'Nombre',
                print: 'Imprimir',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ninguna etiqueta encontrada',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Etiquetas por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'etiqueta(s) seleccionada(s)',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar etiquetas Seleccionadas',
                  },
                },
              },
            },
            audit: {
              toolbar: {
                title: 'Auditoría',
              },
              columns: {
                timestamp: 'Fecha/Hora',
                action: 'Evento',
                description: 'Descripción',
                agent: 'Agente',
                result: 'Resultado',
              },
              tableOptions: {
                textLabels: {
                  body: {
                    noMatch: 'Ningún evento encontrado',
                    toolTip: 'Ordenar',
                  },
                  pagination: {
                    next: 'Siguiente página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Eventos por página:',
                    displayRows: 'de',
                    jumpToPage: 'Ver página:',
                  },
                  toolbar: {
                    search: 'Buscar',
                    downloadCsv: 'Descargar CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Columnas',
                    filterTable: 'Filtrar Tabla',
                  },


                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPIAR',
                  },
                  viewColumns: {
                    title: 'Ver Columnas',
                    titleAria: 'Ver/Ocultar Columnas',
                  },
                  selectedRows: {
                    text: 'evento(s) seleccionados',
                    delete: 'Eliminar',
                    deleteAria: 'Eliminar eventos Seleccionados',
                  },
                },
              },
            },            
          },
          autoComplete: {
            noOptions: 'Sin opciones',
            addNew: 'Agregar Nuevo',
          },
          layouts: {
            actionMenu: {
              logOut: 'Salir',
              myAccount: 'Mi Cuenta',
              reviewDate: 'Exclusión en Análisis',
              exclusionDeadlineDate: 'Fecha Límite de Exclusión',
            },
            sidebar: {
              dpo: 'DPO',
              allActivities: 'Grilla General',
              tutoredUsers: 'Usuarios Tutelados',
              exclusions: 'Solicitudes de Exclusión',
              feedbacks: 'Gestionar Feedbacks',
              feedback: 'Feedback',
              myFeedbacks: 'Mis Feedbacks',
              tracks: 'Trayectorias',
              event: 'Evento',
              activities: 'Actividades',
              manageCaravans: 'Coordinar Caravanas',
              myCaravans: 'Participar en Caravanas',
              editions: 'Ediciones',
              dashboard: 'Inicio',
              myAccount: 'Mi Cuenta',
              myCertificates: 'Mis Certificados',
              myRegistration: 'Mi Inscripción',
              postPurchase: 'Comprar Actividades',
              records: 'Registros',
              users: 'Usuarios',
              institutions: 'Instituciones',
              accessProfiles: 'Perfiles de Acceso',
              satisfactionSurveys: 'Encuestas de Satisfacción',
              audit: 'Auditoría',
              caravans: 'Caravanas',
              customization: 'Personalización',
              themes: 'Temas',
              home: 'Pantalla de Inicio',
              internationalization: 'Internacionalización',
              communication: 'Comunicación',
              templates: 'Modelos',
              tags: 'Etiquetas',
              userRegistration: 'Registrar Usuario',
              emails: 'Correos Electrónicos',
              certificates: 'Certificados',
              enrollments: 'Inscripciones',
              registrations: 'Gestionar Inscripciones',
              speakerActivities: 'Grilla del Ponente',
              userActivities: 'Mi Grilla',
              secretary: 'Secretaría',
              voucher: 'Voucher',
              terms: 'Términos del SWGE',
              privacyPolicy: 'Política de Privacidad',
              cancellationRefundPolicy: 'Política de Cancelación y Reembolso',
              useTerm: 'Términos de Uso',
              imageTerm: 'Término de Autorización de Uso de Imagen y Datos',
              coordinatorTerm: 'Término de Responsabilidad del Coordinador de Caravana',
              authorizationTerm: 'Declaración de Autorización Participación en Evento de Niño o Adolescente',
              apiUseTerm: 'Términos de Uso de la API del Sistema',
              coordinatorAcceptance: 'Aceptación del Término de Responsabilidad',
              listParticipation: 'Mis Participaciones',
              userCertificates: 'Certificados Por Usuarios',
            },
          },
          changeLanguage: 'Cambiar idioma',
          dialog: {
            deleteDialogTitle: 'Eliminar',
            userDeleteDialogTitle: 'Solicitar Exclusión',
            userDeleteDialogContent: '¿Desea solicitar la exclusión de este registro?',
            deleteDialogContent: '¿Realmente desea eliminar?',
            warning: 'Esta acción no se puede deshacer.',
            userDeleteWarning:
              '*Nota: Esta acción es irreversible, para cancelar la solicitud póngase en contacto con el DPO del sistema. El enlace al DPO es:',
            delete: 'Eliminar',
            userDelete: 'Solicitar Exclusión',
            cancelDeleteDialog: 'Cancelar',
          },
          cancelDialog: {
            cancelDialogTitle: 'Cancelar Exclusión',
            cancelDialogContent: '¿Realmente desea Cancelar la Exclusión?',
            accept: 'Sí',
            refuse: 'No',
          },
          tutoredUserCard: {
            info: 'Información',
            specialNeedsText: 'Necesidades Especiales',
          },
          manageExclusion: {
            dialogTitle: 'Gestionar Solicitud de Exclusión',
            send: 'Actualizar',
            cancel: 'Cancelar',
            previewPDF: 'Visualizar Archivo Adjunto',
          },
          userExclusion: {
            dialogTitle: 'Completar Solicitud de Exclusión',
            send: 'Completar',
            cancel: 'Cancelar',
          },
          toastes: {
            reviewed: 'Término de Participación Revisado con éxito',
            reviewedError: 'Ocurrió un error al revisar el Término de Participación',
            coordinatorAccepted: 'Término de Responsabilidad de Coordinador de Caravana aceptado',
            coordinatorError: 'Algo salió mal con la aceptación',
            voucherError: 'Ya existe un voucher en esta edición asignado a este usuario',
            registrationSave: 'Inscripción realizada con éxito',
            registrationExempted: 'Inscripción exenta con éxito',
            cardError: 'Hay algo mal en la tarjeta',
            publicKeyError: '¡Hay un error interno! Vuelve más tarde',
            paymentCancel: 'Pago cancelado, información eliminada',
            chargeSuccess: 'Cobro realizado con éxito',
            chargeError: 'Ocurrió un error al realizar el cobro, intente nuevamente',
            chargeCancel: 'Pago cancelado, valor reembolsado!',
            registrationSaveError: 'Error al realizar la inscripción',
            userProfileSave: 'Haga clic en guardar para registrar la nueva imagen',
            userRemovedImage: 'Imagen eliminada con éxito',
            noBackgroundImage: 'Imagen de Fondo no seleccionada',
            noRegistrationType: 'Tipo de Inscripción no configurado para esta edición',
            noRegistrationTypeInterval: 'Fuera del período de inscripción',
            resetPassword: 'Contraseña cambiada. Verifique su correo electrónico',
            resetPasswordError: 'Si el correo electrónico proporcionado está asociado con una cuenta en nuestro sistema, enviaremos un enlace para restablecer su contraseña',
            caravanEnrollmentAlreadyError: 'Usuario ya inscrito y aceptado en una Caravana',
            editionEnabledError: 'No se pudo habilitar/deshabilitar la edición',
            userEnabledError: 'No se pudo habilitar/deshabilitar al usuario',
            noCurrentEditionError: 'Seleccione una edición primero',
            caravanEnrollment: 'Inscripción en la caravana realizada',
            caravanEnrollmentError: 'No se pudo inscribir en la caravana',
            caravanCancelEnrollment: 'Cancelación de la inscripción en la caravana realizada',
            caravanCancelEnrollmentError: 'No se pudo cancelar la inscripción en la caravana',
            accountUntie: 'Login Social desvinculado',
            accountUntieError: 'No se pudo desvincular el login social',
            activityPlaceTimeConflict: 'Conflicto de Lugar-Horario con la Actividad',
            save: 'Registro Realizado',
            saveError: 'Error al guardar',
            maxUserRole: 'Máximo Alcanzado de Usuarios con el Permiso: ',
            saveNoScheduleError: 'Error al guardar - No hay programación para esta actividad',
            fetch: 'Búsqueda realizada con éxito',
            fetchError: 'No se pudo realizar la búsqueda',
            update: 'Actualizado con éxito',
            updateError: 'Error al actualizar',
            delete: 'Eliminado con éxito',
            discarded: 'Compra descartada exitosamente',
            deleteError: 'Error al eliminar',
            discardedError: 'Error al descartar la compra',
            deletes: 'Eliminado con éxito',
            deleteErrors: 'Error al eliminar',
            exclusionRequest: 'Solicitada la exclusión con éxito',
            exclusionUpdateSave: 'Cambiada la solicitud de exclusión con éxito',
            exclusionRequestError: 'Error al solicitar la exclusión',
            exclusionUpdateSaveError: 'Algo salió mal al actualizar la solicitud de exclusión',
            exclusionUpdateFileError: 'Es necesario adjuntar un archivo para completar la solicitud',
            exclusionNoFile: 'Error, es necesario un adjunto si se va a Negar una solicitud',
            cancelDelete: 'Cancelada la exclusión con éxito',
            cancelDeleteError: 'Error al cancelar la exclusión',
            finalDelete: 'Anonimización realizada con éxito',
            finalDeleteError: 'Error al excluir o anonimizar',
            deleteThemeErrorDefault: '¡No es posible eliminar un tema predeterminado! Cambie el tema predeterminado primero',
            selectWarning: 'La actividad elegida tiene conflicto de programación con otra actividad',
            uploadFile: {
              add: 'Archivo seleccionado',
              upload: 'Archivo añadido',
              delete: 'Archivo eliminado',
              error: 'Ocurrió un error. Por favor intente de nuevo',
              noFileError: 'Seleccione un archivo JSON primero',
              keysError: 'Todas las entradas del archivo JSON deben seguir la Paleta de Colores',
              stringError: 'Hay valores en el archivo JSON que no son Cadenas de texto',
              syntaxError: 'El archivo no es un JSON válido',
              fileSizeError: 'El tamaño del archivo excede los 5MB',
            },
            errorGetData: 'No se pudieron obtener los datos',
            cepFetchError: 'No se pudieron obtener datos del CEP automáticamente',
            emailSent: 'El correo electrónico de confirmación se envió con éxito',
            emailSentError: 'No se pudo enviar el correo electrónico de confirmación',
            colorPaletteValueError: 'Complete todos los campos de colores antes de guardar',
            colorPaletteValueFormat: 'Todos los campos de colores deben ser válidos',
            colorFieldFormat: 'Campo de color debe ser válido',
            certificateValidated: 'Certificado Validado con éxito!',
            invalidCertificate: 'Certificado Inválido!',
            participationRegistered: 'Participación Confirmada',
            errorOnParticipationRegister: 'Error al confirmar Participación',
          },
          steps: {
            appBar: {
              user: {
                title: 'Botón del Menú del Usuario',
                content:
                  'Este botón permite al usuario realizar la acción de acceder a los datos de su cuenta y realizar el cierre de sesión de la cuenta.\n Demostración: ',
              },
              theme: {
                title: 'Botón de Cambiar el Tema',
                content:
                  'Este botón permite cambiar el tema actual del sitio. El predeterminado es el modo Blanco (representado por el Sol) y se puede cambiar al modo Oscuro (representado por la Luna).\n Demostración: ',
              },
              edition: {
                title: 'Botón de Cambiar la Edición',
                content:
                  'Este botón presenta todas las ediciones en el momento, y a través de él es posible seleccionar la edición que desea acceder.\n Demostración: ',
              },
              language: {
                title: 'Botón de Cambiar la Lengua',
                content: 'Este botón permite cambiar entre los diversos idiomas disponibles de la plataforma.\n Demostración: ',
              },
              sideBar: {
                title: 'Botón de Abrir/Ocultar la barra lateral',
                content:
                  'Este botón permite abrir o esconder la barra lateral que posee todas las funcionalidades del sistema.\n Demostración: ',
              },
              tooltip: {
                edition: 'Cambiar Edición',
                help: 'Ayuda',
              },
            },
          },
          enums: {
            languages: {
              'pt-BR': 'Portugués',
              'en-US': 'Inglés',
              'es-ES': 'Español',
            },
            feedbackStatus: {
              OPEN: 'Abierto',
              ON_GOING: 'En Curso',
              CLOSED: 'Cerrado',
            },
            enabled: {
              true: 'Habilitado',
              false: 'No Habilitado',
            },
            confirmedBool: {
              true: 'Confirmado',
              false: 'No Confirmado',
            },
            empty: {
              EMPTY: 'Vacío',
            },
            activityTypes: {
              LECTURE: 'Conferencia',
              MINI_COURSE: 'Mini-Curso',
              WORKSHOP: 'Taller',
              KEYNOTE: 'Keynote',
              PAPER_PRESENTATION: 'Presentación de Trabajo',
              POSTER_PRESENTATION: 'Presentación de Póster',
              OTHER: 'Otro',
            },
            exclusionStatus: {
              APPROVED: 'Aprobado',
              DENIED: 'Denegado',
              CANCELED: 'Cancelado',
              NOT_ANALYZED: 'No Analizado',

              Aprobado: 'APPROVED',
              Denegado: 'DENIED',
              Cancelado: 'CANCELED',
              'No Analizado': 'NOT_ANALYZED',
            },
            requestType: {
              INACTIVITY: 'INACTIVIDAD',
              USER: 'USUARIO',
              ANONYMOUS: 'ANÓNIMO',
              TUTORED: 'TUTELADO',
            },
            fonts: {
              Courier: 'Courier',
              Helvetica: 'Helvetica',
              'Times-Roman': 'Times-Roman',
              Roboto: 'Roboto',
              'Fantasque Sans': 'Fantasque Sans',
              Ubuntu: 'Ubuntu',
              'Apple Storm': 'Apple Storm',
              Lato: 'Lato',
              Poppins: 'Poppins',
              'Playfair Display SC': 'Playfair Display SC',
            },
            payed: {
              PAYED: 'Pagado',
              NOT_PAYED: 'No Pagado',
              EXEMPTION: 'Exento',
            },
            confirmed: {
              CONFIRMED: 'Confirmado',
              NOT_CONFIRMED: 'No Confirmado',
            },
            accepted: {
              ACCEPTED: 'Aceptado',
              NOT_ACCEPTED: 'No Aceptado',
            },
            userTypes: {
              ATTENDEE: 'Participante',
              SPEAKER: 'Ponente',
              COORDINATOR: 'Coordinador',
              ADMINISTRATOR: 'Administrador',
            },
            editionTypes: {
              LIVE: 'Presencial',
              ONLINE: 'Virtual',
              HYBRID: 'Híbrido',
            },
            caravanTypes: {
              GENERAL: 'Común',
              TUTORED: 'Tutelada',
            },
            activityType: {
              LIVE: 'Presencial',
              ONLINE: 'Virtual',
              HYBRID: 'Híbrido',
            },
            genders: {
              MALE: 'Masculino',
              FEMALE: 'Femenino',
              UNDEFINED: 'Otro',
            },
            specialNeeds: {
              VISUAL: 'Visual',
              HEARING: 'Auditiva',
              LOCOMOTOR: 'Locomotora',
              SPEECH: 'Habla y Lenguaje',
              MENTAL: 'Psicosocial',
              INTELLECTUAL: 'Intelectual',
              OTHERS: 'Otros',
            },
            userRoleType: {
              ATTENDEE: 'Participante',
              SPEAKER: 'Ponente',
              CARAVAN_COORDINATOR: 'Coordinador de Caravana',
              SECRETARY: 'Secretaria',
              DPO: 'Oficial de Protección de Datos',
              GRID_COORDINATOR: 'Coordinador de Grilla',
            },
            accessPermissions: {
              USER: 'USUARIO',
              ADM: 'ADMINISTRADOR',
            },
            passwordStrength: {
              tooWeak: 'muy débil',
              weak: 'débil',
              okay: 'aceptable',
              good: 'buena',
              strong: 'fuerte',
            },
            themeTypes: {
              LIGHT: 'Claro',
              DARK: 'Oscuro',
              tooltip: {
                light: 'Cambiar al modo claro',
                dark: 'Cambiar al modo oscuro',
              },
            },
            colorPalette: {
              primary: 'Color primario del sistema. Utilizado en la mayoría de los componentes primarios',
              secondary: 'Color secundario del sistema. Utilizado en la mayoría de los componentes secundarios',
              error: 'Color indicativo de error',
              warning: 'Color indicativo de advertencia',
              info: 'Color indicativo de información',
              success: 'Color indicativo de éxito',
              toolbarBackgroundColor: 'Color de fondo de la barra de ubicación',
              titleCardBackgroundColor: 'Color de fondo de la barra de título de las tarjetas',
              appBarBackgroundColor: 'Color de fondo de la barra superior',
              sidebarBackgroundColor: 'Color de fondo del menú lateral',
            },
            tour: {
              next: 'Siguiente',
              close: 'Cerrar',
              open: 'Comenzar Tour',
              skip: 'Saltar',
              back: 'Atrás',
              last: 'Último',
            },
          },
        },
      },
    },
  });

export default i18n;
